export const BEDS_DATA = {
    crs: {
        type: 'name',
        properties: {
            name: 'urn:ogc:def:crs:OGC:1.3:CRS84'
        }
    },
    name: 'beds1644482064',
    type: 'FeatureCollection',
    features: [
        {
            id: 0,
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-81.4102150340258, 28.3709716189625],
                        [-81.4102023804459, 28.3709716189625],
                        [-81.410200291991, 28.3709716189625],
                        [-81.4102021347453, 28.3709593406588],
                        [-81.4102211765403, 28.3709556571677],
                        [-81.4102377613294, 28.3709574989133],
                        [-81.4102365676728, 28.3709682359416],
                        [-81.4102365328265, 28.3709685493866],
                        [-81.4102150340258, 28.3709716189625]
                    ]
                ]
            },
            properties: {
                DN: 2
            }
        },
        {
            id: 1,
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-81.4102346900722, 28.3707438564283],
                        [-81.4102301982265, 28.3707421729083],
                        [-81.4102297760606, 28.3707398221285],
                        [-81.4102297760606, 28.3707205276512],
                        [-81.4102353043236, 28.3707211415664],
                        [-81.4102346900722, 28.3707438564283]
                    ]
                ]
            },
            properties: {
                DN: 2
            }
        },
        {
            id: 2,
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-81.4103403413216, 28.3707579764776],
                        [-81.4103237565324, 28.3707506094953],
                        [-81.4103127000063, 28.3707518373257],
                        [-81.4103100560544, 28.3707474331515],
                        [-81.4103075626306, 28.3707337268277],
                        [-81.4103065574918, 28.3707254389727],
                        [-81.4103059933834, 28.3707251006929],
                        [-81.4103059432404, 28.3707248250575],
                        [-81.4103055337394, 28.3707248250575],
                        [-81.4103034862346, 28.3707235972271],
                        [-81.4103084002462, 28.3707205276512],
                        [-81.4103102430005, 28.3706971988741],
                        [-81.4103200710237, 28.3706990406197],
                        [-81.4103335845556, 28.3707076354323],
                        [-81.4103292847955, 28.3707211415664],
                        [-81.4103311275498, 28.3707358755309],
                        [-81.4103268277897, 28.370746312089],
                        [-81.4103403413216, 28.370749381665],
                        [-81.4103403413216, 28.3707569942133],
                        [-81.4103403413216, 28.3707579764776]
                    ]
                ]
            },
            properties: {
                DN: 2
            }
        },
        {
            id: 3,
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-81.4102513953028, 28.3707456861363],
                        [-81.4102463608497, 28.3707438564283],
                        [-81.4102445180954, 28.3707107050082],
                        [-81.4102285475577, 28.3707094771778],
                        [-81.4102279333062, 28.3707186859056],
                        [-81.4102199480374, 28.3707186859056],
                        [-81.4102187195345, 28.3706898318918],
                        [-81.4102365328265, 28.3706916736374],
                        [-81.4102469751012, 28.3706990406197],
                        [-81.4102592601302, 28.3707014962804],
                        [-81.4102838301882, 28.3707229833119],
                        [-81.4102832120735, 28.3707230412285],
                        [-81.4102641741418, 28.3707248250575],
                        [-81.4102555746215, 28.3707291224638],
                        [-81.410255491131, 28.3707297065773],
                        [-81.4102543461186, 28.3707303502942],
                        [-81.4102513953028, 28.3707456861363]
                    ]
                ]
            },
            properties: {
                DN: 2
            }
        }
    ]
};

export const CANOPY_DATA = {
    crs: {
        type: 'name',
        properties: {
            name: 'urn:ogc:def:crs:OGC:1.3:CRS84'
        }
    },
    name: 'extended_parcel_parcel1644482066',
    type: 'FeatureCollection',
    features: [
        {
            id: 0,
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-81.41010105168361, 28.37060581100032],
                        [-81.41008875663613, 28.370542138478303],
                        [-81.41017150526814, 28.37052615986888],
                        [-81.41017792726288, 28.370525250152596],
                        [-81.41018440801051, 28.370524986504677],
                        [-81.41019088267888, 28.37052537156261],
                        [-81.41019728649664, 28.370526401474347],
                        [-81.41020355540122, 28.37052806593685],
                        [-81.41020962667972, 28.37053034829915],
                        [-81.41021543959619, 28.370533225728938],
                        [-81.4103693249022, 28.370619237166498],
                        [-81.41033768562164, 28.37067584382846],
                        [-81.41033933579233, 28.37102641634349],
                        [-81.41010292443148, 28.37100076705686],
                        [-81.41010105168361, 28.37060581100032]
                    ]
                ]
            },
            properties: {
                id: 0
            }
        }
    ]
};

// export const LAWN_DATA = {
//     crs: {
//         type: 'name',
//         properties: {
//             name: 'urn:ogc:def:crs:OGC:1.3:CRS84'
//         }
//     },
//     name: 'lawns1644482063',
//     type: 'FeatureCollection',
//     features: [
//         {
//             id: 0,
//             type: 'Feature',
//             geometry: {
//                 type: 'Polygon',
//                 coordinates: [
//                     [
//                         [-81.4103698253912, 28.3706769396729],
//                         [-81.410340955573, 28.3706677309451],
//                         [-81.4103028719831, 28.3706499274047],
//                         [-81.410261717136, 28.3706253707972],
//                         [-81.4102316188149, 28.3706020420202],
//                         [-81.4102475893526, 28.3705934472075],
//                         [-81.410298572223, 28.3706284403732],
//                         [-81.4103759679057, 28.3706622057084],
//                         [-81.4103698253912, 28.3706769396729]
//                     ]
//                 ]
//             },
//             properties: {
//                 DN: 1
//             }
//         },
//         {
//             id: 1,
//             type: 'Feature',
//             geometry: {
//                 type: 'Polygon',
//                 coordinates: [
//                     [
//                         [-81.4100854269698, 28.3706057255113],
//                         [-81.4100805129582, 28.3706044976809],
//                         [-81.4100798987068, 28.3705897637164],
//                         [-81.4101032402619, 28.3705768714975],
//                         [-81.4101161395423, 28.3705731880064],
//                         [-81.4101290388228, 28.3705842384797],
//                         [-81.4101167537938, 28.3705928332923],
//                         [-81.4100854269698, 28.3706057255113]
//                     ]
//                 ]
//             },
//             properties: {
//                 DN: 1
//             }
//         },
//         {
//             id: 2,
//             type: 'Feature',
//             geometry: {
//                 type: 'Polygon',
//                 coordinates: [
//                     [
//                         [-81.4101437808576, 28.3705787132431],
//                         [-81.4101382525945, 28.3705774854127],
//                         [-81.4101382525945, 28.3705652071089],
//                         [-81.4101462378634, 28.3705578401267],
//                         [-81.410156680138, 28.3705559983811],
//                         [-81.410156680138, 28.370571960176],
//                         [-81.4101437808576, 28.3705787132431]
//                     ]
//                 ]
//             },
//             properties: {
//                 DN: 1
//             }
//         },
//         {
//             id: 3,
//             type: 'Feature',
//             geometry: {
//                 type: 'MultiPolygon',
//                 coordinates: [
//                     [
//                         [
//                             [-81.4103100560544, 28.3707474331515],
//                             [-81.4103127000063, 28.3707518373257],
//                             [-81.4103237565324, 28.3707506094953],
//                             [-81.4103403413216, 28.3707579764776],
//                             [-81.4103403413216, 28.3707569942133],
//                             [-81.4103403413216, 28.370749381665],
//                             [-81.4103268277897, 28.370746312089],
//                             [-81.4103311275498, 28.3707358755309],
//                             [-81.4103292847955, 28.3707211415664],
//                             [-81.4103335845556, 28.3707076354323],
//                             [-81.4103200710237, 28.3706990406197],
//                             [-81.4103102430005, 28.3706971988741],
//                             [-81.4103084002462, 28.3707205276512],
//                             [-81.4103034862346, 28.3707235972271],
//                             [-81.4103055337394, 28.3707248250575],
//                             [-81.4102641741418, 28.3707248250575],
//                             [-81.4102832120735, 28.3707230412285],
//                             [-81.4102838301882, 28.3707229833119],
//                             [-81.4102592601302, 28.3707014962804],
//                             [-81.4102469751012, 28.3706990406197],
//                             [-81.4102365328265, 28.3706916736374],
//                             [-81.4102193337859, 28.3706898318918],
//                             [-81.4102174910316, 28.3706560665566],
//                             [-81.4102254763004, 28.3706192316454],
//                             [-81.4102297760606, 28.3706186177302],
//                             [-81.4102586458787, 28.3706407186769],
//                             [-81.4102998007259, 28.3706646613692],
//                             [-81.410365525631, 28.3706910597222],
//                             [-81.4103679826368, 28.3710581810039],
//                             [-81.4100762131981, 28.371025643499],
//                             [-81.4100749846952, 28.3708420828582],
//                             [-81.4100817414611, 28.3708402411126],
//                             [-81.4100835842155, 28.3708365576215],
//                             [-81.4100866554727, 28.3708027922862],
//                             [-81.4100964834959, 28.3707997227103],
//                             [-81.4100958692445, 28.3707917418128],
//                             [-81.4100878839756, 28.3707905139825],
//                             [-81.4100866554727, 28.3707868304914],
//                             [-81.4100860412213, 28.3707107050082],
//                             [-81.4100737561923, 28.3707051797715],
//                             [-81.4100743704437, 28.3706622057084],
//                             [-81.4100786702039, 28.3706529969806],
//                             [-81.4100823557126, 28.3706253707972],
//                             [-81.4100872697242, 28.3706198455606],
//                             [-81.410151151875, 28.3705909915468],
//                             [-81.4101382525945, 28.3706493134895],
//                             [-81.4101400953489, 28.3707205276512],
//                             [-81.4101347482679, 28.3707215964819],
//                             [-81.4101284245713, 28.3707217554816],
//                             [-81.4101284757954, 28.3707228502897],
//                             [-81.4101310657338, 28.3707782048049],
//                             [-81.4101314307352, 28.3707860059472],
//                             [-81.4101308815771, 28.3708463802645],
//                             [-81.410136363173, 28.3708914264906],
//                             [-81.4101363991979, 28.3708921964467],
//                             [-81.4101345670858, 28.3709587267436],
//                             [-81.4101400953489, 28.3709716189625],
//                             [-81.4101775646873, 28.3709740746233],
//                             [-81.4102023804459, 28.3709716189625],
//                             [-81.4102150340258, 28.3709716189625],
//                             [-81.4102365328265, 28.3709685493866],
//                             [-81.4102365676728, 28.3709682359416],
//                             [-81.4102396040838, 28.3709679354714],
//                             [-81.410242675341, 28.3709660937259],
//                             [-81.410242675341, 28.3709562710829],
//                             [-81.4102537318671, 28.3709544293373],
//                             [-81.4102574173758, 28.3709476762703],
//                             [-81.4102586458787, 28.3709053161224],
//                             [-81.4103034862346, 28.3709016326313],
//                             [-81.4103111313137, 28.3708930366257],
//                             [-81.4103114715034, 28.3708930378187],
//                             [-81.4103128899667, 28.3707947448698],
//                             [-81.4103128899667, 28.3707947448696],
//                             [-81.4103133142578, 28.3707653434598],
//                             [-81.4103100560544, 28.3707474331515]
//                         ]
//                     ],
//                     [
//                         [
//                             [-81.41030756263059, 28.370733726827613],
//                             [-81.4103059933834, 28.3707251006929],
//                             [-81.4103065574918, 28.3707254389727],
//                             [-81.41030756263059, 28.370733726827613]
//                         ]
//                     ]
//                 ]
//             },
//             properties: {
//                 DN: 1
//             }
//         }
//     ]
// };

export const LAWN_DATA = {
    "type": "FeatureCollection",
    "features": [
        {
            "id": 0,
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.3066776768,
                            34.0754381937
                        ],
                        [
                            -84.306647323,
                            34.0754368549
                        ],
                        [
                            -84.3067131333,
                            34.0754293359
                        ],
                        [
                            -84.3067864649,
                            34.0753998864
                        ],
                        [
                            -84.3068015073,
                            34.0753967535
                        ],
                        [
                            -84.3069268603,
                            34.0753998864
                        ],
                        [
                            -84.3069513042,
                            34.0754074054
                        ],
                        [
                            -84.3069581986,
                            34.07542307
                        ],
                        [
                            -84.3069569451,
                            34.0754343486
                        ],
                        [
                            -84.306689943,
                            34.0754387347
                        ],
                        [
                            -84.3066776768,
                            34.0754381937
                        ]
                    ]
                ]
            },
            "properties": {
                "DN": 1
            }
        },
        {
            "id": 1,
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.3067043586,
                            34.0753547722
                        ],
                        [
                            -84.3066911965,
                            34.0753528925
                        ],
                        [
                            -84.3066924501,
                            34.0752939935
                        ],
                        [
                            -84.3067049854,
                            34.0752946201
                        ],
                        [
                            -84.306708746,
                            34.0753015125
                        ],
                        [
                            -84.3067074924,
                            34.0753460001
                        ],
                        [
                            -84.3067043586,
                            34.0753547722
                        ]
                    ]
                ]
            },
            "properties": {
                "DN": 1
            }
        },
        {
            "id": 2,
            "type": "Feature",
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            -84.3065865267,
                            34.075220683
                        ],
                        [
                            -84.3065840197,
                            34.0752789554
                        ],
                        [
                            -84.3065729697,
                            34.0752783889
                        ],
                        [
                            -84.3065595758,
                            34.0752777023
                        ],
                        [
                            -84.3065514279,
                            34.0752833415
                        ],
                        [
                            -84.30655218,
                            34.0753021391
                        ],
                        [
                            -84.3065520546,
                            34.0753077784
                        ],
                        [
                            -84.306545787,
                            34.0753322152
                        ],
                        [
                            -84.3065432799,
                            34.0753328418
                        ],
                        [
                            -84.306526984,
                            34.0753058986
                        ],
                        [
                            -84.306526984,
                            34.075338481
                        ],
                        [
                            -84.306516329,
                            34.0753409874
                        ],
                        [
                            -84.3065175825,
                            34.0753660508
                        ],
                        [
                            -84.3064517722,
                            34.075364171
                        ],
                        [
                            -84.3064455045,
                            34.0753434937
                        ],
                        [
                            -84.3063809477,
                            34.0753447469
                        ],
                        [
                            -84.3063772345,
                            34.0753459191
                        ],
                        [
                            -84.3063709194,
                            34.0753460001
                        ],
                        [
                            -84.3063710092,
                            34.0753478845
                        ],
                        [
                            -84.3063727997,
                            34.0753854749
                        ],
                        [
                            -84.3063834547,
                            34.0753961269
                        ],
                        [
                            -84.3064116592,
                            34.0753986332
                        ],
                        [
                            -84.3064173001,
                            34.0753967535
                        ],
                        [
                            -84.3064173307,
                            34.0753961263
                        ],
                        [
                            -84.3064486383,
                            34.0753955003
                        ],
                        [
                            -84.3064511454,
                            34.0753779559
                        ],
                        [
                            -84.3065244769,
                            34.0753760761
                        ],
                        [
                            -84.3065508011,
                            34.0753835952
                        ],
                        [
                            -84.3065495476,
                            34.0754030193
                        ],
                        [
                            -84.3065520546,
                            34.0754067788
                        ],
                        [
                            -84.3065846464,
                            34.0754074054
                        ],
                        [
                            -84.3065859,
                            34.0754136713
                        ],
                        [
                            -84.3065890338,
                            34.0754149244
                        ],
                        [
                            -84.3066172382,
                            34.0754168042
                        ],
                        [
                            -84.3066153579,
                            34.0754330954
                        ],
                        [
                            -84.3066197453,
                            34.0754374815
                        ],
                        [
                            -84.3064501008,
                            34.0754406305
                        ],
                        [
                            -84.3063834547,
                            34.0754418676
                        ],
                        [
                            -84.3063834922,
                            34.0754413437
                        ],
                        [
                            -84.3063840815,
                            34.0754330954
                        ],
                        [
                            -84.3063796941,
                            34.075426203
                        ],
                        [
                            -84.3063621447,
                            34.0754368549
                        ],
                        [
                            -84.3063685388,
                            34.0754415038
                        ],
                        [
                            -84.3063690391,
                            34.0754418676
                        ],
                        [
                            -84.306334567,
                            34.0754424942
                        ],
                        [
                            -84.3062762779,
                            34.0754424942
                        ],
                        [
                            -84.3062756511,
                            34.0752889808
                        ],
                        [
                            -84.3062775314,
                            34.0752576515
                        ],
                        [
                            -84.3062856793,
                            34.0752526388
                        ],
                        [
                            -84.306283799,
                            34.0752300818
                        ],
                        [
                            -84.3062881864,
                            34.0752213096
                        ],
                        [
                            -84.3062881864,
                            34.0752119108
                        ],
                        [
                            -84.3062750243,
                            34.0751924867
                        ],
                        [
                            -84.3062750243,
                            34.0751411067
                        ],
                        [
                            -84.3062825455,
                            34.0751411067
                        ],
                        [
                            -84.3064699484,
                            34.0751411067
                        ],
                        [
                            -84.306465561,
                            34.0751473725
                        ],
                        [
                            -84.3064649342,
                            34.0751573979
                        ],
                        [
                            -84.3064724554,
                            34.0751993791
                        ],
                        [
                            -84.3064806034,
                            34.075213164
                        ],
                        [
                            -84.306495019,
                            34.0752156703
                        ],
                        [
                            -84.3065000331,
                            34.0752094045
                        ],
                        [
                            -84.306505674,
                            34.0751667967
                        ],
                        [
                            -84.3065131952,
                            34.075143613
                        ],
                        [
                            -84.3065169558,
                            34.0751404801
                        ],
                        [
                            -84.3066649545,
                            34.07513449
                        ],
                        [
                            -84.3066649545,
                            34.07513449
                        ],
                        [
                            -84.3066717668,
                            34.0751342142
                        ],
                        [
                            -84.3067818242,
                            34.0751324678
                        ],
                        [
                            -84.3067818242,
                            34.0751324678
                        ],
                        [
                            -84.3069481704,
                            34.0751298281
                        ],
                        [
                            -84.3068760923,
                            34.0751335876
                        ],
                        [
                            -84.3068297117,
                            34.0751411067
                        ],
                        [
                            -84.3068146693,
                            34.0751492523
                        ],
                        [
                            -84.306802134,
                            34.0751498789
                        ],
                        [
                            -84.3068008805,
                            34.0751636637
                        ],
                        [
                            -84.3068033875,
                            34.0751730625
                        ],
                        [
                            -84.306812789,
                            34.0751824613
                        ],
                        [
                            -84.3068460076,
                            34.0751931132
                        ],
                        [
                            -84.3068453808,
                            34.0751956196
                        ],
                        [
                            -84.306831592,
                            34.0751993791
                        ],
                        [
                            -84.3068334723,
                            34.0752050184
                        ],
                        [
                            -84.3068435005,
                            34.0752112842
                        ],
                        [
                            -84.3068541555,
                            34.0752119108
                        ],
                        [
                            -84.3068654373,
                            34.0752188032
                        ],
                        [
                            -84.3068773459,
                            34.0752188032
                        ],
                        [
                            -84.3068905079,
                            34.0752062715
                        ],
                        [
                            -84.3069005362,
                            34.075205645
                        ],
                        [
                            -84.3069205927,
                            34.0752231893
                        ],
                        [
                            -84.3069487971,
                            34.075235721
                        ],
                        [
                            -84.3069525577,
                            34.0752513857
                        ],
                        [
                            -84.3069538112,
                            34.075297753
                        ],
                        [
                            -84.3069538112,
                            34.0753478798
                        ],
                        [
                            -84.3069500507,
                            34.0753572786
                        ],
                        [
                            -84.3069274871,
                            34.0753635444
                        ],
                        [
                            -84.3068974024,
                            34.0753654242
                        ],
                        [
                            -84.306751366,
                            34.0753616647
                        ],
                        [
                            -84.3067187742,
                            34.0753572786
                        ],
                        [
                            -84.3067206545,
                            34.0752908606
                        ],
                        [
                            -84.3067168939,
                            34.0752858479
                        ],
                        [
                            -84.3067081192,
                            34.0752833415
                        ],
                        [
                            -84.3067099995,
                            34.075228202
                        ],
                        [
                            -84.3067083573,
                            34.0752201577
                        ],
                        [
                            -84.306708746,
                            34.075213164
                        ],
                        [
                            -84.306706521,
                            34.075211162
                        ],
                        [
                            -84.3067037319,
                            34.0751974993
                        ],
                        [
                            -84.3066811683,
                            34.0751899803
                        ],
                        [
                            -84.3066767809,
                            34.0751818347
                        ],
                        [
                            -84.3066629921,
                            34.0751724359
                        ],
                        [
                            -84.3066429356,
                            34.0751686764
                        ],
                        [
                            -84.3066316538,
                            34.0751755689
                        ],
                        [
                            -84.3066053297,
                            34.0751818347
                        ],
                        [
                            -84.3066003156,
                            34.075187474
                        ],
                        [
                            -84.3065953014,
                            34.0751849676
                        ],
                        [
                            -84.3065877803,
                            34.0751899803
                        ],
                        [
                            -84.3065984353,
                            34.0751968728
                        ],
                        [
                            -84.3066028226,
                            34.0751937398
                        ],
                        [
                            -84.3066084635,
                            34.0751962462
                        ],
                        [
                            -84.3066078367,
                            34.0752006323
                        ],
                        [
                            -84.3066172382,
                            34.0752037652
                        ],
                        [
                            -84.3066184918,
                            34.0752068981
                        ],
                        [
                            -84.3065921676,
                            34.0752075247
                        ],
                        [
                            -84.3065890338,
                            34.075210031
                        ],
                        [
                            -84.3065886668,
                            34.0752156908
                        ],
                        [
                            -84.3065865267,
                            34.075220683
                        ]
                    ],
                    [
                        [
                            -84.3067883452,
                            34.0752695567
                        ],
                        [
                            -84.3067852114,
                            34.0752726896
                        ],
                        [
                            -84.3067814508,
                            34.0752921137
                        ],
                        [
                            -84.3067827043,
                            34.0753008859
                        ],
                        [
                            -84.3067958664,
                            34.0753121644
                        ],
                        [
                            -84.3068115355,
                            34.0753121644
                        ],
                        [
                            -84.3068165496,
                            34.0753058986
                        ],
                        [
                            -84.3068152961,
                            34.075287101
                        ],
                        [
                            -84.3067983734,
                            34.0752701832
                        ],
                        [
                            -84.3067921058,
                            34.0752676769
                        ],
                        [
                            -84.3067883452,
                            34.0752695567
                        ]
                    ]
                ]
            },
            "properties": {
                "DN": 1
            }
        }
    ]
}

export const PARCEL_LAYER_DATA = {
    crs: {
        type: 'name',
        properties: {
            name: 'urn:ogc:def:crs:OGC:1.3:CRS84'
        }
    },
    name: 'extended_parcel_parcel1644482066',
    type: 'FeatureCollection',
    features: [
        {
            id: 0,
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-81.41010105168361, 28.37060581100032],
                        [-81.41008875663613, 28.370542138478303],
                        [-81.41017150526814, 28.37052615986888],
                        [-81.41017792726288, 28.370525250152596],
                        [-81.41018440801051, 28.370524986504677],
                        [-81.41019088267888, 28.37052537156261],
                        [-81.41019728649664, 28.370526401474347],
                        [-81.41020355540122, 28.37052806593685],
                        [-81.41020962667972, 28.37053034829915],
                        [-81.41021543959619, 28.370533225728938],
                        [-81.4103693249022, 28.370619237166498],
                        [-81.41033768562164, 28.37067584382846],
                        [-81.41033933579233, 28.37102641634349],
                        [-81.41010292443148, 28.37100076705686],
                        [-81.41010105168361, 28.37060581100032]
                    ]
                ]
            },
            properties: {
                id: 0
            }
        }
    ]
};

export const MOWER_21_DATA = {
    type: 'Feature',
    geometry: {
        type: 'Polygon',
        coordinates: [
            [
                [-76.22556679284608, 43.151537230490106],
                [-76.2255650481254, 43.15153493935409],
                [-76.2255650063816, 43.15153305990651],
                [-76.2255591705976, 43.15152722108951],
                [-76.22555726503549, 43.15152471873941],
                [-76.2255364229498, 43.151512802786385],
                [-76.2255352319735, 43.151500886833304],
                [-76.22554297331959, 43.151479438117946],
                [-76.22555369210652, 43.151464543176616],
                [-76.2255530966184, 43.151455010414196],
                [-76.2255417823433, 43.1514490524377],
                [-76.2254977162192, 43.1514430944612],
                [-76.2254661553466, 43.15144190286588],
                [-76.2254572230241, 43.151444881854104],
                [-76.2255221312339, 43.15145620200951],
                [-76.2255124930156, 43.15152030110991],
                [-76.22552987258, 43.1515259103347],
                [-76.2255299186595, 43.151526417471985],
                [-76.2255304680682, 43.15152650613231],
                [-76.22553013724139, 43.151528823123215],
                [-76.2255304680682, 43.1515324641089],
                [-76.2255274906274, 43.1515473590502],
                [-76.22552570416289, 43.15155987080081],
                [-76.2255501191776, 43.15156046659848],
                [-76.2255650063816, 43.151558083407906],
                [-76.22556679284608, 43.151537230490106]
            ]
        ]
    },
    properties: null
};

export const LAWN_EDGE_DATA = {
    type: 'FeatureCollection',
    features: [
        {
            id: 0,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27544145608105, 40.787667188649294],
                        [-74.27543872492866, 40.787661361063925],
                        [-74.27543235468225, 40.78764841473594],
                        [-74.27542766081648, 40.78763521455588],
                        [-74.27542766081648, 40.78762252207255],
                        [-74.27543369578676, 40.78760551414109],
                        [-74.27543604271965, 40.78758825235542],
                        [-74.27543403106289, 40.787562613518446],
                        [-74.27542732554035, 40.7875443363217],
                        [-74.27542833136873, 40.78752834377042],
                        [-74.27543235468225, 40.787507528063],
                        [-74.27544375407057, 40.78747782759109],
                        [-74.2754491184886, 40.78747097363413],
                        [-74.2754467715557, 40.78745878882003],
                        [-74.2754417424138, 40.787452696412146],
                        [-74.27544107186155, 40.78744381164964],
                        [-74.27545582401113, 40.787431119129735],
                        [-74.27545951204853, 40.7874219805139],
                        [-74.2754555232412, 40.78741726918947],
                        [-74.2754558061977, 40.78741729387852]
                    ],
                    [
                        [-74.27544163626482, 40.78766710182499],
                        [-74.27544145608105, 40.787667188649294]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 1,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27546637668264, 40.78764272258167],
                        [-74.27546632934191, 40.787642978484165],
                        [-74.27546525645978, 40.78764360334392],
                        [-74.27545955676563, 40.78765629582324],
                        [-74.275459067684, 40.78765744039054],
                        [-74.2754587851444, 40.78765760165394]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 2,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27413793670983, 40.787609335465106],
                        [-74.27413773682619, 40.78760931477846]
                    ],
                    [
                        [-74.27414684609509, 40.78756448452746],
                        [-74.27414704490857, 40.787564510480735]
                    ],
                    [
                        [-74.27414704490857, 40.787564510480735],
                        [-74.27413793670983, 40.787609335465106]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 3,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27453899046597, 40.78675102851105],
                        [-74.27454833086195, 40.78674189378651],
                        [-74.27456107135475, 40.78673732443133],
                        [-74.27457515295208, 40.78673935525589],
                        [-74.27458990510166, 40.786739862962065],
                        [-74.27459594007195, 40.78673275507583],
                        [-74.27459526951971, 40.78672513948263],
                        [-74.27458588178816, 40.78671143141267],
                        [-74.27458588178816, 40.78670178499138],
                        [-74.27459485063957, 40.78669224562276],
                        [-74.27459517645545, 40.78669218561698]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 4,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27479440072467, 40.786831762720595],
                        [-74.27479444517394, 40.78683146711814]
                    ],
                    [
                        [-74.27476355729056, 40.78670037898003],
                        [-74.27476381238978, 40.786700429055315],
                        [-74.27478808562947, 40.78674310432473],
                        [-74.27480372681386, 40.7867805271313],
                        [-74.27480059857697, 40.78681416026862],
                        [-74.27479621904536, 40.786830266272005],
                        [-74.27479440072467, 40.786831762720595]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 5,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.2746759476073, 40.787053342919116],
                        [-74.27467573789198, 40.7870533882861],
                        [-74.27467281813756, 40.78704896694467],
                        [-74.27466823813069, 40.78704376536609],
                        [-74.27466228412176, 40.787039604102915],
                        [-74.27465457873444, 40.78703754501623],
                        [-74.27465461311415, 40.787037347185525]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 6,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27467888107249, 40.787017377723345],
                        [-74.27467914161336, 40.787017476356695]
                    ],
                    [
                        [-74.27468844367866, 40.787050435061666],
                        [-74.27468840018834, 40.78705064909598],
                        [-74.27467573789198, 40.7870533882861],
                        [-74.27467561965275, 40.78705320923816]
                    ],
                    [
                        [-74.27465477272328, 40.78703759685527],
                        [-74.27465457873444, 40.78703754501623],
                        [-74.27465633011285, 40.787027467083874],
                        [-74.27467888107249, 40.787017377723345]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 7,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27453318685293, 40.786466628540374],
                        [-74.27453336381434, 40.78646653476909],
                        [-74.27453978645768, 40.786477266686745],
                        [-74.27455018001761, 40.7864955441772],
                        [-74.27456459689107, 40.78650442906658],
                        [-74.27457921311881, 40.78650996239651]
                    ],
                    [
                        [-74.27457921311881, 40.78650996239651],
                        [-74.2745937659141, 40.78650620604432],
                        [-74.27459913033213, 40.78650366750466],
                        [-74.27460483002629, 40.78649732115514],
                        [-74.27460583585467, 40.78648995938897],
                        [-74.27460684168305, 40.78647828210305],
                        [-74.27460842560328, 40.78647000718041],
                        [-74.27459309536185, 40.78645238898335],
                        [-74.27458150591453, 40.78644110690988],
                        [-74.27458169178674, 40.78644100873526]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 8,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.2739904969149, 40.786653366692235],
                        [-74.27400760489552, 40.78666123117159],
                        [-74.27402705091087, 40.786664277411916],
                        [-74.27404146778433, 40.78666224658505],
                        [-74.27404817330687, 40.78665767722438],
                        [-74.27405554938166, 40.78664752308839],
                        [-74.27405621993391, 40.786634576562875],
                        [-74.27405382669971, 40.786626573424286],
                        [-74.27405401200646, 40.78662649502558]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 9,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27468402868999, 40.78662934286169],
                        [-74.27464980182727, 40.786613847423325],
                        [-74.27461977075325, 40.7866143211308],
                        [-74.27455970860522, 40.786618110790386],
                        [-74.2745221697627, 40.78662569010896],
                        [-74.27445022031455, 40.78664748064506],
                        [-74.27443019959854, 40.7866650078102],
                        [-74.27442707136167, 40.786674481951565],
                        [-74.2744274701617, 40.78667946413003],
                        [-74.27442727660609, 40.78667955263104]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 10,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27432271356544, 40.78705034898934],
                        [-74.27432292739024, 40.78705011918769]
                    ],
                    [
                        [-74.27434591326443, 40.78705995073927],
                        [-74.27434569865841, 40.787059920081276],
                        [-74.274342535466, 40.78704774555942],
                        [-74.27432271356544, 40.78705034898934]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 11,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27435734091358, 40.787014642615105],
                        [-74.27435754423536, 40.78701446336555]
                    ],
                    [
                        [-74.27435754423536, 40.78701446336555],
                        [-74.27435728761559, 40.78702845281313],
                        [-74.27435058209306, 40.787043683929056],
                        [-74.27435517752168, 40.78706127420412],
                        [-74.27435496279543, 40.78706124352895]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 12,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27445744738053, 40.78706653263585],
                        [-74.27445748422188, 40.78706629832498],
                        [-74.2744653438266, 40.78707324097269],
                        [-74.27447404583965, 40.787077402233734],
                        [-74.27448686985886, 40.78708191026624],
                        [-74.27450976989319, 40.78708225703795],
                        [-74.27451663990348, 40.787078789320674],
                        [-74.27451847190623, 40.78707220065735],
                        [-74.27451755590485, 40.7870652652216],
                        [-74.27451690948604, 40.78706053405303],
                        [-74.27451711437206, 40.787060556214165]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 13,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27461848427166, 40.78709431017879],
                        [-74.27461833475213, 40.78709451017879]
                    ],
                    [
                        [-74.27457442176406, 40.787054563353564],
                        [-74.27457727061211, 40.78706613263299],
                        [-74.27458162920178, 40.787078317519104],
                        [-74.27459135220944, 40.787090502402975],
                        [-74.27460643963516, 40.78709431017879],
                        [-74.27461848427166, 40.78709431017879]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 14,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27462861431903, 40.78710818066561],
                        [-74.2746287871064, 40.78710807516192],
                        [-74.27464808610048, 40.7871308050608],
                        [-74.27466781651518, 40.78711858246909],
                        [-74.27466801135701, 40.78711869703478]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 15,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27466762436866, 40.78711870149996],
                        [-74.27466781651518, 40.78711858246909],
                        [-74.27467387298611, 40.787122143633255],
                        [-74.27467383087428, 40.78712235088351]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 16,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27462363899377, 40.787134046651715],
                        [-74.27461311222709, 40.78711677776468],
                        [-74.27461329311727, 40.78711669026275]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 17,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27428954008623, 40.78722295647549],
                        [-74.27428933944353, 40.78722294035208]
                    ],
                    [
                        [-74.27432485020391, 40.78711746111941],
                        [-74.27432481401935, 40.78711765781972]
                    ],
                    [
                        [-74.27432481401935, 40.78711765781972],
                        [-74.27431068423395, 40.78711501627572],
                        [-74.27428954008623, 40.78722295647549]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 18,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27429688852519, 40.787091074747806],
                        [-74.27429703762691, 40.78709081592402]
                    ],
                    [
                        [-74.27429703762691, 40.78709081592402],
                        [-74.27429727318888, 40.78709191577329],
                        [-74.27430263760691, 40.78709750051087],
                        [-74.27431101951007, 40.78710232369295],
                        [-74.27432174834614, 40.787105116061355],
                        [-74.27432698847223, 40.78710583742861],
                        [-74.27432695225096, 40.78710603432851]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 19,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27553755865509, 40.78739985288682],
                        [-74.27553735357716, 40.78739983610061],
                        [-74.27553886358787, 40.7873952629503],
                        [-74.27553886358787, 40.78739094749065],
                        [-74.2755304816847, 40.787375970304964],
                        [-74.27552109395315, 40.78736023156384],
                        [-74.27551944168712, 40.787320946571185],
                        [-74.27551944168712, 40.787306261794384],
                        [-74.27552569816088, 40.78729489293272],
                        [-74.27553821110838, 40.787283524069124],
                        [-74.27555698052963, 40.78727405001462],
                        [-74.27556824218239, 40.7872569967131],
                        [-74.27557637559828, 40.78724562784299],
                        [-74.27558513466153, 40.787226206018715],
                        [-74.27558681628935, 40.78721379203532],
                        [-74.27558701483159, 40.78721381628132]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 20,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27374478804738, 40.788214768303256],
                        [-74.27374470634446, 40.78821514280486],
                        [-74.27372196109525, 40.78831940018111],
                        [-74.27372165007914, 40.788319311222324],
                        [-74.27372143081678, 40.788319248507534]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 21,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.2740320621458, 40.788561691033124],
                        [-74.27403186250747, 40.78856166944008]
                    ],
                    [
                        [-74.27372186934149, 40.78831937393712],
                        [-74.27372165007914, 40.788319311222324],
                        [-74.27369380696337, 40.78831134736049],
                        [-74.27369130437387, 40.788331242562606],
                        [-74.27367378624734, 40.78841698134164],
                        [-74.27370631991087, 40.788424086760386],
                        [-74.27372258674262, 40.78844303453988],
                        [-74.27375324346401, 40.78848093008267],
                        [-74.27378515148015, 40.788502246316],
                        [-74.27392842472909, 40.78852498362397],
                        [-74.27393342990808, 40.78850366739795],
                        [-74.27404166690401, 40.78851361497095],
                        [-74.2740320621458, 40.788561691033124]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 22,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27439184353335, 40.78680694758918],
                        [-74.27439204176461, 40.78680697415376],
                        [-74.2743877669831, 40.78683754233933],
                        [-74.27437494709058, 40.786855661173036],
                        [-74.27429460909747, 40.78691260604674],
                        [-74.27428777182145, 40.78691584154947],
                        [-74.27426640533392, 40.7869294306592],
                        [-74.27423307361337, 40.78696049147092],
                        [-74.27413307845174, 40.787069204197735],
                        [-74.27404932182063, 40.787138443758295],
                        [-74.2740347926091, 40.78715397411713],
                        [-74.2740279553331, 40.78716756317809],
                        [-74.27404761250163, 40.78718956450902],
                        [-74.27406229308208, 40.78722522322877],
                        [-74.27409232415611, 40.78726833019752],
                        [-74.27409232415611, 40.78727969906373],
                        [-74.27408606768236, 40.787292489035934],
                        [-74.27407793426649, 40.787305752708164],
                        [-74.27406417002423, 40.78732043748502],
                        [-74.2740497801346, 40.787336543365655],
                        [-74.27404039542397, 40.787358333668465],
                        [-74.27404164671871, 40.78738154507013],
                        [-74.27403226200809, 40.78740049314709],
                        [-74.2739700703911, 40.78740489590365],
                        [-74.27393673867056, 40.78740813138239],
                        [-74.27391109888552, 40.78741266105237],
                        [-74.27388289512199, 40.7874404861611],
                        [-74.27382905157341, 40.78750001891271],
                        [-74.27380512110739, 40.78752266722813],
                        [-74.27378375461986, 40.78754272715818],
                        [-74.27377178938683, 40.78754855100772],
                        [-74.27373503902828, 40.7875614928937],
                        [-74.27370598060524, 40.7875737876831],
                        [-74.27368974207472, 40.787588670846134],
                        [-74.2736760675227, 40.78761002494383],
                        [-74.27364700909968, 40.787661145331526],
                        [-74.27359145623208, 40.78776468017344],
                        [-74.27358376429657, 40.78777697492518],
                        [-74.27357179906356, 40.78779315222661],
                        [-74.27356667110655, 40.78781256498323],
                        [-74.27356667110655, 40.78782550681774],
                        [-74.27357265372306, 40.78784491956483],
                        [-74.27357863633958, 40.787860449758455],
                        [-74.27358120031808, 40.78788956886169],
                        [-74.27353816525525, 40.78797782540474],
                        [-74.27353862325593, 40.7880010587925],
                        [-74.27354778326963, 40.78804475141007],
                        [-74.27355556928131, 40.78805307381015],
                        [-74.27356656129778, 40.78806625094151],
                        [-74.27357801131495, 40.78807249273967],
                        [-74.27359037733349, 40.78808254896876],
                        [-74.2736064073575, 40.78809052459769],
                        [-74.27362564338634, 40.78809225842991],
                        [-74.27365174942545, 40.78809052459769],
                        [-74.27367602346185, 40.78810924998348],
                        [-74.27371495352018, 40.78814253954528],
                        [-74.27371495352018, 40.78815224899765],
                        [-74.27371312151745, 40.7881740952603],
                        [-74.27371312151745, 40.788193514154386],
                        [-74.27372548753597, 40.7882070380237],
                        [-74.27374511004814, 40.7882132923487],
                        [-74.27374470634446, 40.78821514280486],
                        [-74.27374462602053, 40.78821551098559]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 23,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27351281730154, 40.78752816946934],
                        [-74.27352014531252, 40.78754238701279],
                        [-74.27352243531595, 40.78754273378209],
                        [-74.27353525933516, 40.787542040243466],
                        [-74.27355037335782, 40.78753406454871],
                        [-74.27356319737703, 40.787523661467105],
                        [-74.27356548738047, 40.78751464546173],
                        [-74.27356273937636, 40.78750771007219],
                        [-74.27355128935919, 40.787505629455154],
                        [-74.2735375493386, 40.78750805684169],
                        [-74.27351281730154, 40.78752816946934]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 24,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27473140458228, 40.788405000173285],
                        [-74.27473161721547, 40.788405024213795],
                        [-74.27471992639599, 40.78842769252981],
                        [-74.27472221639943, 40.78844641782055],
                        [-74.27473137641314, 40.78845023223096],
                        [-74.27474796100638, 40.78844705698867],
                        [-74.27474784520746, 40.78844728279183]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 25,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27454411081368, 40.78850896215858],
                        [-74.27454414977129, 40.788508738274516]
                    ],
                    [
                        [-74.27454414977129, 40.788508738274516],
                        [-74.27454867112034, 40.78851229843508],
                        [-74.2745644290983, 40.78852016766959],
                        [-74.27458286928528, 40.78852295997842],
                        [-74.27458991008393, 40.7885163599756],
                        [-74.27459594505423, 40.788506206123856],
                        [-74.27459460394972, 40.788502144582736],
                        [-74.27459091591231, 40.788493767653364],
                        [-74.27458052235238, 40.78848945226511],
                        [-74.27456979351633, 40.78848843687956],
                        [-74.27454768237506, 40.78848843687956],
                        [-74.27454771717665, 40.78848823687956]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 26,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27471947170582, 40.788486974936745],
                        [-74.2747005512058, 40.78848234456618],
                        [-74.27467540549628, 40.78847777533073],
                        [-74.27466031807057, 40.7884739676343],
                        [-74.27464590119712, 40.78847650609859],
                        [-74.27462008493536, 40.78848995995784],
                        [-74.27460835027092, 40.78849554457787],
                        [-74.27460432695739, 40.78850062150474],
                        [-74.27460063891999, 40.78850975997216],
                        [-74.27460130947225, 40.788518644592045],
                        [-74.27461438524121, 40.78852752921071],
                        [-74.27462846683852, 40.78853793690541],
                        [-74.27463322204392, 40.78854182910888],
                        [-74.27463301898264, 40.78854192135463]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 27,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27410340684267, 40.78856801460097],
                        [-74.27410320428199, 40.788568001145194],
                        [-74.27411438956142, 40.78852202840966],
                        [-74.2745171977508, 40.788561929260936],
                        [-74.27452419990595, 40.788558580938684],
                        [-74.27452677964735, 40.788552721374316],
                        [-74.2745279679075, 40.78854439949792],
                        [-74.27452816692627, 40.78854442057658]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 28,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27391145171353, 40.78798712032052],
                        [-74.27391165079132, 40.78798714662685]
                    ],
                    [
                        [-74.27391165079132, 40.78798714662685],
                        [-74.27390807927877, 40.788003033251215],
                        [-74.2739084941467, 40.78800351961462],
                        [-74.27390845195005, 40.78800377457553]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 29,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.2739079578427, 40.78804089554293],
                        [-74.27390822425001, 40.78804092607999]
                    ],
                    [
                        [-74.27390822425001, 40.78804092607999],
                        [-74.27390716327739, 40.7880418711338],
                        [-74.27390258566334, 40.788062666261936],
                        [-74.27390238402556, 40.788062651949474]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 30,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.2743030092124, 40.78813577038542],
                        [-74.2743027395083, 40.78813574938188]
                    ],
                    [
                        [-74.27430378267103, 40.78813479810033],
                        [-74.27430404890428, 40.78813482576673],
                        [-74.2743030092124, 40.78813577038542]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 31,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27467869381785, 40.78796148416629],
                        [-74.27467890053968, 40.787961444607355]
                    ],
                    [
                        [-74.27467560131491, 40.78798787230107],
                        [-74.27467535847747, 40.7879874586161],
                        [-74.27467869381785, 40.78796148416629]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 32,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27437968704184, 40.788143699575585],
                        [-74.27437937820977, 40.78814366493468]
                    ],
                    [
                        [-74.27438072466573, 40.788142793751334],
                        [-74.27438104167705, 40.78814282669448],
                        [-74.27437968704184, 40.788143699575585]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 33,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.2744373933439, 40.78815017234365],
                        [-74.27443720037134, 40.78814866258842],
                        [-74.2744374047126, 40.78814868382314]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 34,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27529512165296, 40.78810103200181],
                        [-74.27529438697022, 40.788101337394444],
                        [-74.27530830603179, 40.78809079884445],
                        [-74.2753133112108, 40.78807800902601],
                        [-74.27531769074241, 40.788064745507995],
                        [-74.27532269592142, 40.788046271317825],
                        [-74.27532707545305, 40.788026849727714],
                        [-74.27533520886892, 40.78800079636619],
                        [-74.27534396793219, 40.78794584742482],
                        [-74.27533896275318, 40.7879340049746],
                        [-74.27533896275318, 40.78791742554074],
                        [-74.27534146534268, 40.78790653048193],
                        [-74.27534897311118, 40.78788995104122],
                        [-74.27535773217443, 40.78787431899332],
                        [-74.27535835782182, 40.78785773954456],
                        [-74.27536273735343, 40.78784637077726],
                        [-74.27536961947457, 40.787831686116704],
                        [-74.2753689938272, 40.78782173844155],
                        [-74.2753689938272, 40.78780705377554],
                        [-74.27538275806944, 40.78780279048476],
                        [-74.27542644568298, 40.78780510590159]
                    ],
                    [
                        [-74.27542644568298, 40.78780510590159],
                        [-74.27542630545024, 40.787805298750044]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 35,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27481199756285, 40.7879621997757],
                        [-74.27480675291955, 40.78795798070618],
                        [-74.27479837101639, 40.78795544222206],
                        [-74.27478931856095, 40.78795823455462],
                        [-74.2747849599713, 40.78796331152259],
                        [-74.27478764218031, 40.78797321160897],
                        [-74.27479170693258, 40.78797676263355]
                    ],
                    [
                        [-74.27479170693258, 40.78797676263355],
                        [-74.27479191964882, 40.78797694846545]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 36,
            type: 'Feature',
            geometry: {
                type: 'MultiLineString',
                coordinates: [
                    [
                        [-74.27484198380584, 40.78797454331023],
                        [-74.27484195172367, 40.78797474110377]
                    ],
                    [
                        [-74.27484195172367, 40.78797474110377],
                        [-74.27482619893492, 40.787971180822126],
                        [-74.27481748175562, 40.787966611551525],
                        [-74.27481153075576, 40.78796182425129]
                    ],
                    [
                        [-74.27479149793149, 40.787976580047264],
                        [-74.27479170693258, 40.78797676263355],
                        [-74.27479635935961, 40.787980827059016],
                        [-74.27480306488215, 40.787988188659995],
                        [-74.27480641764342, 40.788001642618156],
                        [-74.2748033388644, 40.78801364748068],
                        [-74.27480313796471, 40.788013625751034]
                    ]
                ]
            },
            properties: { edge_type: 'S' }
        },
        {
            id: 0,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27588638875889, 40.787585414582395],
                    [-74.27589160360492, 40.78758132503589],
                    [-74.27593451894917, 40.78755340154669],
                    [-74.27597709901725, 40.787529793496674],
                    [-74.27599630086675, 40.78752175459629],
                    [-74.27605375766862, 40.78750087330037],
                    [-74.2760810346166, 40.787492985229875],
                    [-74.27611793759606, 40.787483329273954],
                    [-74.27618271217804, 40.78747298069078],
                    [-74.27623967661249, 40.78746998034754],
                    [-74.2762433978673, 40.78746993700133],
                    [-74.27634798502869, 40.78747321693234],
                    [-74.27634803327143, 40.78747325853186],
                    [-74.27633337724947, 40.78747915361609],
                    [-74.27629719519524, 40.78748296808209],
                    [-74.27626009713964, 40.78748400839095],
                    [-74.27621887707787, 40.78748400839095],
                    [-74.27618269502364, 40.787485395469446],
                    [-74.27612498693715, 40.78749475824833],
                    [-74.27604299778503, 40.787520795562386],
                    [-74.27599193007956, 40.787538456141306],
                    [-74.27595688904003, 40.78755592768948],
                    [-74.27591415606496, 40.78757792889175],
                    [-74.27588638875889, 40.787585414582395]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 1,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.2754558061977, 40.78741729387852],
                    [-74.2754810741759, 40.78741949860777],
                    [-74.27550652814439, 40.78742187601904],
                    [-74.27548257695078, 40.78755515129817],
                    [-74.27546637668264, 40.78764272258167]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 2,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.2754587851444, 40.78765760165394],
                    [-74.2754448735517, 40.78766554188866],
                    [-74.27544163626482, 40.78766710182499]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 3,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27413773682619, 40.78760931477846],
                    [-74.27407848826245, 40.78760318293968],
                    [-74.27408452323272, 40.787571705561334],
                    [-74.27408107240075, 40.787568690852396],
                    [-74.27406345364717, 40.78756641486273],
                    [-74.27406644933244, 40.78755189874184],
                    [-74.27408789969954, 40.787548706092586],
                    [-74.27409035846514, 40.78755030915647],
                    [-74.27410142257733, 40.787556655405325],
                    [-74.27411349251788, 40.78755944775463],
                    [-74.27413159742873, 40.78756249395377],
                    [-74.27414684609509, 40.78756448452746]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 4,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27479444517394, 40.78683146711814],
                    [-74.27479482140744, 40.78682896504009],
                    [-74.27479478383336, 40.78682896139277],
                    [-74.27479484143416, 40.786828636103394],
                    [-74.27476531979731, 40.78682610132127],
                    [-74.27461176064219, 40.786811195346985],
                    [-74.27460707527943, 40.78683526767123],
                    [-74.27460463429642, 40.78683351939408],
                    [-74.27459466155972, 40.786826172660085],
                    [-74.2745740182576, 40.78680750999617],
                    [-74.27455839909423, 40.78679064702449],
                    [-74.2745483934542, 40.7867771790641],
                    [-74.27454180442413, 40.78676264984233],
                    [-74.27453899046597, 40.78675102851105]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 5,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27459517645545, 40.78669218561698],
                    [-74.27460276768996, 40.78669078753293],
                    [-74.27461266448833, 40.78668949557953],
                    [-74.27464037560541, 40.786687763171614],
                    [-74.27467658542712, 40.786688778584704],
                    [-74.27468226991715, 40.78668888618454],
                    [-74.27471267799574, 40.786690881544196],
                    [-74.27473291181643, 40.78669436335619],
                    [-74.27476355729056, 40.78670037898003]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 6,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27467914161336, 40.787017476356695],
                    [-74.27467976058429, 40.787017710681454],
                    [-74.27468546027846, 40.78702532624109],
                    [-74.27468822067243, 40.787031364056105],
                    [-74.27468849997287, 40.78703245665297],
                    [-74.27468911466565, 40.78704713284944],
                    [-74.27468844367866, 40.787050435061666]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 7,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27526299147598, 40.78611500360631],
                    [-74.27536356154808, 40.786157247750964],
                    [-74.27532744016712, 40.786175480689764],
                    [-74.27526789770361, 40.78619581211689],
                    [-74.27519201025012, 40.78623293905463],
                    [-74.27515114777516, 40.78625945828321],
                    [-74.27510911780092, 40.78628862942247],
                    [-74.27506241782956, 40.78629835313268],
                    [-74.2750203878553, 40.78630188902699],
                    [-74.27499353537178, 40.786323988361914],
                    [-74.27493049041043, 40.78633459604012],
                    [-74.27487561794405, 40.786348739608314],
                    [-74.2748114054834, 40.786373490845506],
                    [-74.27475419801847, 40.786395590156616],
                    [-74.27462750437526, 40.78648681231002],
                    [-74.27460648938815, 40.786509795554196],
                    [-74.27456562691319, 40.78652305511457],
                    [-74.27455395192035, 40.78652924290847],
                    [-74.27452126194038, 40.786548690257035],
                    [-74.27450374945111, 40.786569905539864],
                    [-74.2745049169504, 40.78659819257311],
                    [-74.27453877442964, 40.786613220054676],
                    [-74.27457379940817, 40.786613220054676],
                    [-74.27461466188313, 40.78660261242098],
                    [-74.27468938183733, 40.78660261242098],
                    [-74.27476993928796, 40.786604380360046],
                    [-74.27485399923644, 40.786604380360046],
                    [-74.27497191666416, 40.78659642463387],
                    [-74.27519469878048, 40.78659103554881],
                    [-74.27533479869462, 40.786615786695734],
                    [-74.27545738611948, 40.78664053783339],
                    [-74.27554728356436, 40.786665288961814],
                    [-74.2755974860336, 40.78668738817581],
                    [-74.27562260162543, 40.78669499465178],
                    [-74.27559765322489, 40.78673527393579],
                    [-74.27559548156252, 40.78673594850653],
                    [-74.27558978186836, 40.78673721777193],
                    [-74.27558337739738, 40.78673721777193],
                    [-74.27554956337184, 40.78672538156298],
                    [-74.27552507243072, 40.78671147414275],
                    [-74.27552513424787, 40.786711338072905],
                    [-74.27547243280969, 40.78669838290087],
                    [-74.27547059843859, 40.78669774080046],
                    [-74.27535316121732, 40.78666584829048],
                    [-74.2752616308347, 40.78664477845928],
                    [-74.27517613542236, 40.78662853183786],
                    [-74.27513139233253, 40.78662174728592],
                    [-74.27508680060765, 40.78661895489719],
                    [-74.27503215059897, 40.78661895489719],
                    [-74.27475023739294, 40.786627573380486],
                    [-74.27468402868999, 40.78662934286169]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 8,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27442727660609, 40.78667955263104],
                    [-74.27441695237992, 40.78668427326102],
                    [-74.27438174838662, 40.78670102757522],
                    [-74.27435450466835, 40.78671636824828],
                    [-74.27433438810074, 40.78673007631724],
                    [-74.27430924239121, 40.786747338325966],
                    [-74.27428912582359, 40.78676485418316],
                    [-74.27425626876318, 40.78679633194392],
                    [-74.27423816385233, 40.78681460934655],
                    [-74.2742116770383, 40.786843294704425],
                    [-74.27415468009673, 40.78690142694032],
                    [-74.27410740616284, 40.78694915118331],
                    [-74.27403833822089, 40.78701315250777],
                    [-74.27400799670477, 40.78704311711338],
                    [-74.27397445718051, 40.78707113265179],
                    [-74.27395126592124, 40.78708935865848],
                    [-74.27391545058867, 40.787115617810684],
                    [-74.27388914818769, 40.78713370356359],
                    [-74.27385127009524, 40.78715650969781],
                    [-74.27381334330023, 40.78717907668067],
                    [-74.27376472826182, 40.787228831533696],
                    [-74.27373641768818, 40.78725784467448],
                    [-74.27373031857796, 40.78726385706915],
                    [-74.2737244951266, 40.787268686159535],
                    [-74.27371778960406, 40.787270209265756],
                    [-74.27370974297702, 40.787270209265756],
                    [-74.27370270217835, 40.787272747776086],
                    [-74.27369800943494, 40.78727847215764],
                    [-74.2736968629371, 40.78727977424691],
                    [-74.2736968146635, 40.78727992958392],
                    [-74.27369666720807, 40.78728010945548],
                    [-74.27369660956089, 40.78728058957236],
                    [-74.2736955218326, 40.78728408971378],
                    [-74.2736941807281, 40.787285358968745],
                    [-74.27369274185621, 40.7872856941772],
                    [-74.27367932571728, 40.7872856941772],
                    [-74.27367699407193, 40.7872850500513],
                    [-74.2736632125885, 40.78728108493631],
                    [-74.27366929093748, 40.78725692358429],
                    [-74.27366984511792, 40.78725497820176],
                    [-74.27367520953595, 40.78723771632494],
                    [-74.27369432027518, 40.787201669450184],
                    [-74.27378763418842, 40.787032857346645],
                    [-74.27386666557184, 40.78688101444661],
                    [-74.2738761447609, 40.78685021789269],
                    [-74.273989, 40.786654],
                    [-74.2739904969149, 40.786653366692235]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 9,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27405401200646, 40.78662649502558],
                    [-74.27406167062665, 40.786623254852515],
                    [-74.27407347906812, 40.78662676313907],
                    [-74.27409510583063, 40.78663461424868],
                    [-74.27408892668998, 40.786645530795994],
                    [-74.27409062196705, 40.78664580725818],
                    [-74.27409015711412, 40.78664695113528],
                    [-74.27410332217181, 40.78664787838067],
                    [-74.2741186967346, 40.78665038563179],
                    [-74.27415029878198, 40.78665385337146],
                    [-74.2741759468204, 40.78665420014539],
                    [-74.27419747285268, 40.78665420014539],
                    [-74.27421441887806, 40.7866500388578],
                    [-74.27423731891238, 40.78664240982991],
                    [-74.27423924148316, 40.78664160921167],
                    [-74.27424093813156, 40.786641266648246],
                    [-74.27424754074138, 40.78663815314253],
                    [-74.27426479895358, 40.78663096628645],
                    [-74.27429731700231, 40.78661570822541],
                    [-74.27431127846663, 40.786608097183354],
                    [-74.27431226193235, 40.78660763342339],
                    [-74.27439234479637, 40.7865659471492],
                    [-74.27441540002917, 40.786552387395695],
                    [-74.27443859836454, 40.78654003228365],
                    [-74.27448184898489, 40.78651337762628],
                    [-74.27446843793982, 40.786500938782495],
                    [-74.27453318685293, 40.786466628540374]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 10,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27458169178674, 40.78644100873526],
                    [-74.27459048697492, 40.78643636326268],
                    [-74.27474950714303, 40.786350340932444],
                    [-74.27489302818107, 40.78627152698951],
                    [-74.27526299147598, 40.78611500360631]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 11,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27432292739024, 40.78705011918769],
                    [-74.27434953047306, 40.787021528340944],
                    [-74.27435734091358, 40.787014642615105]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 12,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27435496279543, 40.78706124352895],
                    [-74.27434591326443, 40.78705995073927]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 13,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27461833475213, 40.78709451017879],
                    [-74.27461628645307, 40.78709725002046],
                    [-74.27461492117008, 40.787098207162444],
                    [-74.27460285819969, 40.78710277385412],
                    [-74.27460210581269, 40.78710299991122],
                    [-74.27459168748557, 40.78710497194976],
                    [-74.274576935336, 40.787104210394745],
                    [-74.27456077853617, 40.78710131895986],
                    [-74.27455691305369, 40.78710011009741],
                    [-74.27455577425361, 40.7870998470431],
                    [-74.27454810158909, 40.787096848695825],
                    [-74.27453804330527, 40.787095325585625],
                    [-74.27453334240408, 40.787095325585625],
                    [-74.27452372071713, 40.78709477921174],
                    [-74.27451564598479, 40.787095576653556],
                    [-74.27450350986422, 40.7870960871407],
                    [-74.2744557411099, 40.787100386801896],
                    [-74.27445556549759, 40.78710039981426],
                    [-74.27445684525172, 40.787070362172216],
                    [-74.27445744738053, 40.78706653263585]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 14,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27451711437206, 40.787060556214165],
                    [-74.27452372071713, 40.7870612707778],
                    [-74.27453042623966, 40.7870612707778],
                    [-74.27454011932079, 40.78705963987897],
                    [-74.27454340772331, 40.78705978633718],
                    [-74.27456520067156, 40.78705597855949],
                    [-74.27457442176406, 40.787054563353564]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 15,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27467230410284, 40.787129864774776],
                    [-74.27466038791115, 40.78713802775968],
                    [-74.27462363899377, 40.787134046651715]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 16,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27461329311727, 40.78711669026275],
                    [-74.2746202802417, 40.78711331038389],
                    [-74.27462229189845, 40.78711204112568],
                    [-74.27462581436772, 40.78710989031148],
                    [-74.27462753425834, 40.7871090764356],
                    [-74.27462798422977, 40.787108565397126],
                    [-74.27462861431903, 40.78710818066561]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 17,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27467383087428, 40.78712235088351],
                    [-74.27467230410284, 40.787129864774776]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 18,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27428933944353, 40.78722294035208],
                    [-74.2742612951671, 40.78722068674723],
                    [-74.27427176732243, 40.787170410359955],
                    [-74.27428588801843, 40.78711274604942],
                    [-74.27429259354096, 40.787098530356076],
                    [-74.27429688852519, 40.787091074747806]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 19,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27432695225096, 40.78710603432851],
                    [-74.27432485020391, 40.78711746111941]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 20,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27560899188549, 40.78721650012248],
                    [-74.27560810364061, 40.78722168291631],
                    [-74.27560807439426, 40.787221703532765],
                    [-74.27560777556103, 40.78722359721814],
                    [-74.27557701476448, 40.78740308248129],
                    [-74.27553755865509, 40.78739985288682]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 21,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27558701483159, 40.78721381628132],
                    [-74.27560899188549, 40.78721650012248]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 22,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27292706207918, 40.788512273535716],
                    [-74.27321030071762, 40.78800788841204],
                    [-74.27322352131773, 40.78799259472527],
                    [-74.27323502833661, 40.787983640383466],
                    [-74.27323531838904, 40.78798583646338],
                    [-74.2732342501538, 40.78799614860105],
                    [-74.27323389137212, 40.787996977118446],
                    [-74.2731967164954, 40.78806712244427],
                    [-74.27318601268448, 40.78808638876802],
                    [-74.27302108735114, 40.78838108517624],
                    [-74.27299800293484, 40.78842333652259],
                    [-74.27297084642618, 40.78847187853515],
                    [-74.27296640548015, 40.78848116926067],
                    [-74.2729620726352, 40.78848909965538],
                    [-74.27296010832251, 40.78849092328576],
                    [-74.27293409531318, 40.78851419934813],
                    [-74.27292706207918, 40.788512273535716]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 23,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27331312211746, 40.787829119183385],
                    [-74.27348106091078, 40.787537133322445],
                    [-74.27348531133997, 40.78754284375665],
                    [-74.2734828270556, 40.78755262161826],
                    [-74.27343281571784, 40.787640462407545],
                    [-74.2734207551389, 40.78765997038727],
                    [-74.27339297656867, 40.78771043658667],
                    [-74.27332341764591, 40.787832611096505],
                    [-74.27331854854503, 40.787838708100935],
                    [-74.2733108371941, 40.78784505432225],
                    [-74.27331312211746, 40.787829119183385]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 24,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27403186250747, 40.78856166944008],
                    [-74.27397561043138, 40.78855558517046],
                    [-74.27371659918774, 40.788526647178145],
                    [-74.27368962332434, 40.78852229881587],
                    [-74.27366999966658, 40.788518230668984],
                    [-74.27361994787655, 40.78850165138098],
                    [-74.27357302432341, 40.788479387759196],
                    [-74.27353235724402, 40.78845333457522],
                    [-74.27351005004078, 40.7884336216583],
                    [-74.27349182534111, 40.78841206163435],
                    [-74.27347595609206, 40.78839159851887],
                    [-74.27346259335611, 40.788372396303394],
                    [-74.27345076556368, 40.788353366610615],
                    [-74.27343885803646, 40.78832744698491],
                    [-74.27343063971739, 40.78829387779504],
                    [-74.27343018171669, 40.78825989477801],
                    [-74.27343551847616, 40.78819761500044],
                    [-74.27344013694042, 40.78815888819563],
                    [-74.27344796589479, 40.78810032411769],
                    [-74.2734516299003, 40.78806564746847],
                    [-74.27345437790441, 40.788052470336964],
                    [-74.2734511718996, 40.78803686583578],
                    [-74.27344321746796, 40.78801273274388],
                    [-74.27343114752738, 40.7879848094361],
                    [-74.2734163953778, 40.78795688611655],
                    [-74.27339826931346, 40.78793481205315],
                    [-74.27337781746971, 40.78791526571598],
                    [-74.27335468341697, 40.78789825785955],
                    [-74.27333423157322, 40.787884042334326],
                    [-74.27332283218492, 40.78787287299096],
                    [-74.2733242533119, 40.787869768083794],
                    [-74.27343436251827, 40.78766854225262],
                    [-74.2735052810873, 40.78755129682585],
                    [-74.27350184243929, 40.787545221926905],
                    [-74.27350501155755, 40.787539430327115],
                    [-74.27350409139837, 40.78751588225884],
                    [-74.27350450975426, 40.787515454998726],
                    [-74.27352730853089, 40.787504031743225],
                    [-74.27368051798994, 40.78741185095217],
                    [-74.27371136339362, 40.78738798900122],
                    [-74.27371581895557, 40.78738464618938],
                    [-74.27372265038973, 40.78738018196478],
                    [-74.27372953981948, 40.78737435202966],
                    [-74.27374824376757, 40.78736031928142],
                    [-74.2737673545068, 40.787343818984255],
                    [-74.27379015328343, 40.78732300321903],
                    [-74.27381429316456, 40.78729863353428],
                    [-74.27385352047142, 40.78725522501125],
                    [-74.27387739636272, 40.7872284610604],
                    [-74.27389408207259, 40.78721442391366],
                    [-74.27389542998729, 40.78721333956739],
                    [-74.27391085268911, 40.78720394707031],
                    [-74.27397484635972, 40.78716587798618],
                    [-74.27406232449083, 40.787109701014856],
                    [-74.27407837082808, 40.787097431364366],
                    [-74.27408080692648, 40.78709582982279],
                    [-74.27408228020424, 40.78709444210405],
                    [-74.27410858256016, 40.78707433030476],
                    [-74.27414874833435, 40.78703390106347],
                    [-74.2741571594336, 40.78702391140577],
                    [-74.27417575843052, 40.78700639252568],
                    [-74.27420119320908, 40.78697142192366],
                    [-74.27428262136904, 40.7868719877531],
                    [-74.27431581370558, 40.78683670223788],
                    [-74.27433559499708, 40.78682147107446],
                    [-74.27434672660847, 40.78681610764382],
                    [-74.2743544606061, 40.78681270313197],
                    [-74.27438932932331, 40.7868066106648],
                    [-74.27439184353335, 40.78680694758918]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 25,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27453717285798, 40.78854526945591],
                    [-74.27453778231492, 40.78854533118309],
                    [-74.27454411081368, 40.78850896215858]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 26,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27454771717665, 40.78848823687956],
                    [-74.27456546562469, 40.78838623895457],
                    [-74.27473140458228, 40.788405000173285]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 27,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27474784520746, 40.78844728279183],
                    [-74.27473492467749, 40.78847247729419],
                    [-74.27471947170582, 40.788486974936745]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 28,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27463301898264, 40.78854192135463],
                    [-74.274619924966, 40.788547869644646],
                    [-74.27458639735332, 40.78855497733659],
                    [-74.27455713174359, 40.78856682452164],
                    [-74.27449433648174, 40.78858236173883],
                    [-74.27442517837808, 40.78858860348845],
                    [-74.27438373189266, 40.78859051983176],
                    [-74.27437558566533, 40.78859186294437],
                    [-74.2743686521211, 40.7885923986138],
                    [-74.27433838040426, 40.788591088929365],
                    [-74.27420292884901, 40.78857763509063],
                    [-74.27412212730243, 40.78856925817078],
                    [-74.27410340684267, 40.78856801460097]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 29,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27452816692627, 40.78854442057658],
                    [-74.27453715614625, 40.78854537265093],
                    [-74.27453717285798, 40.78854526945591]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 30,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27391657061072, 40.78795471994624],
                    [-74.2739112134799, 40.78798708884011],
                    [-74.27391145171353, 40.78798712032052]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 31,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27390845195005, 40.78800377457553],
                    [-74.27390241351968, 40.78804026002231],
                    [-74.2739079578427, 40.78804089554293]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 32,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27390238402556, 40.788062651949474],
                    [-74.27389875028862, 40.78806239402303],
                    [-74.27389321257571, 40.788095854031326],
                    [-74.27381535245901, 40.7880864913372],
                    [-74.27384145849815, 40.78794743783544],
                    [-74.27391657061072, 40.78795471994624]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 33,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.2743027395083, 40.78813574938188],
                    [-74.27430120783872, 40.78813563010121],
                    [-74.27430149053629, 40.78813455990649],
                    [-74.27430378267103, 40.78813479810033]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 34,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27467890053968, 40.787961444607355],
                    [-74.27469557359385, 40.78795825399973],
                    [-74.27469761785125, 40.78796508241101],
                    [-74.27470004063503, 40.787977189237864],
                    [-74.27469433036491, 40.787980153874706],
                    [-74.27467560131491, 40.78798787230107]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 35,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27437937820977, 40.78814366493468],
                    [-74.27437563913888, 40.788143245532666],
                    [-74.27437581304247, 40.78814228334573],
                    [-74.27438072466573, 40.788142793751334]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 36,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27444829628662, 40.78814981565271],
                    [-74.27444805878228, 40.78815136865856],
                    [-74.2744373933439, 40.78815017234365]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 37,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.2744374047126, 40.78814868382314],
                    [-74.27444829628662, 40.78814981565271]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 38,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27451491073023, 40.78815831028055],
                    [-74.27446294293055, 40.78815145639385],
                    [-74.274462965629, 40.788151340060054],
                    [-74.27451432421661, 40.78815667713695],
                    [-74.27451777024828, 40.78813867064685],
                    [-74.2745182634915, 40.788138764009204],
                    [-74.27451491073023, 40.78815831028055]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 39,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27326886051415, 40.78857917848288],
                    [-74.27325036655591, 40.78854376106193],
                    [-74.27321876450856, 40.78850249612282],
                    [-74.27319861247834, 40.7884848111411],
                    [-74.27316563642893, 40.78846747291911],
                    [-74.27313357638089, 40.78845741674826],
                    [-74.2731093023445, 40.788454642631905],
                    [-74.273082280304, 40.78845637645466],
                    [-74.2730181602079, 40.788473714679554],
                    [-74.27297214995048, 40.788496731041306],
                    [-74.2729758189564, 40.78849316119732],
                    [-74.27298721634494, 40.78847251486772],
                    [-74.27315059384483, 40.78818337348932],
                    [-74.27318918261146, 40.788110602156074],
                    [-74.27324719173345, 40.78800601750848],
                    [-74.27328071934613, 40.787968955657306],
                    [-74.27328809542092, 40.78796870180889],
                    [-74.27330318284663, 40.78796997105076],
                    [-74.27331558806333, 40.78797276338267],
                    [-74.27331972917428, 40.787974367524605],
                    [-74.27334402818965, 40.787986271831606],
                    [-74.27334467322888, 40.78798677501086],
                    [-74.27335878007325, 40.78800409513246],
                    [-74.27336688531074, 40.78801718682945],
                    [-74.27337376052405, 40.78803529271157],
                    [-74.27337474075276, 40.78803852329933],
                    [-74.27337694359456, 40.78805424865374],
                    [-74.27337694359456, 40.7880680781649],
                    [-74.27337614974475, 40.78807949808436],
                    [-74.27337380656904, 40.78809783035139],
                    [-74.2733625633427, 40.78816836323065],
                    [-74.27336190374493, 40.78817592558661],
                    [-74.27336085034047, 40.78818218794294],
                    [-74.27335582119855, 40.78823321128121],
                    [-74.27335146260891, 40.78828651920466],
                    [-74.27335414481792, 40.788299465408215],
                    [-74.2733564917508, 40.78830885775034],
                    [-74.2733578721562, 40.78831366541184],
                    [-74.27335818381108, 40.788317103727195],
                    [-74.27336336322126, 40.788332789636094],
                    [-74.27336655003461, 40.788343888636405],
                    [-74.27337566421238, 40.78836352648136],
                    [-74.27339075163809, 40.78839271886363],
                    [-74.27340516851156, 40.78841505737361],
                    [-74.27342059121338, 40.78843587279661],
                    [-74.27343328893484, 40.78844987117219],
                    [-74.27345307022632, 40.78846967119844],
                    [-74.27346964914311, 40.788484813699014],
                    [-74.27348018504927, 40.7884961603161],
                    [-74.27350012795603, 40.78850782795255],
                    [-74.27350624984426, 40.7885122807933],
                    [-74.27352535563192, 40.78852258745347],
                    [-74.27353524201828, 40.78852837150308],
                    [-74.27359276347478, 40.788554799870454],
                    [-74.27359677439851, 40.788556703879095],
                    [-74.27359738126265, 40.78855692152386],
                    [-74.27360844276119, 40.788562003755175],
                    [-74.27363083049401, 40.78856891770198],
                    [-74.27363499587698, 40.78857041156817],
                    [-74.27363660607021, 40.788570701358545],
                    [-74.2736559919617, 40.788576688254295],
                    [-74.27369090426902, 40.78858025174534],
                    [-74.27373122012538, 40.788586911560515],
                    [-74.27379525786563, 40.788593511556286],
                    [-74.27405114234783, 40.78861877604672],
                    [-74.274562, 40.788671],
                    [-74.27440333550615, 40.78866326411091],
                    [-74.27438713078409, 40.78866036416852],
                    [-74.27416627726062, 40.78864520599495],
                    [-74.27367451842369, 40.78860162622669],
                    [-74.27362008710203, 40.78858646803971],
                    [-74.27357816872788, 40.78857462570372],
                    [-74.27352811693787, 40.78856467813986],
                    [-74.27348119338471, 40.78856136228495],
                    [-74.27345116231069, 40.78856088859135],
                    [-74.27341925429455, 40.78855046733179],
                    [-74.27339360275218, 40.78853909868312],
                    [-74.27332415589352, 40.78853294066431],
                    [-74.2732997556459, 40.78855188841274],
                    [-74.27326886051415, 40.78857917848288]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 40,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27471332198806, 40.787969722663554],
                    [-74.27471178925965, 40.7879588141688],
                    [-74.27472654140922, 40.78795627568468],
                    [-74.27473760552141, 40.787903982890555],
                    [-74.27480266762579, 40.787910049474654],
                    [-74.27480327245195, 40.78792745095623],
                    [-74.27471332198806, 40.787969722663554]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 41,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27537792391368, 40.78792145275066],
                    [-74.27535766972878, 40.787989218183924],
                    [-74.27534209770543, 40.78804747501141],
                    [-74.27532984795, 40.78808276826359],
                    [-74.27532844457319, 40.78808447476709],
                    [-74.27529912185412, 40.788099369199784],
                    [-74.27529512165296, 40.78810103200181]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 42,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27542630545024, 40.787805298750044],
                    [-74.27541545402845, 40.78782022165552],
                    [-74.27539255399412, 40.787866688533114],
                    [-74.27537792391368, 40.78792145275066]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 43,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27482735822079, 40.78793035858804],
                    [-74.27483248866531, 40.787913121440056],
                    [-74.27485327578518, 40.78791692916866],
                    [-74.27485060277937, 40.78793054395904],
                    [-74.2748495703577, 40.78793503012537],
                    [-74.27482735822079, 40.78793035858804]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 44,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27503564294477, 40.7878553438766],
                    [-74.27504842586713, 40.787877340189084],
                    [-74.27507542750908, 40.78787938457879],
                    [-74.27507038950152, 40.787901230931226],
                    [-74.27504474146309, 40.787897763256694],
                    [-74.27502687943633, 40.78791544839481],
                    [-74.27503649745073, 40.78793625443359],
                    [-74.27501955142534, 40.78795012512248],
                    [-74.27502871143908, 40.78796746347956],
                    [-74.27501222341436, 40.78798272123001],
                    [-74.27502367343152, 40.787999712811626],
                    [-74.275003979402, 40.78801635762204],
                    [-74.27501909342465, 40.78803612332905],
                    [-74.27509328953586, 40.78804409896347],
                    [-74.27508687752623, 40.78807704179138],
                    [-74.27513994677889, 40.78808354123146],
                    [-74.27513994677889, 40.788093290053915],
                    [-74.27511439102743, 40.788090577570586],
                    [-74.27508562402225, 40.78808678250297],
                    [-74.27508598562115, 40.788084044725736],
                    [-74.27505992605026, 40.78808125671941],
                    [-74.27504447347246, 40.788079603511676],
                    [-74.27503695302202, 40.78807716324044],
                    [-74.27502628817189, 40.78807186422574],
                    [-74.2750251357689, 40.78807126932565],
                    [-74.27501974143543, 40.78806781344932],
                    [-74.27502090489212, 40.788056582109554],
                    [-74.2750226001345, 40.788049779448386],
                    [-74.27500176576585, 40.788047860072425],
                    [-74.27500107419458, 40.78804703461711],
                    [-74.27499180651843, 40.788035972774765],
                    [-74.27488704681758, 40.788025668770075],
                    [-74.27488932838773, 40.78801012172849],
                    [-74.27492254272492, 40.78801321365005],
                    [-74.27496305729157, 40.78801753694495],
                    [-74.27496311699825, 40.78801699070783],
                    [-74.27496948121319, 40.788017583152396],
                    [-74.2749737513567, 40.78796156843865],
                    [-74.27497191935394, 40.78795359279425],
                    [-74.27496917134984, 40.78794561714889],
                    [-74.27496504934365, 40.78793798826982],
                    [-74.27495726333198, 40.78793035938992],
                    [-74.27494627131551, 40.787924464345735],
                    [-74.27493253129492, 40.78791648869691],
                    [-74.27491726557699, 40.787912749286775],
                    [-74.27491872589033, 40.787906250217105],
                    [-74.2749189009094, 40.78790617421717],
                    [-74.27494074814902, 40.78780321318889],
                    [-74.27497842521838, 40.78780650062606],
                    [-74.27497191935394, 40.78784990933002],
                    [-74.27499827973382, 40.78785213608381],
                    [-74.27500686141296, 40.78780931717831],
                    [-74.2750627935, 40.78781536690528],
                    [-74.27506362481968, 40.78780781385917],
                    [-74.275069015074, 40.787775980888455],
                    [-74.27508931804537, 40.78777870819269],
                    [-74.27509099953242, 40.78777916867958],
                    [-74.27508092351731, 40.787837772459085],
                    [-74.27505565458556, 40.78783657670519],
                    [-74.27503564294477, 40.7878553438766]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 45,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27537642112395, 40.78816653592773],
                    [-74.27541526803645, 40.7880570176776],
                    [-74.2754352229044, 40.7879729681444],
                    [-74.27544086773098, 40.78795441769998],
                    [-74.27545881558049, 40.787876119714355],
                    [-74.27546862616501, 40.78784987438928],
                    [-74.27547915634585, 40.78783479079968],
                    [-74.27548115639182, 40.787832212392665],
                    [-74.27550692136896, 40.787806202341976],
                    [-74.27551048843267, 40.787803277132554],
                    [-74.27551082741753, 40.787803517407156],
                    [-74.27554722104306, 40.787829313437236],
                    [-74.27553845711331, 40.78783714328992],
                    [-74.27551155427618, 40.787866038906884],
                    [-74.27548214884953, 40.78790867176147],
                    [-74.27546901025465, 40.78794467281733],
                    [-74.27544569586294, 40.78803255543255],
                    [-74.27540902093108, 40.78812449942663],
                    [-74.27538338114604, 40.78816332474037],
                    [-74.27537642112395, 40.78816653592773]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 46,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.2749361399162, 40.7879646006674],
                    [-74.27493657969615, 40.78796226986623],
                    [-74.27493792080065, 40.7879602390791],
                    [-74.27494060300967, 40.78795846214027],
                    [-74.2749459674277, 40.787958208291855],
                    [-74.2749473085322, 40.78795846214027],
                    [-74.27495166712185, 40.787959985230685],
                    [-74.27495468460698, 40.787963539108176],
                    [-74.27495616718929, 40.78796671955581],
                    [-74.2749361399162, 40.7879646006674]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 47,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27484556443426, 40.78795246795832],
                    [-74.27484198380584, 40.78797454331023]
                ]
            },
            properties: { edge_type: 'H' }
        },
        {
            id: 48,
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-74.27480313796471, 40.788013625751034],
                    [-74.27480149374557, 40.78801344790952],
                    [-74.27470381411068, 40.78800158325342],
                    [-74.27470113190168, 40.78801554490525],
                    [-74.27468711554448, 40.78801334927175],
                    [-74.27469065592439, 40.7879980319353],
                    [-74.27470038446235, 40.787993220754856],
                    [-74.2748009727094, 40.78794688329265],
                    [-74.27484556443426, 40.78795246795832]
                ]
            },
            properties: { edge_type: 'H' }
        }
    ]
};
