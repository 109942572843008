import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';

const Checkbox = props => {
    const { children } = props;

    return <AntdCheckbox {...props}>{children}</AntdCheckbox>;
};

export default Object.assign(Checkbox, {
    Group: AntdCheckbox.Group
});
