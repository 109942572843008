import Mousetrap from 'mousetrap';
import { SHORTCUT_TOOL_MAPPING } from '../../Constants/Tool';

// TODO: make container specific
class KeyBinder {
    constructor() {
        this.mousetrap = new Mousetrap();
        this.mousetrap.stopCallback = function (e, element, combo) {
            return element.tagName !== 'BODY';
        };
    }

    add(key, action, AppStore) {
        const wrappedAction = () => {
            AppStore.setTrackTools({
                toolName: SHORTCUT_TOOL_MAPPING[key],
                operation: 'keyboard'
            });

            action();
        };
        this.mousetrap.bind(key, wrappedAction);
    }

    remove(key) {
        this.mousetrap.unbind(key);
    }

    resetAll() {
        this.mousetrap.reset();
    }
}

export default KeyBinder;
