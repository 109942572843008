import React from 'react';
import { List as AntdList } from 'antd';

const List = props => {
    return <AntdList {...props} />;
};

export default Object.assign(List, {
    Item: AntdList.Item
});
