import { getGeom, getCoords } from '@turf/invariant';
import { polygon as turfPolygon, lineString, featureCollection } from '@turf/helpers';
import lineIntersect from '@turf/line-intersect';
import lineOffset from '@turf/line-offset';
import lineToPolygon from '@turf/line-to-polygon';
import difference from '@turf/difference';
import simplify from '@turf/simplify';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Draw } from 'ol/interaction';
import { drawStyle } from '../MapBase';
import { GEOMETRY_TYPES, GEO_JSON } from '../../../../Constants/MapConstant';
import { undoRedoPush } from '../MapInit';
import { captureException, setExtra } from '@sentry/react';
import { isPointWithinPolygon } from '../../../../Utils/MapHelper';
import { TOOL_NAMES } from '../../../../Constants/Tool';

const { LINESTRING, POLYGON, MULTI_POLYGON } = GEOMETRY_TYPES;
class CutPolygon {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.draw = null;
        this.lineLayer = null;
        this.targetPolygon = null;
        this.timeTaken = 0;
    }

    init(id) {
        this.off();
        this.selected_layer_id = id;
        let sourceDrawnLines = new VectorSource({ wrapX: false });
        this.lineLayer = new VectorLayer({
            source: sourceDrawnLines
        });
        this.mapObj.map.addLayer(this.lineLayer);

        this.draw = new Draw({
            source: sourceDrawnLines,
            type: LINESTRING,
            style: drawStyle(id),
            dragVertexDelay: 0,
            snapTolerance: 1,
            condition: e => {
                const mouseClick = e.originalEvent.which;
                if (mouseClick == 3 || mouseClick == 2) {
                    return false;
                }
                return true;
            }
        });

        this.mapObj.map.addInteraction(this.draw);
        this.snap = this.mapObj.getSnap();
        this.snap.forEach(snap => {
            this.mapObj.map.addInteraction(snap);
        });
        this.draw.on('drawend', this.drawEnd);
        window.addEventListener('keydown', this.handleKeyDawn);
    }

    handleKeyDawn = e => {
        if (e.code == 'Backspace') {
            this.draw.removeLastPoint();
        } else if (e.code == 'Space') {
            this.draw.finishDrawing();
        }
    };

    off() {
        this.mapObj.map.removeInteraction(this.draw);
        this.lineLayer && this.mapObj.map.removeLayer(this.lineLayer);
        window.removeEventListener('keydown', this.handleKeyDawn);
    }

    drawEnd = e => {
        const drawnFeature = e.feature;
        const drawnExtent = drawnFeature.getGeometry().getExtent();
        const type = drawnFeature.getGeometry().getType();
        this.mapObj.AppStore.setLoaderState(true);
        setTimeout(() => {
            const layerPoly = this.mapObj.map.getLayerById(this.selected_layer_id);
            if (layerPoly) {
                const sourcePoly = layerPoly.getSource();
                const featuresInExtent = sourcePoly.getFeaturesInExtent(drawnExtent);
                const drawnGeoJSON = GEO_JSON.writeFeatureObject(drawnFeature);
                const drawnGeometry = getGeom(drawnGeoJSON);
                if (type == GEOMETRY_TYPES.LINESTRING) {
                    featuresInExtent.forEach(feature => {
                        const featureGeo = GEO_JSON.writeFeatureObject(feature);
                        const fcopy = Object.assign({}, featureGeo);
                        const polygon = getGeom(featureGeo);
                        try {
                            this.timeTaken = Date.now();
                            const cutPolygon = this.polygonCut(polygon, drawnGeometry);

                            if (cutPolygon != null) {
                                const features = GEO_JSON.readFeatures(cutPolygon);
                                sourcePoly.addFeatures(features);
                                sourcePoly.removeFeature(feature);
                            }
                            let currentTime = Date.now();
                            this.mapObj.AppStore.setTrackTools({
                                toolName: TOOL_NAMES.split_feature,
                                timeTaken: currentTime - this.timeTaken, // in ms
                                successfulOperation: true
                            });
                        } catch (err) {
                            setExtra('feature', JSON.stringify(fcopy));
                            setExtra('drawn', JSON.stringify(drawnGeometry));
                            setExtra('Request ID', localStorage.getItem('job_id'));
                            captureException(err);
                            let currentTime = Date.now();
                            this.mapObj.AppStore.setTrackTools({
                                toolName: TOOL_NAMES.split_feature,
                                timeTaken: currentTime - this.timeTaken,
                                successfulOperation: false,
                                hasError: true,
                                error: err
                            });
                        }
                    });
                }
                undoRedoPush();
            }
            this.mapObj.AppStore.setLoaderState(false);
            this.mapObj.map.removeLayer(this.lineLayer);
            this.mapObj.AppStore.setUpdateMapLegend();
        }, 10);
    };

    polygonCut(polygon, line) {
        const THICK_LINE_UNITS = 'inches';
        const THICK_LINE_WIDTH = 20;
        let i, j, id, intersectPoints, lineCoords, forCut, forSelect;
        let thickLineString, thickLinePolygon, clipped, polyg, intersect;
        let polyCoords = [];
        let cutPolyGeoms = [];
        let cutFeatures = [];
        let offsetLine = [];
        let retVal = null;
        let idPrefix = 'cut_';

        if ((polygon.type != POLYGON && polygon.type != MULTI_POLYGON) || line.type != LINESTRING) {
            return retVal;
        }

        if (typeof idPrefix === 'undefined') {
            idPrefix = '';
        }

        intersectPoints = lineIntersect(polygon, line);
        if (intersectPoints.features.length == 0) {
            return retVal;
        }

        lineCoords = getCoords(line);
        if (isPointWithinPolygon(lineCoords[0], polygon) || isPointWithinPolygon(lineCoords.at(-1), polygon)) {
            return retVal;
        }

        offsetLine[0] = lineOffset(line, THICK_LINE_WIDTH, { units: THICK_LINE_UNITS });
        offsetLine[1] = lineOffset(line, -THICK_LINE_WIDTH, { units: THICK_LINE_UNITS });

        for (i = 0; i <= 1; i++) {
            forCut = i;
            forSelect = (i + 1) % 2;
            polyCoords = [];
            for (j = 0; j < line.coordinates.length; j++) {
                polyCoords.push(line.coordinates[j]);
            }
            for (j = offsetLine[forCut].geometry.coordinates.length - 1; j >= 0; j--) {
                polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
            }
            polyCoords.push(line.coordinates[0]);

            thickLineString = lineString(polyCoords);
            thickLinePolygon = lineToPolygon(thickLineString);
            polygon = simplify(polygon, { tolerance: 1e-9 }); // simplify due to topological exception
            thickLinePolygon = simplify(thickLinePolygon, { tolerance: 1e-9 }); // simplify due to topological exception
            clipped = difference(polygon, thickLinePolygon);

            cutPolyGeoms = [];
            for (j = 0; j < clipped.geometry.coordinates.length; j++) {
                polyg = turfPolygon(clipped.geometry.coordinates[j]);
                intersect = lineIntersect(polyg, offsetLine[forSelect]);
                if (intersect.features.length > 0) {
                    cutPolyGeoms.push(polyg.geometry.coordinates);
                }
            }

            cutPolyGeoms.forEach(function (geometry, index) {
                id = idPrefix + (i + 1) + '.' + (index + 1);
                cutFeatures.push(turfPolygon(geometry));
            });
        }
        if (cutFeatures.length > 0) {
            retVal = featureCollection(cutFeatures);
        }

        return retVal;
    }
}

export default CutPolygon;
