import React from 'react';
import { EMPTY_GEOJSON, GEOMETRY_TYPES, LAYER, PARCEL } from '../Constants/MapConstant';
import { mapObj, undoRedoPush, selectTool as selectFeatureTool } from '../MainPage/Annotation/OlMap/MapInit';
import { addBufferFeature, getBufferFeaturesOfParent, getLayerType, removeBufferFeature } from '../Utils/MapHelper';
import { generateUniqueId } from '../Utils/HelperFunctions';

const LAWN_EDGE_ATTRIBUTE = 'edge_type';

export const LayersDropdown = () => {
    return <select placeholder='Select Layer' id='layers-dropdown'></select>;
};

export function ContextMenuFn(_this, e) {
    var select;

    const selectTool = selectFeatureTool;
    let features =
        selectTool.select && selectTool.select.getFeatures().getLength()
            ? selectTool.select.getFeatures().getArray()
            : [];
    let layer = selectTool.layer?.get('id');
    if (!features.length) {
        const px = e.pixel;
        const feature = _this.map.forEachFeatureAtPixel(
            px,
            (f, l) => {
                layer = l ? l.get('id') : null;
                return f;
            },
            { layerFilter: l => ![PARCEL, LAYER.PARCEL].includes(l.get('id')), hitTolerance: 5 }
        );
        if (layer && feature) {
            features.push(feature);
        } else {
            hide();
            return;
        }
    }
    let containerId = 'select-attr',
        fn = handleLawnEdgeAttribution;
    if (layer !== LAYER.LAWN_EDGE) {
        containerId = 'layers-dropdown';
        fn = handleLayersDropdown;
    }

    hide(); // hide first if any other dropdown already opened
    select = document.getElementById(containerId);
    select.style.top = e.originalEvent.clientY + 'px';
    select.style.left = e.originalEvent.clientX + 5 + 'px';
    show();
    select.focus();
    fn();

    function handleLawnEdgeAttribution() {
        select.value = features[0].getProperties()[LAWN_EDGE_ATTRIBUTE];
        select.onchange = () => {
            features.forEach(feat => {
                feat.setProperties({ [LAWN_EDGE_ATTRIBUTE]: select.value });
            });
            onTaskComplete();
        };
    }

    function handleLayersDropdown() {
        let layers = [];

        const geometry_type = getLayerType(layer)?.geometry_type;
        if (geometry_type === GEOMETRY_TYPES.POINT) {
            const pointLayers = mapObj.map
                ? mapObj.map
                      .getLayers()
                      .getArray()
                      .filter(l => mapObj.isPointLayer(l))
                      .map(l => l.get('id'))
                : [];
            layers = [...layers, ...pointLayers];
        } else if (geometry_type === GEOMETRY_TYPES.LINESTRING) {
            const lineLayers = mapObj.map
                ? mapObj.map
                      .getLayers()
                      .getArray()
                      .filter(l => mapObj.isLineLayer(l))
                      .map(l => l.get('id'))
                : [];
            layers = [...layers, ...lineLayers];
        } else {
            const polyLayers = mapObj.map
                ? mapObj.map
                      .getLayers()
                      .getArray()
                      .filter(l => !mapObj.isIgnorableLayer(l))
                      .map(l => l.get('id'))
                : [];
            layers = [...layers, ...polyLayers];
        }
        const dropdownContainer = document.getElementById(containerId);
        dropdownContainer.innerHTML = '';
        dropdownContainer.size = layers.length;
        if (layers.length === 1) {
            dropdownContainer.size = 2;
            let div = document.createElement('option');
            div.innerHTML = layers[0];
            dropdownContainer.appendChild(div);
        } else {
            for (let i = 0; i < layers.length; i++) {
                let option = document.createElement('option');
                option.value = layers[i];
                option.text = layers[i];
                option.key = layers[i];
                option.innerHTML = layers[i];
                dropdownContainer.appendChild(option);
            }
        }

        select.value = layer;
        select.onchange = () => {
            const selectedLayer = mapObj.map.getLayerById(select.value);
            const currentLayer = mapObj.map.getLayerById(layer);
            currentLayer &&
                selectedLayer &&
                features.forEach(feat => {
                    const clonedFeature = feat.clone();
                    clonedFeature.setStyle(null);

                    if (clonedFeature?.get('parent_id')) {
                        removeBufferFeature(clonedFeature?.get('parent_id'));
                        clonedFeature?.set('parent_id', null);
                    }

                    if (feat?.get('buffer')) {
                        const { bufferFeatures, bufferLayerSource } = getBufferFeaturesOfParent(
                            currentLayer.get('id'),
                            feat?.getId()
                        );

                        const layer_type = selectedLayer?.get('id');
                        bufferLayerSource.removeFeatures(bufferFeatures);
                        let bufferLayer = mapObj.map.getLayerById('buffer_' + layer_type);
                        if (!bufferLayer) {
                            mapObj.addVectorLayer(EMPTY_GEOJSON, 'buffer_' + layer_type);
                        }
                        bufferLayer = mapObj.map.getLayerById('buffer_' + layer_type);
                        clonedFeature.setId(generateUniqueId());
                        addBufferFeature(clonedFeature, layer_type, bufferLayer);
                    }
                    currentLayer.getSource().removeFeature(feat);

                    selectedLayer.getSource().addFeature(clonedFeature);
                });
            onTaskComplete();
        };
    }

    function onTaskComplete() {
        selectTool.emptyFeatureArray();
        undoRedoPush();
        hide();
    }

    function show() {
        select.style.display = 'block';
    }

    function hide() {
        if (select) select.style.display = 'none';
    }

    document.onclick = e => {
        if (e.target === select || select.contains(e.target)) {
            return;
        }
        hide();
    };
}
