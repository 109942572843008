import { lineSegment, polygonToLine } from '@turf/turf';
import { Translate } from 'ol/interaction';
import { undoRedoPush } from '../MapInit';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import { asString } from 'ol/color';
import { MultiPoint } from 'ol/geom';

import { GEOMETRY_TYPES, GEO_JSON } from '../../../../Constants/MapConstant';
import { avoidOverlap, distanceBwPoints, getTurfFeature } from '../../../../Utils/MapHelper';
import { highlightFeatureIfInvalid } from '../../../../Utils/HelperFunctions';
import { captureException } from '@sentry/react';
import { TOOL_NAMES } from '../../../../Constants/Tool';

const POINT_COLOR = asString([0, 0, 255, 0.5]);
const LINE_COLOR = asString([255, 191, 0, 1]);
const SEGMENT_COLOR = asString([0, 0, 255, 1]);

const lineStyles = [
    new Style({
        stroke: new Stroke({
            color: LINE_COLOR,
            width: 3
        }),
        image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
                color: POINT_COLOR
            }),
            fill: new Fill({
                color: POINT_COLOR
            })
        })
    }),
    new Style({
        image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
                color: POINT_COLOR
            }),
            fill: new Fill({
                color: POINT_COLOR
            })
        }),
        geometry: feature => {
            const geom = feature.getGeometry();
            const coordinates = geom.getCoordinates();
            const type = geom.getType();
            if (typeof coordinates === 'object') {
                if (type === GEOMETRY_TYPES.LINESTRING) {
                    return new MultiPoint(coordinates);
                } else if (type === GEOMETRY_TYPES.POLYGON) {
                    return new MultiPoint(coordinates[0]);
                }
            }
        }
    })
];

const segmentHighlightStyle = new Style({
    stroke: new Stroke({
        color: SEGMENT_COLOR,
        width: 3,
        zIndex: 1000
    })
});

class ModifySegment {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.translate = null;
        this.hitTolerance = 10;
        this.overlayLayerId = 'modify-segment-overlay';
        this.timeTaken = 0;
    }

    init(id) {
        this.off();
        this.layer = this.mapObj.map.getLayerById(id);

        if (!this.layer) {
            return;
        }
        this.overlay = new VectorLayer({
            id: this.overlayLayerId,
            source: new VectorSource(),
            style: lineStyles,
            zIndex: this.layer.getZIndex() + 10
        });

        this.translate = new Translate({
            layers: [this.overlay],
            hitTolerance: this.hitTolerance
        });
        this.mapObj.map.addLayer(this.overlay);
        this.mapObj.map.addInteraction(this.translate);

        this.mapObj.map.on('pointermove', this.pointerMoveHandler);
        this.translate.on('translatestart', this.onTranslateStart);
        this.translate.on('translating', this.onTranslating);
        this.translate.on('translateend', this.onTranslateEnd);
    }

    onTranslateStart = e => {
        this.timeTaken = Date.now();
        this.translateStart = true;
        const features = this.overlay.getSource().getFeatures();
        const translable = e.features.getArray()[0];
        try {
            const polyline = GEO_JSON.readFeature(polygonToLine(getTurfFeature(this.originalFeature)));
            features.forEach((f, i) => {
                if (f !== translable) {
                    this.overlay.getSource().removeFeature(f);
                }
            });
            this.overlay.getSource().addFeature(polyline);
            const coords = polyline.getGeometry().getCoordinates();
            let firstPointIndex, lastPointIndex;

            // finding the closest points index
            translable
                .getGeometry()
                .getCoordinates()
                .forEach((coord, index) => {
                    let min = Infinity;
                    for (let i = 0; i < coords.length; i++) {
                        const dist = distanceBwPoints(coord, coords[i]);
                        if (dist < min) {
                            min = dist;
                            if (index == 0) {
                                firstPointIndex = i;
                            } else {
                                lastPointIndex = i;
                            }
                        }
                    }
                });

            this.firstIndex = firstPointIndex;
            this.lastIndex = lastPointIndex;
            this.modifiable = polyline;
            this.coords = this.modifiable.getGeometry().getCoordinates();
        } catch (e) {
            captureException(e);
        }
    };

    onTranslating = e => {
        this.translating = true;
        const updatedCoords = e.features.getArray()[0].getGeometry().getCoordinates();
        this.coords[this.firstIndex] = updatedCoords[0];
        this.coords[this.lastIndex] = updatedCoords[1];
        if (this.lastIndex == 0 || this.firstIndex == 0) {
            this.coords.splice(-1, 1, this.coords[0]); // make first and last same for valid polygon
        }
        this.modifiable.getGeometry().setCoordinates(this.coords);
    };

    onTranslateEnd = e => {
        // put this.translating check bcz if there is no movement then no changes happen in original feature
        if (this.modifiable && this.translating) {
            const newCoords = this.modifiable.getGeometry().getCoordinates();
            this.originalFeature.getGeometry().setCoordinates([newCoords]);
            highlightFeatureIfInvalid(this.originalFeature);
            if (this.mapObj.AppStore.avoid_overlap) {
                avoidOverlap(this.originalFeature);
            }
            undoRedoPush();
        }
        this.resetAll();
        let currentTime = Date.now();
        this.mapObj.AppStore.setTrackTools({
            toolName: TOOL_NAMES.modify_segment,
            timeTaken: currentTime - this.timeTaken, // in ms
            successfulOperation: true
        });
    };

    resetAll() {
        this.originalFeature = null;
        this.translating = false;
        this.translateStart = false;
        this.overlay && this.overlay.getSource().clear();
    }

    pointerMoveHandler = e => {
        let canvas = document.getElementsByTagName('canvas')[0];
        if (canvas) {
            canvas.style.cursor = '';

            const feature = this.getFeatureAtPixel(e.pixel, this.layer.get('id'));

            if (feature) {
                if (!this.originalFeature) {
                    canvas.style.cursor = 'move';
                    this.originalFeature = feature;
                    const segments = lineSegment(getTurfFeature(this.originalFeature));
                    this.addToOverlay(GEO_JSON.readFeatures(segments));
                } else {
                    const overlayFeature = this.getFeatureAtPixel(e.pixel, this.overlayLayerId);
                    this.overlayFeature && this.overlayFeature.setStyle(null);

                    if (overlayFeature && !overlayFeature.getStyle() && !this.translateStart) {
                        this.overlayFeature = overlayFeature;
                        overlayFeature.setStyle(segmentHighlightStyle);
                    }
                }
            } else if (!this.translateStart) {
                /**
                 * replace this.translating with this.translateStart bcz when user click translate will
                 * start but translating will be true when there is mouse movement so when user click
                 * we found feature but at that time this.translating = false  due to which our var
                 * this.originalFeature becomes null so exception occurs
                 */
                this.resetAll();
            }
        }
    };

    getFeatureAtPixel(px, lId) {
        return this.mapObj.map.forEachFeatureAtPixel(
            px,
            (feature, layer) => {
                return feature;
            },
            {
                layerFilter: layer_candidate => {
                    return lId == layer_candidate.get('id');
                },
                hitTolerance: this.hitTolerance
            }
        );
    }

    addToOverlay(features) {
        this.overlay.getSource().addFeatures(features);
    }

    off() {
        this.resetAll();
        this.mapObj.map.un('pointermove', this.pointerMoveHandler);
        this.translate && this.translate.un('translatestart', this.onTranslateStart);
        this.translate && this.translate.un('translating', this.onTranslating);
        this.translate && this.translate.un('translateend', this.onTranslateEnd);
        this.translate && this.mapObj.map.removeInteraction(this.translate);
        this.mapObj.map.removeLayer(this.overlay);
    }
}

export default ModifySegment;
