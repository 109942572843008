import React from 'react';
import { Alert as UIAlert } from 'antd';

const Alert = props => {
    return <UIAlert {...props} />;
};

export default Object.assign(Alert, {
    ErrorBoundary: UIAlert.ErrorBoundary
});
