import message from '../../../../../UIComponents/Message';
import { selectTool, undoRedoPush } from '../../MapInit';
import { mergeFeatures, moveFeatures } from '../../../../../Utils/MapHelper';
import { highlightFeatures } from '../../../../../Utils/HelperFunctions';

// TODO: Give option to select sourceLayer and targetLayer
class MergeLayers {
    constructor(mapObj) {
        this.mapObj = mapObj;
    }

    execute() {
        this.select = selectTool.select;
        this.setSourceTargetLayers();
        this.mergeFeatures();
    }

    mergeFeatures() {
        const sourceLayer = this.mapObj.map.getLayerById(this.sourceLayerId);
        const targetLayer = this.mapObj.map.getLayerById(this.targetLayerId);
        if (!(sourceLayer && targetLayer)) {
            return message.error('Layers not found');
        }
        let features = this.select ? this.select.getFeatures().getArray() : [];
        if (features.length) {
            const invalidFeatures = features.filter(f => !f.isValid());
            if (invalidFeatures.length) {
                message.error(
                    'Invalid geometries exists in the provided features. Please first fix or delete them to perform operation. '
                );
                highlightFeatures(invalidFeatures);
                return;
            }
            moveFeatures(features, sourceLayer, targetLayer);
            features = targetLayer.getSource().getFeatures();
            mergeFeatures(features, targetLayer);
            undoRedoPush();
        } else {
            message.info('No feature is selected');
        }
    }
}

export default MergeLayers;
