import { Draw } from 'ol/interaction';
import { drawStyle } from '../MapBase';
import { appStore, undoRedoPush } from '../MapInit';
import { avoidOverlap } from '../../../../Utils/MapHelper';
import { TOOL_NAMES } from '../../../../Constants/Tool';
import { showArea } from '../../../../Utils/HelperFunctions';
import { unByKey } from 'ol/Observable';
import { Polygon } from 'ol/geom.js';
import { clearCommentLayer } from '../../components/CommentsList/CommentUtils';

class DrawPolygon {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.draw = null;
        this.snap = null;
        this.timeTaken = 0;
        this.listner = null;
        this.overlay = appStore?.measurementOverlay;
    }

    init(id) {
        this.off();
        const layer = this.mapObj.map.getLayerById(id);
        this.layer = layer;
        if (layer) {
            const source = layer.getSource();
            this.draw = new Draw({
                source: source,
                type: 'Polygon',
                style: drawStyle(id),
                dragVertexDelay: 0,
                snapTolerance: 1,
                condition: e => {
                    const mouseClick = e.originalEvent.which;
                    if (mouseClick == 3 || mouseClick == 2) {
                        return false;
                    }
                    return true;
                }
                /* newCoordinates is added becuase for making a polygon the first and last coordinate must be 
            same in order to form a closed loop so in case of ending a drawing by Space button, we are adding the
            first coordinate onto last,so a new variable is used in order to not modify the coordinates array
            as they are more of a global variable where all coordinates are stored and also used in different 
            functions such as "on pressing backspace" and "on finish a drawing by double click" */
            });
            this.mapObj.map.addInteraction(this.draw);
            this.snap = this.mapObj.getSnap();
            this.snap.forEach(snap => {
                this.mapObj.map.addInteraction(snap);
            });
            this.draw.on('drawend', this.onDrawEnd);
            this.draw.on('drawstart', this.calculateTime);
            window.addEventListener('keydown', this.keyDownHandler);
        }
    }

    calculateTime = evt => {
        this.timeTaken = Date.now();
        let overlay = appStore?.measurementOverlay;

        let sketch = evt.feature;
        this.listner = sketch.getGeometry().on('change', event => {
            const geom = event.target;

            if (geom instanceof Polygon) {
                showArea(geom, overlay);
            }
        });
    };

    keyDownHandler = e => {
        if (e.code == 'Backspace') {
            this.draw.removeLastPoint();
        } else if (e.code == 'Space') {
            this.draw.finishDrawing();
        }
    };

    onDrawEnd = e => {
        if (this.mapObj.AppStore?.snapping_mode) {
            this.mapObj.snapFeature(e.feature);
        }

        setTimeout(() => {
            if (this.mapObj.AppStore.avoid_overlap) {
                avoidOverlap(e.feature);
            }
            undoRedoPush();
        }, 0);

        // get the timeTaken & update it with tracktools data.
        let currentTime = Date.now();
        this.mapObj.AppStore.setTrackTools({
            toolName: TOOL_NAMES.add_polygon,
            timeTaken: currentTime - this.timeTaken, // in ms
            successfulOperation: true
        });

        this.mapObj.AppStore.setUpdateMapLegend();
        unByKey(this.listner);
        let overlay = appStore?.measurementOverlay;
        const coordinate = e.feature.getGeometry().getCoordinates()[0][0];
        if (appStore.comment_action_selected) {
            clearCommentLayer();
            this.popup = appStore.overlay;
            this.popup.setPosition([coordinate[0], coordinate[1]]);
            const view = this.mapObj?.map?.getView();
            view.animate({
                center: coordinate,
                duration: 500
            });
        }
        overlay.setPosition(undefined);
    };

    off() {
        this.mapObj.map.removeInteraction(this.draw);
        this.mapObj.map.removeInteraction(this.snap);
        this.draw && this.draw.un('drawend', this.onDrawEnd);
        window.removeEventListener('keydown', this.keyDownHandler);
    }
}

export default DrawPolygon;
