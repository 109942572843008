import { getGeom, getCoords } from '@turf/invariant';
import { point as turfPoint, polygon as turfPolygon } from '@turf/helpers';
import lineIntersect from '@turf/line-intersect';
import lineSegment from '@turf/line-segment';
import lineSlice from '@turf/line-slice';
import pointToLineDistance from '@turf/point-to-line-distance';
import turfDistance from '@turf/distance';
import kinks from '@turf/kinks';
import area from '@turf/area';
import booleanContains from '@turf/boolean-contains';

import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Draw } from 'ol/interaction';
import { drawStyle } from '../MapBase';
import { GEOMETRY_TYPES, GEO_JSON } from '../../../../Constants/MapConstant';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import { undoRedoPush } from '../MapInit';
import { captureException, setExtra } from '@sentry/react';
import { avoidOverlap } from '../../../../Utils/MapHelper';
import { message } from '../../../../UIComponents';
import { TOOL_NAMES } from '../../../../Constants/Tool';

const DISTANCE_FACTOR = 1e-7;

// Variable to choose to enable debug layers. Make sure it if false before commiting
const _DEBUG = false;
class ReshapePolygon {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.draw = null;
        this.lineLayer = null;
        this.targetPolygon = null;
        this.timeTaken = 0;
    }

    getZoom() {
        return this.mapObj.map.getView().getZoom();
    }

    getDistanceFactor() {
        const zoom = parseInt(this.getZoom());
        let distanceFactor = DISTANCE_FACTOR;
        if (zoom > 16) {
            distanceFactor = 1e-7;
        } else if (zoom < 10) {
            distanceFactor = 1;
        } else {
            distanceFactor = Math.pow(10, -zoom % 10);
        }

        return distanceFactor;
    }

    init(id) {
        this.off();
        this.selected_layer_id = id;

        let sourceDrawnLines = new VectorSource({ wrapX: false });
        this.lineLayer = new VectorLayer({
            source: sourceDrawnLines
        });
        this.mapObj.map.addLayer(this.lineLayer);

        this.draw = new Draw({
            source: sourceDrawnLines,
            type: GEOMETRY_TYPES.LINESTRING,
            style: drawStyle(id),
            dragVertexDelay: 0,
            snapTolerance: 1,
            condition: e => {
                const mouseClick = e.originalEvent.which;
                if (mouseClick == 3 || mouseClick == 2) {
                    return false;
                }
                return true;
            }
        });

        this.mapObj.map.addInteraction(this.draw);
        this.snap = this.mapObj.getSnap();
        this.snap.forEach(snap => {
            this.mapObj.map.addInteraction(snap);
        });
        this.draw.on('drawend', this.drawEnd);
        window.addEventListener('keydown', this.handleKeyDawn);
    }

    handleKeyDawn = e => {
        if (e.code == 'Backspace') {
            this.draw.removeLastPoint();
        } else if (e.code == 'Space') {
            this.draw.finishDrawing();
        }
    };

    off() {
        this.mapObj.map.removeInteraction(this.draw);
        this.lineLayer && this.mapObj.map.removeLayer(this.lineLayer);
        window.removeEventListener('keydown', this.handleKeyDawn);
    }
    /**
     * Check whether the two points are closer by the DISTANCE_FACTOR
     * Use this to determine if the points are the same or not
     * @param {} p1
     * @param {*} p2
     */
    checkClosePoints(p1, p2) {
        const distance = turfDistance(turfPoint(p1), turfPoint(p2));
        const distanceFactor = this.getDistanceFactor();
        return distance < distanceFactor;
    }
    /**
     * Use in debug mode to draw the required feature on the map
     * idx allows you to choose between two layers, which have different style colors
     * @param {GeoJSONObject} p
     * @param {int} idx
     */
    debugAddF = (p, idx = 1) => {
        if (_DEBUG) {
            if (idx == 1) {
                let _p = GEO_JSON.readFeature(p, false);
                _p.getGeometry().transform('EPSG:4326', 'EPSG:3857');
                this.intersection_src1.addFeature(_p);
            } else {
                let _p = GEO_JSON.readFeature(p, false);
                _p.getGeometry().transform('EPSG:4326', 'EPSG:3857');
                this.intersection_src2.addFeature(_p);
            }
        }
    };
    /**
     * Use in debug mode to init debug layers
     */
    debugSetup = () => {
        if (_DEBUG) {
            if (this.intersection_src == undefined) {
                this.intersection_src1 = new VectorSource();
                var intersection_lyr1 = new VectorLayer({
                    id: 'int_lyr1',
                    source: this.intersection_src1,
                    style: new Style({
                        image: new Circle({
                            radius: 5,
                            fill: new Fill({
                                color: 'rgba(255, 255, 0, 0.6)'
                            }),
                            stroke: new Stroke({
                                color: 'rgb(255, 255, 0)',
                                width: 1.5
                            })
                        }),
                        fill: new Fill({
                            color: 'rgba(255, 255, 0, 0.6)'
                        }),
                        stroke: new Stroke({
                            color: 'rgb(255, 255, 0)',
                            width: 1.5
                        })
                    })
                });
                this.mapObj.map.addLayer(intersection_lyr1);
                this.intersection_src2 = new VectorSource();
                var intersection_lyr2 = new VectorLayer({
                    id: 'int_lyr2',
                    source: this.intersection_src2,
                    style: new Style({
                        image: new Circle({
                            radius: 5,
                            fill: new Fill({
                                color: 'rgba(0, 255, 0, 0.6)'
                            }),
                            stroke: new Stroke({
                                color: 'rgb(0, 255, 0)',
                                width: 1.5
                            })
                        }),
                        fill: new Fill({
                            color: 'rgba(0, 255, 0, 0.6)'
                        }),
                        stroke: new Stroke({
                            color: 'rgb(0, 255, 0)',
                            width: 1.5
                        })
                    })
                });
                this.mapObj.map.addLayer(intersection_lyr2);
            }
        }
    };
    drawEnd = e => {
        this.mapObj.AppStore.setLoaderState(true);
        setTimeout(() => {
            this.timeTaken = Date.now();
            this.debugSetup();
            let layerPoly = this.mapObj.map.getLayerById(this.selected_layer_id);
            if (layerPoly) {
                let sourcePoly = layerPoly.getSource();
                let featuresPoly = sourcePoly.getFeatures();

                let drawnGeoJSON = GEO_JSON.writeFeatureObject(e.feature);
                let drawnGeometry = getGeom(drawnGeoJSON);
                let invalidPolys = [];
                if (drawnGeometry.type == GEOMETRY_TYPES.LINESTRING) {
                    featuresPoly.forEach(featurePoly => {
                        let featureGeo = GEO_JSON.writeFeatureObject(featurePoly);
                        const fcopy = Object.assign({}, featureGeo);

                        const props = featurePoly.getProperties();
                        delete props['geometry'];
                        try {
                            let reshapedPoly = this.reshape(featureGeo, drawnGeoJSON);

                            if (reshapedPoly) {
                                let reshapedFeature = GEO_JSON.readFeature(reshapedPoly);
                                reshapedFeature.setProperties({ ...props });
                                sourcePoly.addFeature(reshapedFeature);
                                sourcePoly.removeFeature(featurePoly);
                                if (this.mapObj.AppStore.avoid_overlap) {
                                    avoidOverlap(reshapedFeature);
                                }
                            }
                        } catch (err) {
                            setExtra('feature', JSON.stringify(fcopy));
                            setExtra('drawn', JSON.stringify(drawnGeoJSON));
                            setExtra('Request_ID', localStorage.getItem('job_id'));
                            captureException(err);
                            let currentTime = Date.now();

                            this.mapObj.AppStore.setTrackTools({
                                hasError: true,
                                toolName: TOOL_NAMES.reshape_feature,
                                error: err,
                                timeTaken: currentTime - this.timeTaken
                            });
                        }
                    });
                    let currentTime = Date.now();
                    this.mapObj.AppStore.setTrackTools({
                        toolName: TOOL_NAMES.reshape_feature,
                        successfulOperation: true,
                        timeTaken: currentTime - this.timeTaken
                    });
                    // console.log(e.feature.getGeometry().getCoordinates());
                    // if (this.mapObj.AppStore.avoid_overlap) {
                    //     avoidOverlap(reshapedFeature);
                    // }
                    undoRedoPush();
                }
            }
            this.mapObj.AppStore.setLoaderState(false);
        }, 10);

        this.mapObj.map.removeLayer(this.lineLayer);
    };

    /**
     * Determine whether both intersection points sets are same
     * @param {Array[2]} firstIntersectLinePoints
     * @param {Array[2]} lastIntersectLinePoints
     */
    checkIntersectionPtsMatch(firstIntersectLinePoints, lastIntersectLinePoints) {
        return (
            (this.checkClosePoints(firstIntersectLinePoints[0], lastIntersectLinePoints[0]) ||
                this.checkClosePoints(firstIntersectLinePoints[0], lastIntersectLinePoints[1])) &&
            (this.checkClosePoints(firstIntersectLinePoints[1], lastIntersectLinePoints[0]) ||
                this.checkClosePoints(firstIntersectLinePoints[1], lastIntersectLinePoints[1]))
        );
    }
    /**
     * Generates the new polygon based on a start intersect point and the intersecting line
     * @param {Array[]} polygonPoints
     * @param {Array[2]} firstIntersectLinePoints
     * @param {int} startPtIdx
     * @param {Array[2]} lastIntersectLinePoints
     * @param {LineString} lineWithIntersect
     */
    generatedReshapedPoly(
        polygonPoints,
        firstIntersectLinePoints,
        startPtIdx,
        lastIntersectLinePoints,
        lineWithIntersect
    ) {
        /**
         * We need to iterate in order starting from a point on the firstIntersectLine.
         * Here, we fetch the starting index to iterate
         */
        let ptIndex;
        for (let i = 0; i < polygonPoints[0].length; i++) {
            let polygonPoint = polygonPoints[0][i];
            if (this.checkClosePoints(polygonPoint, firstIntersectLinePoints[startPtIdx])) {
                ptIndex = i;
                break;
            }
        }
        let polyCoords = [];
        let polyPtsLen = polygonPoints[0].length;

        // Adding the points of the line to the final polygon
        polyCoords.push(...lineWithIntersect.geometry.coordinates);
        let polyCoordsNew = [];

        /**
         * Determine the order of iteration.
         * If the next point is on the same edge (firstIntersectLine), use reverse order
         */
        let indexSecond = (ptIndex + 1 + polyPtsLen) % polyPtsLen;
        let secondPt = polygonPoints[0][indexSecond];
        let dir = 1;
        if (this.checkClosePoints(secondPt, firstIntersectLinePoints[1 - startPtIdx])) {
            dir = -1;
        }
        /**
         * Handle special case when both intersectLineSegments are the same
         * In this case:
         * One polygon will contain all points from polygon along with line segment
         * Second polygon will contain only the line segment points
         * This is determined based on when the starting point of the line is closer to which startIntersectingLinePoint
         */
        if (this.checkIntersectionPtsMatch(firstIntersectLinePoints, lastIntersectLinePoints)) {
            let startPt = firstIntersectLinePoints[startPtIdx];
            let otherStartPt = firstIntersectLinePoints[1 - startPtIdx];
            let lineStartPt = polyCoords[0];
            let distStartPt = turfDistance(turfPoint(startPt), turfPoint(lineStartPt));
            let distOtherStartPt = turfDistance(turfPoint(otherStartPt), turfPoint(lineStartPt));
            if (distStartPt < distOtherStartPt) {
                for (let i = 0; i < polyPtsLen; i++) {
                    let index = (ptIndex + i * dir + polyPtsLen) % polyPtsLen;
                    let polygonPoint = polygonPoints[0][index];
                    polyCoordsNew.push(polygonPoint);
                }
            }
        } else {
            /**
             * Iterate over all points to polygon till we reach any of the lastIntersectLinePoints
             */
            for (let i = 0; i < polyPtsLen; i++) {
                let index = (ptIndex + i * dir + polyPtsLen) % polyPtsLen;
                let polygonPoint = polygonPoints[0][index];

                polyCoordsNew.push(polygonPoint);

                if (
                    this.checkClosePoints(polygonPoint, lastIntersectLinePoints[0]) ||
                    this.checkClosePoints(polygonPoint, lastIntersectLinePoints[1])
                ) {
                    break;
                }
            }
        }

        // We need to reverse these to ensure all the points of polyCoords are in correct order
        polyCoordsNew.reverse();
        polyCoords.push(...polyCoordsNew);
        //remove duplicates here
        let uniqueCoordinates = [...new Set(polyCoords.map(JSON.stringify))].map(JSON.parse);
        // add the first coord again at last to make a polygon-feature
        uniqueCoordinates.push(uniqueCoordinates[0]);

        return turfPolygon([uniqueCoordinates]);
    }

    /**
     *
     * @param {Turf Polygon} polygon
     * @param {Turf LineString} line
     */
    reshape(polygon, line) {
        /**
         * reshape with rings
         * 1. Check if line is intersecting only with 1 one of polygon
         * 2. Run reshaped method on the intersected ring
         * 3. Push other rings in array
         * 4. Remove extra rings if they are outside of outer ring
         */

        let polyGeom = getGeom(polygon);
        let polyRings = getCoords(polyGeom);
        let reshapedPolygon = [];
        let finalPoly = [];
        if (this.isIntersectWithSingleRing(polyRings, line)) {
            for (let x = 0; x < polyRings.length; x++) {
                let polyRing = turfPolygon([polyRings[x]]);
                let reshaped = this.reshapeSingle(polyRing, line);
                if (reshaped) {
                    reshapedPolygon.push(reshaped.geometry.coordinates[0]);
                } else {
                    reshapedPolygon.push(polyRings[x]);
                }
            }
            let reshapedPolyWithExtraRings = turfPolygon(reshapedPolygon);
            let reshapedPolyWithExtraRingsCoords = reshapedPolyWithExtraRings.geometry.coordinates;
            let outerPoly = reshapedPolyWithExtraRingsCoords[0];
            finalPoly.push(outerPoly);
            for (let i = 1; i < reshapedPolyWithExtraRingsCoords.length; i++) {
                if (booleanContains(turfPolygon([outerPoly]), turfPolygon([reshapedPolyWithExtraRingsCoords[i]]))) {
                    finalPoly.push(reshapedPolyWithExtraRingsCoords[i]);
                }
            }
            return turfPolygon(finalPoly);
        }
    }

    /**
     *
     * @param {Array} polyRings
     * @param {LineString} line
     */
    isIntersectWithSingleRing(polyRings, line) {
        let num = 0;
        for (let x = 0; x < polyRings.length; x++) {
            let polyRing = turfPolygon([polyRings[x]]);
            let intersect = lineIntersect(polyRing, line);
            if (intersect.features.length) {
                num++;
            }
        }
        return num == 1 ? true : false;
    }

    /**
     *
     * @param {Turf Polygon} polygon
     * @param {Turf LineString} line
     */
    reshapeSingle(polygon, line) {
        /**
         * reshape Tool
         *
         * This tool reshapes a given polygon based on an intersecting line.
         * We take the two points where the line intersects the polygon
         * Then replace all the point of the polygon, which lie between those intersection points,
         * with the points of the line
         *
         * Algorithm steps
         * 1. Get the intersection points. Need these points in order to choose first and last as required intersection points
         * 2. Find the edges of the polygon where these two intersection points lie
         * 3. Take the first edge to start with. Consider the first point of this edge
         * 4. Starting from this point, collect all points of polygon till you reach the second intersecting edge.
         * 5. Combining these points with the points of the line, we can get a polygon (outerPolygon)
         * 6. Similarly, taking the second point in step 3 gives us the second polygon (innerPolygon)
         * 7. Choose whichever polygon is larger in area and a valid polygon and return
         *
         */
        let intersectPoints = lineIntersect(polygon, line);

        // If the line intersects the polygon at less than 2 points, no need to go ahead
        if (intersectPoints.features.length < 2) {
            return;
        }
        /**
         * The intersection points obtained from turf are unordered.
         * The below section orders them in order from the starting point of the line
         */
        let orderedIntersectPoints = [];
        let intersectionLineSegements = lineSegment(line); // Break line into segments
        const distanceFactor = this.getDistanceFactor();

        intersectionLineSegements.features.forEach(lineseg => {
            let lineStartPt = turfPoint(lineseg.geometry.coordinates[0]);
            // For each segment, find the points lying on that segment in order
            let orderedIntersectPointsOnLineSeg = [];
            let orderedIntersectPointsOnLineSegDists = [];
            intersectPoints.features.forEach(point => {
                // Check if point lies on segment using distance function
                let dist = pointToLineDistance(point, lineseg);
                if (dist < distanceFactor) {
                    let currPointIdx = 0;
                    let currPointDist = turfDistance(point, lineStartPt);
                    // Logic to collect points sorted by their distance from lineStartPt
                    let i;
                    for (i = 0; i < orderedIntersectPointsOnLineSegDists.length; i++) {
                        let dist = orderedIntersectPointsOnLineSegDists[i];
                        if (currPointDist < dist) {
                            // Find the index where this point should be inserted
                            currPointIdx = i;
                            break;
                        } else {
                            // If currPointDist is larger than all dist till now, insert it at the end
                            if (i == orderedIntersectPointsOnLineSegDists.length - 1) {
                                currPointIdx = i + 1;
                            }
                        }
                    }
                    // Insert at index sorted by distance from lineStartPt
                    orderedIntersectPointsOnLineSegDists.splice(currPointIdx, 0, currPointDist);
                    orderedIntersectPointsOnLineSeg.splice(currPointIdx, 0, point);
                }
            });
            // Add all points for this segment into the main array
            orderedIntersectPointsOnLineSeg.forEach(_p => {
                orderedIntersectPoints.push(_p);
            });
        });

        /* Issue to be fixed:
            So while snapping points are coincident on the feature edges as well due
            to which the intersection points are duplicated one from the output of lastIntersect function and 
            the point-coordinate itself. so we had to remove the duplicates and just keep the unique coords
        */
        orderedIntersectPoints = [...new Set(orderedIntersectPoints)];
        const firstIntersect = orderedIntersectPoints[0];
        const lastIntersect = orderedIntersectPoints[orderedIntersectPoints.length - 1];

        // Slice line based on the points. We do not need the line part lying outside these points
        let lineWithIntersect = lineSlice(firstIntersect, lastIntersect, line);

        /* 
            Issues to be fixed:
            The above lineWithItersect Takes a line , a start Point (in our case firstIntersect) ,
            and a stop point (in our case lastIntersect) and returns a subsection of the line in-between 
            those points. So while snapping points are coincident on the feature edges as well due
            to which the lastIntersect point was duplicated one from the output of lastIntersect and 
            the point-coordinate itself.

            Solution:
            For such cases remove the duplicate coords from lineWithIntersect
        */

        const uniqueCoordinates = [];

        lineWithIntersect.geometry.coordinates.forEach(coord => {
            const isDuplicate = uniqueCoordinates.some(
                uniqueCoord => uniqueCoord[0] === coord[0] && uniqueCoord[1] === coord[1]
            );
            if (!isDuplicate) {
                uniqueCoordinates.push(coord);
            }
        });

        lineWithIntersect.geometry.coordinates = uniqueCoordinates;

        // Split polygon into line segments
        let polyLineSegments = lineSegment(polygon);

        let polygonPoints = getGeom(polygon).coordinates;

        // Cloning coordinates array before performing the pop operations below so not to modify original polygon
        polygonPoints = JSON.parse(JSON.stringify(polygonPoints));

        // First and last point is same in a polygon, so removing the duplicate point
        polygonPoints[0].pop();

        let firstIntersectLine;
        let lastIntersectLine;

        // Obtain the polygon line segments corresponding to the two intersection points
        for (let i = 0; i < polyLineSegments.features.length; i++) {
            let singlePolygonLine = polyLineSegments.features[i];

            let firstIntersectDistance = pointToLineDistance(firstIntersect, singlePolygonLine);
            let lastIntersectDistance = pointToLineDistance(lastIntersect, singlePolygonLine);

            if (firstIntersectDistance < distanceFactor || lastIntersectDistance < distanceFactor) {
                if (firstIntersectDistance < distanceFactor) {
                    firstIntersectLine = singlePolygonLine;
                }

                if (lastIntersectDistance < distanceFactor) {
                    lastIntersectLine = singlePolygonLine;
                }
            }
        }
        if (!firstIntersectLine || !lastIntersectLine) {
            return null;
        }
        let firstIntersectLinePoints = getGeom(firstIntersectLine).coordinates;
        let lastIntersectLinePoints = getGeom(lastIntersectLine).coordinates;

        let outerPolygon = this.generatedReshapedPoly(
            polygonPoints,
            firstIntersectLinePoints,
            0,
            lastIntersectLinePoints,
            lineWithIntersect
        );
        let innerPolygon = this.generatedReshapedPoly(
            polygonPoints,
            firstIntersectLinePoints,
            1,
            lastIntersectLinePoints,
            lineWithIntersect
        );

        // Check for self intersection in polgons to determine if they are valid
        let innerPolygonKinks = kinks(innerPolygon);
        let outerPolygonKinks = kinks(outerPolygon);

        let innerPolygonValid = innerPolygonKinks.features.length == 0;
        let outerPolygonValid = outerPolygonKinks.features.length == 0;

        if (innerPolygonValid) {
            if (outerPolygonValid) {
                let innerPolygonArea = area(innerPolygon);
                let outerPolygonArea = area(outerPolygon);
                return innerPolygonArea > outerPolygonArea ? innerPolygon : outerPolygon;
            } else {
                return innerPolygon;
            }
        } else if (outerPolygonValid) {
            message.error('Error! Please try again');
            // return outerPolygon;
        }
        return null;
    }
}

export default ReshapePolygon;
