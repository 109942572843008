import React, { lazy, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Skeleton } from './UIComponents';
import LoginPage from './LoginPage/LoginPage.js';
import { QueryClient, QueryClientProvider } from 'react-query';
import useFullScreen from './hooks/useFullScreen';
import * as Sentry from '@sentry/react';
const MainPage = lazy(() => import('./MainPage/MainPage.js'));
const queryClient = new QueryClient();

export const App = inject('AppStore')(
    observer(({ AppStore }) => {
        useFullScreen();

        return (
            <div className='app'>
                <QueryClientProvider client={queryClient}>
                    <Suspense
                        fallback={
                            <div style={{ padding: '20px 40px' }}>
                                <Skeleton active />
                            </div>
                        }>
                        {AppStore.is_logged_in ? <MainPage /> : <LoginPage />}
                    </Suspense>
                </QueryClientProvider>
            </div>
        );
    })
);

export default Sentry.withProfiler(withRouter(App));
