import KeyBinder from '../KeyBinder';
import MapBase from './MapBase';
// import MapTool from './MapTools';
import UndoRedo from './Tools/UndoRedo';
import Select from './Tools/SelectTool';
import TOOL_MAP, { TOOL_TYPE } from '../../../Constants/Tool';

export const mapObj = new MapBase();
// export const toolsObj = new MapTool(mapObj);
export const selectTool = new Select(mapObj);
export const keyBinder = new KeyBinder();
export let undoRedoObj;
export let appStore;

export const initOlMap = function (AppStore) {
    mapObj.init(AppStore);
    appStore = AppStore;
    undoRedoObj = new UndoRedo(mapObj, AppStore);
};

export const initTool = function (type, layer_id, mode) {
    offAllTools();
    if (type && type !== TOOL_TYPE.PAN) {
        const tool = TOOL_MAP[type].getObject(); // this.getTool(type);
        tool && tool.init(layer_id, mode);
    }
};

export function offAllTools() {
    TOOL_TYPE.all().forEach(type => {
        const tool = TOOL_MAP[type].getObject();
        tool && tool.off();
    });
}

export function executeAction(type, e) {
    const action = TOOL_MAP[type].getObject();
    action && action.execute(e);
}

export const undoRedoPush = function () {
    undoRedoObj.push();
};

export const undoRedoReset = function () {
    undoRedoObj.reset();
};
