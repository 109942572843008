import React from 'react';
import { Select as AntdSelect } from 'antd';

const Select = props => {
    return <AntdSelect {...props}>{props.children}</AntdSelect>;
};

export default Object.assign(Select, {
    Option: AntdSelect.Option,
    OptGroup: AntdSelect.OptGroup
});
