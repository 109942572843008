import React from 'react';
import { Card as UICard } from 'antd';

const Card = props => {
    const { children } = props;
    return <UICard {...props}>{children}</UICard>;
};

export default Object.assign(Card, {
    Meta: UICard.Meta,
    Grid: UICard.Grid
});
