import { ACTION } from '../../../../../Constants/Tool';
import { message } from '../../../../../UIComponents';
import { undoRedoObj } from '../../MapInit';

class UndoRedo {
    constructor(mapObj) {
        this.mapObj = mapObj;
    }

    execute(event, type) {
        switch (type) {
            case ACTION.UNDO:
                this.mapObj.AppStore.setLoaderState(true);
                message.destroy('invalid-geometry-message');
                setTimeout(() => {
                    undoRedoObj.undo();
                    this.mapObj.AppStore.setLoaderState(false);
                    this.mapObj.AppStore.setUpdateMapLegend();
                }, 10);
                break;
            case ACTION.REDO:
                this.mapObj.AppStore.setLoaderState(true);
                setTimeout(() => {
                    undoRedoObj.redo();
                    this.mapObj.AppStore.setLoaderState(false);
                    this.mapObj.AppStore.setUpdateMapLegend();
                }, 10);
                break;
        }
    }
}

export default UndoRedo;
