import { Modify } from 'ol/interaction';
import { highlightFeatureIfInvalid } from '../../../../Utils/HelperFunctions';
import { avoidOverlap } from '../../../../Utils/MapHelper';
import { drawStyle } from '../MapBase';
import { appStore, undoRedoPush } from '../MapInit';
import { TOOL_NAMES } from '../../../../Constants/Tool';
import { Collection } from 'ol';
import { LAYER } from '../../../../Constants/MapConstant';


class ModifyEdge {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.modify = null;
        this.timeTaken = 0;
    }

    init(id) {
        this.off();
        this.layer = this.mapObj.map.getLayerById(id);
        if (this.layer) {
            let allFeatures = [];

            if (this.mapObj.AppStore.snapping_mode) {
                if (appStore.comment_action_selected) {
                    allFeatures.push(...this.mapObj.map.getLayerById(LAYER.COMMENT_LAYER).getSource().getFeatures());
                }
                this.mapObj.AppStore.current_layers.forEach(l => {
                    const source = this.mapObj.map.getLayerById(l)?.getSource();
                    allFeatures.push(...source.getFeatures());
                });
            } else {
                const source = this.layer.getSource();
                allFeatures = source.getFeatures();
            }
            // const featureCollection = GEO_JSON.writeFeaturesObject(allFeatures);
            const collection = new Collection(allFeatures);

            this.modify = new Modify({
                features: collection,
                style: drawStyle(id),
                pixelTolerance: this.mapObj.AppStore.tolerance_value
            });
            this.mapObj.map.addInteraction(this.modify);
            this.modify.on('modifystart', this.onModifyStart);
            this.modify.on('modifyend', this.onModifyEnd);
        }
        window.addEventListener('keydown', this.handleKeydown, false);
    }

    off() {
        this.mapObj.map.removeInteraction(this.modify);
        this.modify && this.modify.un('modifyend', this.onModifyEnd);

        window.removeEventListener('keydown', this.handleKeydown);
    }

    handleKeydown = evt => {
        const key = evt.keyCode;
        if (key == 46) {
            this.deleteVertex();
        }
    };

    onModifyStart = e => {
        this.snap = this.mapObj.getSnap();
        this.snap.forEach(snap => {
            this.mapObj.map.addInteraction(snap);
        });
        this.timeTaken = Date.now();
    };

    onModifyEnd = e => {
        const feature = e.features.getArray()[0];
        highlightFeatureIfInvalid(feature);
        if (this.mapObj.AppStore.avoid_overlap) {
            avoidOverlap(feature);
        }
        undoRedoPush();
        let currentTime = Date.now();
        this.mapObj.AppStore.setTrackTools({
            toolName: TOOL_NAMES.modify_vertex,
            timeTaken: currentTime - this.timeTaken, // in ms
            successfulOperation: true
        });
    };

    deleteVertex() {
        this.modify.removePoint();
    }
}

export default ModifyEdge;
