import { useState, useEffect, useCallback } from 'react';
import Mousetrap from 'mousetrap';

const useFullScreen = () => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const enterFullscreen = useCallback(async () => {
        try {
            if (isFullScreen) {
                await document.exitFullscreen();
            } else {
                await document.documentElement.requestFullscreen();
            }
            setIsFullScreen(prev => !prev);
        } catch (err) {
            console.log('error in fullscreen', err);
        }
    }, [isFullScreen]);

    useEffect(() => {
        Mousetrap.bind('shift+f', enterFullscreen);
        return () => {
            Mousetrap.unbind('shift+f', enterFullscreen);
        };
    }, [enterFullscreen]);
};

export default useFullScreen;
