import React from 'react';
import { Typography as AntdTypography } from 'antd';

const Typography = props => {
    const { children } = props;
    return <AntdTypography {...props}>{children}</AntdTypography>;
};

export default Object.assign(Typography, {
    Text: AntdTypography.Text,
    Link: AntdTypography.Link,
    Paragraph: AntdTypography.Paragraph,
    Title: AntdTypography.Title
});
