import 'ol/ol.css';
import Map from 'ol/Map';
import { asArray } from 'ol/color/';
import Tile from 'ol/layer/Tile';
import TileImage from 'ol/source/TileImage';
import Static from 'ol/source/ImageStatic';
import ImageLayer from 'ol/layer/Image';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import OSM from 'ol/source/OSM';
import GeoJSON from 'ol/format/GeoJSON';
import { Stroke, Style, Fill, Circle } from 'ol/style';
import { fromLonLat, toLonLat, transform, transformExtent } from 'ol/proj';
import { defaults as defaultControls, Zoom } from 'ol/control';
import { defaults as defaultInteractions, Snap } from 'ol/interaction';
import bbox from '@turf/bbox';
import bboxClip from '@turf/bbox-clip';
import {
    LAYER_COLOR,
    LAYER_NAME,
    LAWN_ATTRIBUTE,
    EMPTY_GEOJSON,
    StyleGen,
    GEO_JSON,
    PARCEL,
    LAYER_TYPE_GEOMETRY_MAP,
    GEOMETRY_TYPES,
    SOURCE_TYPE,
    LAYER_ZINDEX,
    ModifiedStyleGenFn,
    LAYER,
    DEFAULT_ZOOM_VALUE,
    MIN_ZOOM_VALUE
} from '../../../Constants/MapConstant';
import { TOOL_TYPE } from '../../../Constants/Tool';
import { mapObj, undoRedoObj, undoRedoPush } from './MapInit';
import { buffer } from 'ol/extent';
import Collection from 'ol/Collection';
// import { turfMerge } from './Tools/SelectTool';
import DragPan from 'ol/interaction/DragPan';
import KeyboardPan from 'ol/interaction/KeyboardPan';
import KeyboardZoom from 'ol/interaction/KeyboardZoom';
import MouseWheelZoom from 'ol/interaction/MouseWheelZoom';
import GeoImageSource from 'ol-ext/source/GeoImage';
import bboxPolygon from '@turf/bbox-polygon';
import transformRotate from '@turf/transform-rotate';
import turfDifference from '@turf/difference';
import intersect from '@turf/intersect';
import { polygon as turfPolygon, multiPolygon as turfMultiPolygon } from '@turf/helpers';
import { getCoords } from '@turf/invariant';
import { captureException, setExtra } from '@sentry/react';
import { debounce, getParameterFromUrl, highlightFeatureIfInvalid, turfMerge } from '../../../Utils/HelperFunctions';
import { Feature } from 'ol';
import { addBufferFeature, getLayerType, getTurfFeature, makeOlFeature } from '../../../Utils/MapHelper';
import { INVALID_PARCEL_FEATURE } from '../../../Constants/Messages';
import VectorImageLayer from 'ol/layer/VectorImage';
import CircleStyle from 'ol/style/Circle';
import { asString } from 'ol/color';
import { MultiPoint } from 'ol/geom';
import { SAVE_JOB } from '../../../Constants/Urls';
import { interpolate, postAPI } from '../../../Utils/ApiCalls';
import { message } from 'antd';
import buffered from '@turf/buffer';
import { IMAGE_TILE_LAYER_TYPE } from '../../../Constants/Annotations';

const MULTI_GEOMETRIES = [GEOMETRY_TYPES.MULTI_POLYGON, GEOMETRY_TYPES.MULTI_LINESTRING, GEOMETRY_TYPES.MULTI_POINT];

const GOOGLE_IMAGERY_SATELLITE = 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}';
const DRAW_WIDTH = 3;
const ZOOM = 5;

class MapBase {
    constructor() {
        this.map = null;
        this.base_layer = null;
        this.google_layer = null;
        this.draw = null;
        this.snap = null;
        this.modify = null;
        this.previousLayersState = [];
    }

    getInteractions() {
        return [
            new DragPan({
                condition: e => {
                    if (e.originalEvent.which === 3 || this.AppStore?.current_tool == TOOL_TYPE.PAN) {
                        return true;
                    }
                    return false;
                }
            }),
            new KeyboardPan({ duration: 10 }),
            new KeyboardZoom({ duration: 10 }),
            new MouseWheelZoom({ duration: 10, maxDelta: 32, timeout: 10 })
        ];
    }

    init(AppStore) {
        this.AppStore = AppStore;
        const interactions = new Collection(this.getInteractions());
        this.map = new Map({
            controls: defaultControls(),
            interactions: interactions,
            target: 'map',
            layers: [],
            view: new View({
                center: fromLonLat([-104, 39]),
                zoom: ZOOM,
                minZoom: MIN_ZOOM_VALUE,
                maxZoom: DEFAULT_ZOOM_VALUE,
                extent: transformExtent([-180, -90, 180, 90], 'EPSG:4326', 'EPSG:3857')
            }),
            keyboardEventTarget: document
        });
        this.oblique_images = {};
        this.ortho_image = {};
        Map.prototype.getLayerById = function (id) {
            return this.getLayers()
                .getArray()
                .find(lyr => id == lyr.get('id'));
        };

        this.map.on('contextmenu', e => {
            e.preventDefault();
            if (AppStore.current_tool === TOOL_TYPE.PAN || AppStore.current_tool === TOOL_TYPE.SELECT)
                this.AppStore.ContextMenuFn(this, e);
        });

        this.addBaseLayer();
        this.addGoogleBaseLayer();
        this.addOverlaysLayer();

        return this.map;
    }

    addBaseLayer() {
        let source = new OSM();
        this.base_layer = new Tile({
            source,
            id: 'base_layer',
            zIndex: 0
        });
        this.map.addLayer(this.base_layer);
    }

    //add overlays layer specifically for showing vertices as an overlay on hovering over any feature
    addOverlaysLayer() {
        const _source = new VectorSource();

        const POINT_COLOR = asString([0, 0, 255, 0.5]);
        const LINE_COLOR = asString([255, 191, 0, 1]);
        const lineStyles = [
            new Style({
                stroke: new Stroke({
                    color: LINE_COLOR,
                    width: 3
                }),
                image: new CircleStyle({
                    radius: 5,
                    stroke: new Stroke({
                        color: POINT_COLOR
                    }),
                    fill: new Fill({
                        color: POINT_COLOR
                    })
                })
            }),
            new Style({
                image: new CircleStyle({
                    radius: 5,
                    stroke: new Stroke({
                        color: POINT_COLOR
                    }),
                    fill: new Fill({
                        color: POINT_COLOR
                    })
                }),
                geometry: feature => {
                    const geom = feature.getGeometry();
                    const coordinates = geom.getCoordinates();
                    const type = geom.getType();
                    if (typeof coordinates === 'object') {
                        if (type === GEOMETRY_TYPES.LINESTRING) {
                            return new MultiPoint(coordinates);
                        } else if (type === GEOMETRY_TYPES.POLYGON) {
                            const rings = geom.getLinearRings();
                            const points = [];
                            rings.forEach(ring => {
                                points.push(...ring.getCoordinates());
                            });
                            return new MultiPoint(points);
                        }
                    }
                }
            })
        ];

        let _layer = new VectorImageLayer({
            id: 'overlays_layer',
            source: _source,
            style: lineStyles,
            zIndex: 300
        });

        this.map.addLayer(_layer);
    }

    addGoogleBaseLayer() {
        let source = new TileImage({
            url: GOOGLE_IMAGERY_SATELLITE
        });
        this.google_layer = new Tile({
            source,
            id: 'google_layer',
            zIndex: 0
        });
        this.google_layer.setVisible(false);
        this.map.addLayer(this.google_layer);
    }

    loadImageLayer(zoom = false, tileserver_as_baselayer = false) {
        if (!this.ortho_image) {
            console.log('Attempt to load non existing ortho layer');
            return;
        }
        const url = this.ortho_image?.url ?? '';
        const tileserver_url = this.ortho_image.tileserver_url;
        const _bounds = this.ortho_image?.bounds;
        let _bx;
        if (tileserver_as_baselayer) {
            _bx = transformExtent([_bounds[0], _bounds[1], _bounds[2], _bounds[3]], 'EPSG:4326', 'EPSG:3857');
        } else {
            _bx = transformExtent(
                [_bounds?.left, _bounds?.bottom, _bounds?.right, _bounds?.top],
                'EPSG:4326',
                'EPSG:3857'
            );
        }

        if (tileserver_url) {
            const source = new TileImage({
                url: tileserver_url
            });
            const img_layer = new Tile({
                id: 'image_static_ORTHO',
                source: source,
                zIndex: 1
            });

            this.map.addLayer(img_layer);
            img_layer.setExtent(_bx);
        } else {
            const img_src = new Static({
                url: url,
                imageExtent: _bx
            });
            const img_layer = new ImageLayer({
                id: 'image_static_ORTHO',
                source: img_src,
                zIndex: 1
            });

            this.map.addLayer(img_layer);
        }
        if (zoom) {
            this.zoomToExtent(_bx);
        }
        this.AppStore.setOrthoImageVisible(true);
    }
    addImageLayer(url, _bounds = {}, tileserver_url, tileserver_as_baselayer = false) {
        this.ortho_image.url = url;
        this.ortho_image.bounds = _bounds;
        this.ortho_image.tileserver_url = tileserver_url;
        this.loadImageLayer(true, tileserver_as_baselayer);
    }
    addObliqueImageLayer(url, _orientation, _bounds = {}) {
        if (_orientation == 'WEST' || _orientation == 'EAST') {
            const boundary_layer_bbox = [_bounds.left, _bounds.bottom, _bounds.right, _bounds.top];
            const boundary_layer_bbox_polygon = bboxPolygon(boundary_layer_bbox);
            const rotatedPoly = transformRotate(boundary_layer_bbox_polygon, 270);
            const new_bounds = bbox(rotatedPoly);
            _bounds.left = new_bounds[0];
            _bounds.bottom = new_bounds[1];
            _bounds.right = new_bounds[2];
            _bounds.top = new_bounds[3];
        }
        this.oblique_images[_orientation] = {
            url: url,
            bounds: { ..._bounds }
        };
    }
    loadObliqueImageryLayer(orientation) {
        if (!this.oblique_images[orientation]) {
            console.log(`Attempt to load non existing orientation - ${orientation}`);
            return;
        }
        const url = this.oblique_images[orientation].url;
        const _bounds = this.oblique_images[orientation].bounds;
        const imageLoaded = () => {
            let _bx = transformExtent(
                [_bounds.left, _bounds.bottom, _bounds.right, _bounds.top],
                'EPSG:4326',
                'EPSG:3857'
            );
            const imageCenter = [(_bx[0] + _bx[2]) / 2, (_bx[1] + _bx[3]) / 2];
            const scale_x = (_bx[2] - _bx[0]) / img.width;
            const scale_y = (_bx[3] - _bx[1]) / img.height;
            // Using GeoImageSource instead of ImageStatic as we need rotation support
            const img_src = new GeoImageSource({
                url: url,
                imageCenter: imageCenter,
                imageScale: [scale_x, scale_y],
                imageExtent: _bx,
                // imageCrop: _bx,
                projection: 'EPSG:3857',
                imageRotate: rot
            });
            const img_layer = new ImageLayer({
                id: `image_static_${orientation}`,
                source: img_src,
                zIndex: 0
            });

            this.map.addLayer(img_layer);
        };

        var img = document.createElement('img');
        // Create image layer after loading image in an HTML element
        // This is done to obtain height, width of image before adding to map
        img.onload = imageLoaded;
        img.src = url;
        // Calculate rotation based on image orientation
        if (orientation == 'NORTH') {
            var rot_deg = 0;
        } else if (orientation == 'SOUTH') {
            var rot_deg = 180;
        } else if (orientation == 'EAST') {
            var rot_deg = 90;
        } else if (orientation == 'WEST') {
            var rot_deg = 270;
        }
        var rot = rot_deg * (Math.PI / 180);
    }
    moveImageryLayerToTop(orientation) {
        const layers = this.map.getLayers();
        layers.forEach(layer => {
            if (layer) {
                const layer_id = layer.get('id');
                if (layer_id.includes('image_static')) {
                    this.map.removeLayer(layer);
                }
            }
        });
        if (orientation == 'ORTHO') {
            this.loadImageLayer();
        } else {
            this.loadObliqueImageryLayer(orientation);
        }
    }

    loadTileLayer = parcelGeoJson => {
        if (!parcelGeoJson) {
            parcelGeoJson = this.AppStore?.current_job_data?.boundary_layer_json;
        }
        const bufferedBbox = buffered(parcelGeoJson, 0.1);
        const bBox = bbox(bufferedBbox);

        this.addImageLayer('', bBox, this.AppStore?.current_job_data?.base_layer, true);
    };

    getLayersFromJob = () => {
        let layers = [];
        this.AppStore.current_layers &&
            this.AppStore.current_layers
                .filter(layer_type => {
                    const isBufferLayer = layer_type?.split('_')[0] == 'buffer';
                    return !isBufferLayer;
                })
                .forEach(layer => {
                    const layer_type = layer;
                    const geojson = this.getLayerGeoJson(layer_type);
                    layers.push({ layer_name: layer_type, geojson });
                });
        return layers;
    };
    debounnceSave = debounce(() => {
        const layers = this.getLayersFromJob();
        const layersChanged = [];

        layers.map(layer => {
            const prevLayer = this.previousLayersState.find(item => item.layer_name === layer.layer_name);
            if (!prevLayer) layersChanged.push(layer);
            else if (JSON.stringify(prevLayer.geojson) !== JSON.stringify(layer.geojson)) layersChanged.push(layer);
        });

        if (layersChanged?.length === 0) return;

        const url = interpolate(SAVE_JOB, [this.AppStore.current_job_data?.id]);
        const data = {
            layers: layersChanged,
            tools_usage: this.AppStore.track_tools
        };

        return postAPI(url, data)
            .then(() => {
                this.previousLayersState = [...layers];
                this.AppStore.resetTrackTools();

                if (this.AppStore?.current_job_data?.base_layer_type === IMAGE_TILE_LAYER_TYPE)
                    this.loadTileLayer(layers[0]?.geojson);
            })
            .catch(error => {
                //ask what to do here and why reload in original method
                message.error('Save api failed please try again');
                // if (error.response.status === 404) {
                //     setTimeout(() => window.location.reload(), 2000);
                // }
            });
    }, 500);

    saveData = e => {
        this.debounnceSave();
    };
    addVectorLayer(json, id = LAYER_NAME, style = null) {
        const features = GEO_JSON.readFeatures(json);
        const isBufferLayer = id?.split('_')[0] == 'buffer';
        if (isBufferLayer) {
            this.AppStore.setCurrentLayers([...this.AppStore.current_layers, id]);
            this.AppStore.updateAllLayerTypes({
                layer_type: id,
                color: '#' + Math.floor(Math.random() * 16777215).toString(16),
                geometry_type: GEOMETRY_TYPES.POLYGON
            });
        }
        features?.forEach(feature => {
            if (feature?.get('buffer')) {
                setTimeout(() => {
                    let bufferLayer = mapObj.map.getLayerById('buffer_' + id);
                    if (!bufferLayer) mapObj.addVectorLayer(EMPTY_GEOJSON, 'buffer_' + id);
                    bufferLayer = mapObj.map.getLayerById('buffer_' + id);
                    addBufferFeature(feature, id, bufferLayer);
                }, 0); //adding all buffer features and layer in the end
            }
        });

        const _source = new VectorSource({
            features: features
        });
        const isDemoPage = getParameterFromUrl('demo');
        _source.convertMultiGeometryFeatures();
        _source.on('addfeature', this.onAddFeature);
        if (!isDemoPage) _source.on(['changefeature', 'addfeature', 'removefeature'], this.saveData);

        this.previousLayersState = this.getLayersFromJob();

        if (!style) {
            style = ModifiedStyleGenFn(id); //StyleGen(LAYER_COLOR[id]);
        }
        let _layer = new VectorImageLayer({
            id: id,
            source: _source,
            style: style,
            zIndex: this.AppStore.all_layer_types.get(id)?.z_index ?? 100
        });

        this.map.addLayer(_layer);

        // undoRedoObj.init(id);
        return _source;
    }

    onAddFeature = e => {
        const feature = e.feature;

        const geomType = feature.getGeometry().getType();
        if (MULTI_GEOMETRIES.includes(geomType)) {
            e.target.convertFeatureGeometry(feature);
        }
        if (geomType === GEOMETRY_TYPES.POLYGON) {
            highlightFeatureIfInvalid(feature);
        }
    };

    isIgnorableLayer = l => {
        const id = l.get('id');
        return (
            !(l instanceof VectorImageLayer) ||
            getLayerType(id)?.geometry_type != GEOMETRY_TYPES.POLYGON ||
            id === PARCEL
        );
    };

    isLineLayer = layer => {
        const id = layer.get('id');
        return getLayerType(id)?.geometry_type === GEOMETRY_TYPES.LINESTRING;
    };

    isPointLayer = l => {
        const id = l.get('id');
        return getLayerType(id)?.geometry_type === GEOMETRY_TYPES.POINT;
    };

    setLayersOpacity(opacity) {
        const layers = this.AppStore.layer_opacity_checkbox
            ? this.map
                  .getLayers()
                  .getArray()
                  .filter(l => l.get('id') === this.AppStore.selected_layer_id)
            : this.map.getLayers().getArray();
        for (const l of layers) {
            if (this.isIgnorableLayer(l)) {
                continue;
            }
            this.setOpacity(l.get('id'), opacity);
        }
    }

    setOpacity(id, value) {
        const layer = this.map.getLayerById(id);
        if (layer) {
            const style = layer.getStyle();
            const hexColor = style.getStroke().getColor();
            let color = asArray(hexColor);
            color = color.slice();
            color[3] = value;
            const fill = style.getFill();
            fill.setColor(color);
            layer.setStyle(style);
        }
    }

    addParcelLayer(url) {
        let source = this.addVectorLayer(url, PARCEL);
        const extent = source.getExtent();
        if (extent[0] !== Infinity) {
            const buffered = buffer(extent, 10);
            this.zoomToExtent(buffered);
        }
    }

    removeOutputLayers() {
        // TODO - Handle multiple layers in map
        const parcel_layer = this.map.getLayerById(PARCEL);
        this.map.removeLayer(parcel_layer);
        this.AppStore.current_layers.forEach(id => {
            const output_layer = this.map.getLayerById(id);
            this.map.removeLayer(output_layer);
        });
        // TODO: refactor to obtain orientations from system level constant
        const orientations = ['ORTHO', 'NORTH', 'SOUTH', 'EAST', 'WEST'];
        orientations.forEach(orientation => {
            const image_layer = this.map.getLayerById(`image_static_${orientation}`);
            if (image_layer) {
                this.map.removeLayer(image_layer);
            }
        });
        this.oblique_images = {};
        this.ortho_image = {};
    }

    getLayerGeoJson(id) {
        let parcel_layer = this.map.getLayerById(id);
        if (parcel_layer) {
            let feature = parcel_layer.getSource().getFeatures();
            if (feature.length) {
                let geojson = new GeoJSON();
                let parcel_geojson = geojson.writeFeatures(feature, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                });
                return parcel_geojson && JSON.parse(parcel_geojson);
            }
        }
        return EMPTY_GEOJSON;
    }

    zoomToLayer(id) {
        const layer = this.map.getLayerById(id);
        if (layer) {
            const source = layer.getSource();
            const extent = source.getExtent();
            if (extent[0] !== Infinity) {
                const buffered = buffer(extent, 10);
                this.zoomToExtent(buffered);
            }
        }
    }

    zoomToExtent(extent) {
        this.map.getView().fit(extent, { duration: 300, maxZoom: 21, size: this.map.getSize() });
    }

    restrictZoom() {
        this.map.getInteractions().forEach(interaction => {
            if (interaction instanceof MouseWheelZoom) {
                interaction.setActive(false);
            } else if (interaction instanceof KeyboardZoom) {
                interaction.setActive(false);
            }
        });

        this.map.getControls().forEach(control => {
            if (control instanceof Zoom) {
                this.map.removeControl(control);
            }
        });
    }

    restrictPan() {
        this.map.getInteractions().forEach(interaction => {
            if (interaction instanceof KeyboardPan) {
                interaction.setActive(false);
            } else if (interaction instanceof DragPan) {
                interaction.setActive(false);
            }
        });
    }

    resetMap() {
        this.map.addControl(new Zoom());
        this.map.getView().animate({ zoom: ZOOM, duration: 300 });
        this.getInteractions().forEach(interaction => {
            this.map.addInteraction(interaction);
        });
    }

    snapFeature(drawnFeature) {
        if (!drawnFeature) {
            return;
        }
        const layers = this.map.getLayers().getArray();
        for (const layer of layers) {
            const layerId = layer.get('id');
            if (layerId == PARCEL || getLayerType(layerId)?.geometry_type != GEOMETRY_TYPES.POLYGON) {
                continue;
            }
            const sourcePoly = layer.getSource();
            const features = sourcePoly.getFeatures();

            for (let i = 0; i < features.length; ++i) {
                const existFeature = features[i];
                const existFeatureGeo = GEO_JSON.writeFeatureObject(existFeature);
                const existFeatureCoords = getCoords(existFeatureGeo);
                const drawnFeatureGeo = GEO_JSON.writeFeatureObject(drawnFeature); // Put inside so that every existFeature get updated drawn feature
                const drawnFeatureCoords = getCoords(drawnFeatureGeo);

                try {
                    const _existFeature = turfPolygon(existFeatureCoords);
                    const _drawnFeature = turfPolygon(drawnFeatureCoords);
                    const _intersect = intersect(_existFeature, _drawnFeature);
                    if (_intersect) {
                        const difference = turfDifference(_drawnFeature, _existFeature);
                        const newFeature = GEO_JSON.readFeature(rings);
                        drawnFeature.setGeometry(newFeature.getGeometry());
                    }
                } catch (err) {
                    const efcopy = Object.assign({}, existFeatureGeo);
                    const dfcopy = Object.assign({}, drawnFeatureGeo);
                    setExtra('existFeature', JSON.stringify(efcopy));
                    setExtra('drawnFeature', JSON.stringify(dfcopy));
                    setExtra('Request ID', localStorage.getItem('job_id'));
                    captureException(err);
                }
            }

            undoRedoPush(layer.get('id'));
        }
    }

    mergeAll() {
        let layer = this.map.getLayerById(this.AppStore.selected_layer_id);
        if (layer) {
            const src = layer.getSource();
            if (src) {
                const features = src.getFeatures();
                const geojson = new GeoJSON().writeFeaturesObject(features, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                });

                const merged = turfMerge(geojson);
                let mergedGeojson = new GeoJSON().readFeatures(merged, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                });

                mergedGeojson.forEach(f => {
                    f.setProperties({ [LAWN_ATTRIBUTE]: 'F' });
                });

                const new_source = new VectorSource({
                    features: mergedGeojson
                });
                layer.setSource(new_source);
                undoRedoPush();
            }
        }
    }

    deleteAll() {
        let layer = this.map.getLayerById(this.AppStore.selected_layer_id);
        if (layer) {
            const src = layer.getSource();
            const features = src.getFeatures();
            for (let i = 0; i < features.length; i++) {
                let feature = features[i];
                src.removeFeature(feature);
            }
        }
    }

    getSnap(tolerance = this.AppStore?.tolerance_value) {
        const layers = this.map
            .getLayers()
            .getArray()
            .filter(
                layer =>
                    layer.get('id') !== LAYER.PARCEL &&
                    (getLayerType(layer.get('id'))?.geometry_type === GEOMETRY_TYPES.POLYGON ||
                        getLayerType(layer.get('id'))?.geometry_type === GEOMETRY_TYPES.LINESTRING)
            );
        const snaps = [];
        layers.forEach(layer => {
            const snap = new Snap({
                source: layer.getSource(),
                pixelTolerance: tolerance
            });
            snaps.push(snap);
        });
        return snaps;
    }

    setFeatureAttribute(type) {
        let layer = this.map.getLayerById(this.AppStore.selected_layer_id);
        if (layer) {
            const src = layer.getSource();
            const features = src.getFeatures();
            for (let i = 0; i < features.length; i++) {
                let feature = features[i];
                feature.setProperties({ [LAWN_ATTRIBUTE]: type });
            }
        }
    }

    /**
     * @name clipFeature
     * @description
     * we have two approaches to clip a feature wrt other
     * 1. by finding bbox and then use bboxClip but here we do not get 100% optimize result clipped feature may has buffer
     * 2. by finding intersect of both and consider intersected part as clipped
     * @param {Feature} feature toBeClipped
     * @param {Feature} boundFeature clipFeature with respect to feature
     * @return {Feature null}
     */

    // TODO: able to clip all geomteries at present only polygon geometry can be clipped
    clipFeature(feature, boundFeature) {
        try {
            if (!(feature instanceof Feature && feature.isValid())) {
                return feature;
            } else if (!(boundFeature instanceof Feature && feature.isValid())) {
                throw new Error(INVALID_PARCEL_FEATURE);
            } else if (
                ![GEOMETRY_TYPES.POLYGON, GEOMETRY_TYPES.MULTI_POLYGON].includes(feature.getGeometry().getType())
            ) {
                return feature;
            }

            const _feature = getTurfFeature(feature);
            const _boundFeature = getTurfFeature(boundFeature);
            const clippedFeature = intersect(_boundFeature, _feature); //bboxClip(_feature, boundbox);
            return clippedFeature ? GEO_JSON.readFeature(clippedFeature) : null;
        } catch (e) {
            setExtra('parcel', JSON.stringify(GEO_JSON.writeFeatureObject(boundFeature)));
            setExtra('feature', JSON.stringify(GEO_JSON.writeFeatureObject(feature)));
            setExtra('requestid', localStorage.getItem('job_id'));
            captureException(e);
            return feature; // return so that user can delete and redraw feature
        }
    }
}

MapBase.prototype.getEditableLayers = function () {
    return this.map
        .getLayers()
        .getArray()
        .filter(l => !this.isIgnorableLayer(l));
};

MapBase.prototype.getInvalidPolys = function () {
    const layers = this.getEditableLayers();
    const invalidPolys = [];
    for (const l of layers) {
        const features = l
            .getSource()
            .getFeatures()
            .filter(f => !f.isValid() && f.getGeometry().getType() === GEOMETRY_TYPES.POLYGON);
        invalidPolys.push(...features);
    }
    return invalidPolys;
};

VectorSource.prototype.convertMultiGeometryFeatures = function (features = []) {
    const _features = features.length ? features : this.getFeatures();
    const multiGeomteryFeatures = _features.filter(f => MULTI_GEOMETRIES.includes(f.getGeometry().getType()));
    multiGeomteryFeatures.forEach(mgf => {
        this.convertFeatureGeometry(mgf);
    });
};

VectorSource.prototype.convertFeatureGeometry = function (feature) {
    let geoms = [],
        _features = [];
    const geometry = feature.getGeometry().getType();
    const props = feature.getProperties();
    delete props['geometry'];
    if (geometry === GEOMETRY_TYPES.MULTI_POINT) {
        geoms = feature.getGeometry().getPoints();
    } else if (geometry === GEOMETRY_TYPES.MULTI_LINESTRING) {
        geoms = feature.getGeometry().getLineStrings();
    } else if (geometry === GEOMETRY_TYPES.MULTI_POLYGON) {
        geoms = feature.getGeometry().getPolygons();
    }

    geoms.forEach(geom => {
        const _feature = makeOlFeature(geom, props);
        _features.push(_feature);
    });

    if (_features.length) {
        this.addFeatures(_features);
        this.removeFeature(feature);
    }
};

VectorSource.prototype.removeFeatures = function (features = []) {
    const _features = features.length ? features : this.getFeatures();
    for (let i = 0; i < _features.length; i++) {
        const feature = _features[i];
        this.removeFeature(feature);
    }
};

export default MapBase;

export const drawStyle = id => {
    return new Style({
        stroke: new Stroke({
            color: getLayerType(id)?.color,
            width: DRAW_WIDTH
        }),
        image: new Circle({
            radius: 7,
            stroke: new Stroke({
                color: 'skyblue'
            })
        })
    });
};

export const selectStyle = () => {
    return new Style({
        stroke: new Stroke({
            color: 'orange',
            width: DRAW_WIDTH
        }),
        image: new Circle({
            radius: 7,
            stroke: new Stroke({
                color: 'skyblue'
            })
        }),
        fill: new Fill({
            color: 'rgba(255,255,255,0.2)'
        })
    });
};
