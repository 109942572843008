import React from 'react';
import { DatePicker as AntDatePicker } from 'antd';

const DatePicker = props => {
    return <AntDatePicker {...props} />;
};

export default Object.assign(DatePicker, {
    RangePicker: AntDatePicker.RangePicker
});
