import { LAYER } from '../../../../../Constants/MapConstant';
import MergeLayers from './MergeLayers';

// TODO: Give option to select featureLayer and targetLayer
// It can be common if we pass layers as options from where the action is executed
class LawnBedMerge extends MergeLayers {
    constructor(mapObj) {
        super(mapObj);
    }

    setSourceTargetLayers() {
        this.sourceLayerId = LAYER.BEDS;
        this.targetLayerId = LAYER.LAWN;
    }
}

export default LawnBedMerge;
