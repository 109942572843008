import axios, { isCancel } from 'axios';
import { message } from '../UIComponents';
import { BASE_URL } from '../Constants/Urls';
import { captureException, captureMessage } from '@sentry/react';

const CancelToken = axios.CancelToken;
let cancel;

const checkInternetConnection = () => {
    if (!navigator.onLine) {
        message.warn('You lost your internet connectivity!!');
    }
};
/**
 * @name makeURL
 * @function
 * @description Used to create URL with Base Url
 * @param {String} URL API end point URL
 */
export const makeURL = function (URL) {
    return BASE_URL + URL;
};

/**
 * @name multipartAPI
 * @function
 * @description Used to call API with multipart data
 * @param {String} URL API URL
 * @param {Object} data Source data
 */
export const multipartAPI = function (URL, data) {
    return new Promise((resolve, reject) => {
        axios
            .post(makeURL(URL), data, {
                headers: {
                    Authorization: 'Token ' + getAuthToken(),
                    'content-type': 'multipart/form-data'
                }
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err);
                handleErrorCode(err);
            });
    });
};

/**
 * @name postAPI
 * @function
 * @description Used to call API with post method
 * @param {String} URL API URL
 * @param {Object} data Source data
 */
export const postWithoutCancel = function (URL, data = {}, method = 'post') {
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: makeURL(URL),
            data: data,

            headers: {
                Authorization: 'Token ' + getAuthToken()
            }
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                checkInternetConnection();
                if (!isCancel(err)) {
                    captureException(err);
                    handleErrorCode(err);
                    reject(err);
                }
            });
    });
};

export var postAPI = function (URL, data = {}, method = 'post') {
    if (cancel !== undefined) {
        cancel();
    }
    return new Promise((resolve, reject) => {
        axios({
            method: method,
            url: makeURL(URL),
            data: data,
            cancelToken: new CancelToken(function executor(c) {
                cancel = c;
            }),
            headers: {
                Authorization: 'Token ' + getAuthToken()
            }
        })
            .then(res => {
                resolve(res.data);
                const message = `
      API Call:
      URL: ${makeURL(URL)}
      Method: ${method}
      Request Body: ${JSON.stringify(data)}
    
    `;

                captureMessage(message, 'debug');
            })
            .catch(err => {
                checkInternetConnection();
                if (!isCancel(err)) {
                    captureException(err);
                    handleErrorCode(err);
                    reject(err);
                }
            });
    });
};
/**
 * @name getAPI
 * @function
 * @description Used to call API with post method
 * @param {String} URL API URL
 * @param {Object} data Source data
 */
export var getAPI = function (URL, data = {}) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: makeURL(URL),
            params: data,
            headers: {
                Authorization: 'Token ' + getAuthToken()
            }
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                checkInternetConnection();
                if (!isCancel(err)) {
                    captureException(err);
                    handleErrorCode(err);
                    reject(err);
                }
            });
    });
};

/**
 * @name loginAPI
 * @function
 * @description Used to login (Calls API without token)
 * @param {String} URL API URL
 * @param {Object} data Source data
 */
export const loginAPI = function (URL, data, set_cookie = true) {
    return new Promise((resolve, reject) => {
        axios({
            method: 'post',
            url: makeURL(URL),
            data: data
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err);
                handleErrorCode(err);
            });
    });

    // return response.data;
};

/**
 * @name thirdPartyAPI
 * @function
 * @description Used to call any third party API
 * @param {String} URL API URL
 */
export const thirdPartyAPI = async function (URL) {
    const response = await axios({
        method: 'get',
        url: URL
    });
    return response.data;
};

/**
 * @name interpolate
 * @function
 * @description Replace %s from a string to given number
 * @param {String} theString String with %s
 * @param {Array} argumentArray Array of numbers
 */
export const interpolate = function (theString, argumentArray) {
    var regex = /%s/;
    var _r = function (p, c) {
        return p.replace(regex, c);
    };
    return argumentArray.reduce(_r, theString);
};

/**
 * @name getAuthToken
 * @function
 * @description Returns token from localstorage
 */
export const getAuthToken = function () {
    return localStorage.getItem('token');
};

export const handleErrorCode = function (err = {}) {
    if (err.response && err.response.status == 401) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location.href = '/';
    }
    if (err.response && err.response.data) {
        captureException(err);
        if (err.response.data.errors && err.response.data.errors[0].message) {
            message.error(err.response.data.errors && err.response.data.errors[0].message);
        }
    }
};
