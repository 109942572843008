import { message } from 'antd';
import { selectTool, undoRedoPush } from '../../MapInit';
import { TOOL_NAMES } from '../../../../../Constants/Tool';
import { getBufferFeaturesOfParent, removeBufferFeature } from '../../../../../Utils/MapHelper';

class Delete {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.timeTaken = 0;
    }

    execute() {
        this.select = selectTool.select;
        this.layer = selectTool.layer;
        const count = this.select ? this.select.getFeatures()?.getArray()?.length : 0;
        const layerId = this.layer ? this.layer.get('id') : '';
        // this.mapObj.AppStore.setDeleteModal({ value: true, count, layerId }, this.deleteFeatures);
        this.deleteFeatures();
    }

    deleteFeatures = () => {
        this.timeTaken = Date.now();
        const features = this.select ? this.select.getFeatures().getArray() : [];
        if (!features.length) {
            message.error('No feature is selected.');
        } else {
            let source = this.layer.getSource();
            for (let i = 0; i < features.length; i++) {
                let feature = features[i];
                if (feature?.get('parent_id')) {
                    removeBufferFeature(feature?.get('parent_id'));
                    feature?.set('parent_id', null);
                }
                if (feature?.get('buffer')) {
                    const { bufferFeatures, bufferLayerSource } = getBufferFeaturesOfParent(
                        this.layer.get('id'),
                        feature?.getId()
                    );

                    bufferLayerSource.removeFeatures(bufferFeatures);
                }
                source.removeFeature(feature);
            }
            selectTool.emptyFeatureArray();
            undoRedoPush();
            message.destroy('selection-tool');
        }
        let currentTime = Date.now();
        this.mapObj.AppStore.setTrackTools({
            toolName: TOOL_NAMES.delete_selected,
            timeTaken: currentTime - this.timeTaken, // in ms
            successfulOperation: true
        });

        this.mapObj.AppStore.setUpdateMapLegend();
    };
}

export default Delete;
