import React from 'react';
import { Menu as UIMenu } from 'antd';

const Menu = props => {
    return <UIMenu {...props}>{props.children}</UIMenu>;
};

export default Object.assign(Menu, {
    Item: UIMenu.Item,
    ItemGroup: UIMenu.ItemGroup,
    SubMenu: UIMenu.SubMenu,
    Divider: UIMenu.Divider
});
