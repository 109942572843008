import { DragBox, Select, Translate } from 'ol/interaction';
import { undoRedoPush } from '../MapInit';
import { EVENT_TYPE } from '../../../../Constants/MapConstant';
import { TOOL_NAMES } from '../../../../Constants/Tool';
import { selectStyle } from '../MapBase';
import { message } from 'antd';

class MoveTool {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.translate = null;
        this.hitTolerance = 10;
        this.isTranslating = false;
        this.outsideMapevent = null;
        this.timeTaken = 0;
        this.select = null;
        this.drag = null;
    }

    emptyFeatureArray() {
        if (this.select) {
            this.select.getFeatures().clear();
        }
    }

    init(id) {
        this.off();
        this.layer = this.mapObj.map.getLayerById(id);
        this.drag = new DragBox();
        this.select = new Select({
            layers: [this.layer],
            multi: true,
            // condition: all(shiftKeyOnly, click),
            style: selectStyle(id)
        });

        this.translate = new Translate({
            layers: [this.layer],
            hitTolerance: this.hitTolerance,
            features: this.select.getFeatures()
        });

        this.drag.on('boxdrag', () => {
            this.emptyFeatureArray();
        });

        this.drag.on('boxend', () => {
            let extent = this.drag.getGeometry().getExtent();
            this.layer.getSource().forEachFeatureIntersectingExtent(extent, feature => {
                this.select.getFeatures().push(feature);
            });
            message.info({
                content: `${this.select.getFeatures().getLength()} features selected.`,
                style: {
                    marginTop: '4vh'
                },
                duration: 0,
                key: 'selection-tool'
            });
            if (!this.select.getFeatures().getLength()) {
                message.destroy('selection-tool');
            }
        });

        this.translate.handleEvent = e => {
            this.outsideMapevent = e;
            if (e.type === EVENT_TYPE.CLICK && !this.isTranslating) {
                this.translate.handleDownEvent(e);
            } else if (e.type === EVENT_TYPE.CLICK && this.isTranslating) {
                this.translate.handleUpEvent(e);
                this.isTranslating = false;
            } else if (e.type === EVENT_TYPE.POINTER_MOVE) {
                this.translate.handleMoveEvent(e);
                this.translate.handleDragEvent(e);
            }
            return true;
        };
        this.mapObj.map.addInteraction(this.select);
        this.mapObj.map.addInteraction(this.translate);
        this.mapObj.map.addInteraction(this.drag);
        this.mapObj.map.on('pointermove', this.pointerMoveHandler);
        this.translate.on('translatestart', () => {
            this.mapObj.map.removeInteraction(this.select);
            this.mapObj.map.removeInteraction(this.drag);
            this.isTranslating = true;
            this.timeTaken = Date.now();
        });
        this.translate.on('translateend', () => {
            let currentTime = Date.now();
            this.mapObj.AppStore.setTrackTools({
                toolName: TOOL_NAMES.move_feature,
                timeTaken: currentTime - this.timeTaken, // in ms
                successfulOperation: true
            });
            this.mapObj.map.addInteraction(this.select);
            this.mapObj.map.addInteraction(this.drag);
            undoRedoPush();
        });
    }
    pointerMoveHandler = e => {
        let canvas = document.getElementsByTagName('canvas')[0];
        if (canvas) {
            canvas.style.cursor = '';

            this.mapObj.map.forEachFeatureAtPixel(
                e.pixel,
                (feature, layer) => {
                    if (feature) {
                        canvas.style.cursor = 'move';
                    }
                },
                {
                    layerFilter: layer_candidate => {
                        return this.layer == layer_candidate;
                    },
                    hitTolerance: this.hitTolerance
                }
            );
        }
    };
    off() {
        this.translate?.handleUpEvent(this.outsideMapevent);
        this.isTranslating = false;
        this.select && this.mapObj.map.removeInteraction(this.select);
        this.drag && this.mapObj.map.removeInteraction(this.drag);
        this.mapObj.map.un('pointermove', this.pointerMoveHandler);
        this.translate && this.mapObj.map.removeInteraction(this.translate);
        this.translate && this.translate.un('translateend', undoRedoPush);
    }
}

export default MoveTool;
