import { appStore, mapObj } from '../../OlMap/MapInit';
import { DELETE_COMMENT, RESOLVE_COMMENT } from '../../../../Constants/Urls';
import { postAPI, getAPI, interpolate } from '../../../../Utils/ApiCalls';
import { message } from '../../../../UIComponents';
import { LAYER } from '../../../../Constants/MapConstant';
import { formatDateTime } from '../../../../Utils/HelperFunctions';
export const handleDeleteComment = async () => {
    const url = interpolate(DELETE_COMMENT, [appStore.selectedComment.id]);
    const payload = {
        active_work_id: appStore.current_job?.id
    };

    try {
        await postAPI(url, payload, 'DELETE');
        message.success('Comment Deleted Successfully');
        const comments = appStore.comments.filter(comment => comment.id !== appStore.selectedComment.id);
        appStore.setComments(comments);
        appStore.overlay.setPosition(undefined);
        appStore.setSelectedComment({});
        appStore.setCurrentTool('pan');
        clearCommentLayer();
    } catch (err) {}
};

export const handleResolveComment = async Comment => {
    const url = interpolate(RESOLVE_COMMENT, [Comment.id]);

    try {
        const res = await getAPI(url);
        const comments = appStore.comments.map(comment => {
            if (comment.id === appStore.selectedComment.id) {
                comment.isResolved = !comment.isResolved;
                comment.date = formatDateTime(res.modified_at);
            }
            return comment;
        });
        appStore.setComments(comments);
        if (Comment.isResolved) message.success('Comment Resolved Successfully');
        else message.success('Comment Unresolved Successfully');
    } catch (err) {}
};

export const clearCommentLayer = () => {
    const commentSource = mapObj.map.getLayerById(LAYER.COMMENT_LAYER).getSource();
    commentSource.clear();
    appStore.setSelectedComment({});
};

