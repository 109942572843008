import DrawHole from 'ol-ext/interaction/DrawHole';
import { selectStyle } from '../MapBase';
import { appStore, undoRedoPush } from '../MapInit';
import { TOOL_NAMES } from '../../../../Constants/Tool';
import { Polygon } from 'ol/geom';
import { showArea } from '../../../../Utils/HelperFunctions';
import { unByKey } from 'ol/Observable';

class FillRing {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.hole = null;
        this.timeTaken = 0;
        this.listner = null;
    }

    init(id) {
        this.off();
        this.layer = this.mapObj.map.getLayerById(id);
        this.hole = new DrawHole({
            layers: [this.layer],
            style: selectStyle(),
            dragVertexDelay: 0,
            snapTolerance: 1,
            condition: e => {
                const mouseClick = e.originalEvent.which;
                if (mouseClick == 3 || mouseClick == 2) {
                    return false;
                }
                return true;
            }
        });
        this.mapObj.map.addInteraction(this.hole);
        this.hole.on('drawend', this.drawEndHandler);
        this.hole.on('drawstart', evt => {
            this.timeTaken = Date.now();
            let overlay = appStore?.measurementOverlay;

            let sketch = evt.feature;
            this.listner = sketch.getGeometry().on('change', event => {
                const geom = event.target;

                if (geom instanceof Polygon) {
                    showArea(geom, overlay);
                }
            });
        });
        window.addEventListener('keydown', this.keyDownHandler);
    }

    keyDownHandler = e => {
        if (e.code == 'Backspace') {
            this.hole.removeLastPoint();
        } else if (e.code == 'Space') {
            this.hole.finishDrawing();
        }
    };

    drawEndHandler = e => {
        let holeFeature = e.hole;
        this.layer.getSource().addFeature(holeFeature);
        undoRedoPush();
        let currentTime = Date.now();
        this.mapObj.AppStore.setTrackTools({
            toolName: TOOL_NAMES.fill_ring,
            timeTaken: currentTime - this.timeTaken, // in ms
            successfulOperation: true
        });
        this.mapObj.AppStore.setUpdateMapLegend();
        unByKey(this.listner);
        let overlay = appStore?.measurementOverlay;
        overlay.setPosition(undefined);
    };
    off() {
        this.hole && this.mapObj.map.removeInteraction(this.hole);
        this.hole && this.hole.un('drawend', this.drawEndHandler);
        window.removeEventListener('keydown', this.keyDownHandler);
    }
}

export default FillRing;
