import { captureException } from '@sentry/minimal';
import { SOURCE_TYPE_LAYERS_MAP } from '../../../../../Constants/MapConstant';
import { undoRedoPush } from '../../MapInit';

class RightClick {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.hitTolerance = 10;
    }

    getAvailableLayers = () => {
        return this.mapObj.map
            .getLayers()
            .getArray()
            .map(l => l.get('id'));
    };

    getTogglableLayers = () => {
        return SOURCE_TYPE_LAYERS_MAP[this.mapObj.AppStore.current_source_type] || [];
    };

    getLayer = id => {
        return this.mapObj.map.getLayerById(id);
    };

    getOnPixelData = px => {
        return (
            !this.isOverlappedFeatures(px) &&
            this.mapObj.map.forEachFeatureAtPixel(
                px,
                (feature, layer) => {
                    return { feature, layer };
                },
                { layerFilter: this.layerFilter, hitTolerance: this.hitTolerance }
            )
        );
    };

    layerFilter = layer => {
        const id = layer.get('id');
        return !this.isIgnorable(id);
    };

    isOverlappedFeatures = px => {
        return this.mapObj.map.getFeaturesAtPixel(px, { layerFilter: this.layerFilter }).length > 1;
    };

    getLayersOrder() {
        const availableLayers = this.getAvailableLayers();
        const togglableLayers = this.getTogglableLayers();
        return togglableLayers.filter(layer => availableLayers.includes(layer));
    }

    isIgnorable(id) {
        const availableLayers = this.getAvailableLayers();
        const layers = this.getLayersOrder();
        const ignoreLayers = availableLayers.filter(al => !layers.includes(al));
        return ignoreLayers.includes(id);
    }

    getNextLayer = currentLayer => {
        const layers = this.getLayersOrder();
        let currentLayerPos = layers.indexOf(currentLayer);
        if (currentLayerPos !== -1) {
            currentLayerPos = (currentLayerPos + 1) % layers.length;
            return this.getLayer(layers[currentLayerPos]);
        }
    };

    execute = e => {
        const pixel = e.pixel;
        const { feature, layer } = this.getOnPixelData(pixel) || {};
        if (layer && feature) {
            const toLayer = this.getNextLayer(layer.get('id'));
            if (toLayer) {
                try {
                    layer.getSource().removeFeature(feature);
                    toLayer.getSource().addFeature(feature);
                    undoRedoPush();
                } catch (e) {
                    captureException(e);
                }
            }
        }
    };
}

export default RightClick;
