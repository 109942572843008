import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import VectorSource from 'ol/source/Vector';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import { getLength } from 'ol/sphere';
import LineString from 'ol/geom/LineString';

import { GEOMETRY_TYPES, LAYER_ZINDEX } from '../../../../Constants/MapConstant';
import { asString } from 'ol/color';
import { MultiPoint } from 'ol/geom';
import { TOOL_NAMES } from '../../../../Constants/Tool';

const POINT_COLOR = asString([0, 0, 255, 0.5]);
const LINE_COLOR = asString([255, 191, 0, 1]);

const lineStyles = [
    new Style({
        fill: new Fill({
            color: LINE_COLOR
        }),
        stroke: new Stroke({
            color: LINE_COLOR,
            width: 2
        }),
        image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
                color: POINT_COLOR
            }),
            fill: new Fill({
                color: POINT_COLOR
            })
        })
    }),
    new Style({
        image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
                color: POINT_COLOR
            }),
            fill: new Fill({
                color: POINT_COLOR
            })
        }),
        geometry: feature => {
            const geom = feature.getGeometry();
            const coordinates = geom.getCoordinates();
            const type = geom.getType();
            if (typeof coordinates === 'object') {
                if (type === GEOMETRY_TYPES.LINESTRING) {
                    return new MultiPoint(coordinates);
                } else if (type === GEOMETRY_TYPES.POLYGON) {
                    return new MultiPoint(coordinates[0]);
                }
            }
        }
    })
];

const labelStyle = new Style({
    text: new Text({
        font: '14px Calibri,sans-serif',
        placement: 'line',
        offsetY: 20,
        overflow: true,
        fill: new Fill({
            color: 'rgba(0, 0, 0, 1)'
        })
    })
});

const formatLength = line => {
    const length = getLength(line);
    return (length * 3.28083).toFixed(2) + ' ' + 'ft';
};

const MEASUREMENT_MODE = {
    LENGTH: 1
};

class MeasureTool {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.select = null;
        this.layer = null;
        this.timeTaken = 0;
    }

    init(id, measureMode) {
        this.off();
        this.measureMode = measureMode || MEASUREMENT_MODE.LENGTH;

        const src = new VectorSource();
        this.layer = new VectorLayer({
            id: 'measure-tool-layer',
            source: src,
            style: feature => {
                return this.styleFunction(feature);
            },
            zIndex: Math.max(Object.values(LAYER_ZINDEX)) + 1 // give highest value so that the line to measure width will be visibile on top most
        });
        this.mapObj.map.addLayer(this.layer);

        this.draw = new Draw({
            type: GEOMETRY_TYPES.LINESTRING,
            source: src,
            snapTolerance: 5,
            style: feature => {
                return this.styleFunction(feature, this.measureMode);
            }
        });
        this.mapObj.map.addInteraction(this.draw);
        this.snap = this.mapObj.getSnap();
        this.snap.forEach(snap => {
            this.mapObj.map.addInteraction(snap);
        });
        this.draw.on('drawstart', this.clearDrawing);
        this.draw.on('drawend', () => {
            let currentTime = Date.now();
            this.mapObj.AppStore.setTrackTools({
                toolName: TOOL_NAMES.measure_tool,
                timeTaken: currentTime - this.timeTaken, // in ms
                successfulOperation: true
            });
        });
        document.addEventListener('keydown', this.handleKeyDown);
    }

    off() {
        this.measureMode = null;
        this.mapObj.map.removeLayer(this.layer);
        this.mapObj.map.removeInteraction(this.draw);
        this.mapObj.map.removeInteraction(this.snap);
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    styleFunction = (feature, measureMode) => {
        let styles = [...lineStyles];
        let drawType = measureMode === MEASUREMENT_MODE.LENGTH ? GEOMETRY_TYPES.LINESTRING : null;
        const geometry = feature.getGeometry();
        const type = geometry.getType();
        if (!measureMode || drawType === type) {
            geometry.forEachSegment((a, b) => {
                const segment = new LineString([a, b]);
                const label = formatLength(segment);
                const _labelStyle = labelStyle.clone();
                _labelStyle.setGeometry(segment);
                _labelStyle.getText().setText(label);
                styles.push(_labelStyle);
            });
        }
        return styles;
    };

    handleKeyDown = e => {
        const keyCode = e.keyCode;
        if (keyCode === 8) {
            this.draw.removeLastPoint();
        } else if (keyCode === 27) {
            this.draw.abortDrawing();
        }
    };

    clearDrawing = () => {
        this.timeTaken = Date.now();
        this.layer?.getSource()?.clear();
    };
}
export default MeasureTool;
