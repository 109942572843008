import React from 'react';
import { Button as AntdButton } from 'antd';

const Button = props => {
    const { children } = props;

    return <AntdButton {...props}>{children}</AntdButton>;
};

export default Object.assign(Button, {
    Group: AntdButton.Group
});
