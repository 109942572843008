import { ACTION } from '../../../Constants/Tool';
import { mapObj } from './MapInit';
import Tool from './Tool';

function Action(title, type, geom, sk, icon, actionClass, ordering) {
    // Inherit Tool
    Tool.call(this, title, type, geom, sk, icon, null, ordering);

    this.action = e => {
        // e.preventDefault();
        this.getObject().execute(e, type);
    };

    this.getObject = () => {
        let obj;

        if ([ACTION.COPY, ACTION.CUT, ACTION.PASTE].includes(type)) {
            if (!Action.cutCopyPaste) {
                Action.cutCopyPaste = new actionClass(mapObj);
            }
            obj = Action.cutCopyPaste;
        } else {
            obj = new actionClass(mapObj);
        }
        return obj;
    };
}
export default Action;
