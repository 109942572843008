import {
    combine,
    difference,
    distance,
    cleanCoords,
    kinks,
    union,
    unkinkPolygon,
    simplify,
    feature,
    buffer,
    along
} from '@turf/turf';

import { polygon as turfPolygon, Geometries, GeometryCollection } from '@turf/helpers';
import { captureException, setExtra } from '@sentry/react';
import lineIntersect from '@turf/line-intersect';
import lineOffset from '@turf/line-offset';
import lineToPolygon from '@turf/line-to-polygon';
import {
    GEOMETRY_TYPES,
    GEO_JSON,
    LAYER_TYPE_GEOMETRY_MAP,
    LAYER,
    GEOM_SIMPLIFICATION_TOLERANCE,
    SLIVER_POLYGON_AREA_LIMIT
} from '../Constants/MapConstant';
import {
    polygon,
    multiPolygon,
    lineString,
    multiLineString,
    point,
    multiPoint,
    featureCollection
} from '@turf/helpers';
import { getType, getCoords } from '@turf/invariant';
import pointsWithinPolygon from '@turf/points-within-polygon';
import { Feature } from 'ol';
import { toLonLat, transformExtent, transform } from 'ol/proj';

import { appStore, mapObj } from '../MainPage/Annotation/OlMap/MapInit';
import { highlightFeature, turfMerge } from './HelperFunctions';
import LinearRing from 'ol/geom/LinearRing';
import Polygon from 'ol/geom/Polygon';
import intersect from '@turf/intersect';
import booleanIntersects from '@turf/boolean-intersects';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { message } from '../UIComponents';
import { LineString } from 'ol/geom';

const { MULTI_POLYGON, POLYGON, MULTI_LINESTRING, LINESTRING, MULTI_POINT, POINT } = GEOMETRY_TYPES;

const CONVERT = {
    [MULTI_POLYGON]: function (coords) {
        return multiPolygon(coords);
    },
    [POLYGON]: function (coords) {
        return polygon(coords);
    },
    [LINESTRING]: function (coords) {
        return lineString(coords);
    },
    [MULTI_LINESTRING]: function (coords) {
        return multiLineString(coords);
    },
    [POINT]: function (coords) {
        return point(coords);
    },
    [MULTI_POINT]: function (coords) {
        return multiPoint(coords);
    }
};

export const getTurfFeature = feature => {
    const featureGeoJson = GEO_JSON.writeFeatureObject(feature);
    const geomType = getType(featureGeoJson);
    const coords = getCoords(featureGeoJson);
    return CONVERT[geomType](coords);
};

export const isPointWithinPolygon = (coord, poly) => {
    const fc = pointsWithinPolygon(point(coord), poly);
    return fc.features.length;
};

export const makeOlFeature = (geom, props) => {
    return new Feature({
        geometry: geom,
        ...props
    });
};

export const distanceBwPoints = (point1, point2) => {
    return distance(toLonLat(point1), toLonLat(point2));
};

export const mergeFeatures = (features, featureLayer, toLayer) => {
    const props = features[0].getProperties();
    delete props['geometry'];
    const geojson = GEO_JSON.writeFeaturesObject(features);
    let source = featureLayer.getSource();
    const merged = turfMerge(geojson);
    source.removeFeatures(features);
    const mergedGeojson = GEO_JSON.readFeatures(merged);

    mergedGeojson.forEach(f => {
        f.setProperties({ ...props });
    });

    if (toLayer) {
        source = toLayer.getSource();
    }
    source.addFeatures(mergedGeojson);
};

export const moveFeatures = (features, fromLayer, toLayer) => {
    fromLayer.getSource().removeFeatures(features);
    toLayer.getSource().addFeatures(features);
};

const polygonCut = (polygon, line) => {
    const THICK_LINE_UNITS = 'inches';
    const THICK_LINE_WIDTH = 20;
    let i, j, id, intersectPoints, lineCoords, forCut, forSelect;
    let thickLineString, thickLinePolygon, clipped, polyg, intersect;
    let polyCoords = [];
    let cutPolyGeoms = [];
    let cutFeatures = [];
    let offsetLine = [];
    let retVal = null;
    let idPrefix = 'cut_';

    if ((polygon.type !== POLYGON && polygon.type !== MULTI_POLYGON) || line.type !== LINESTRING) {
        return retVal;
    }

    if (typeof idPrefix === 'undefined') {
        idPrefix = '';
    }

    intersectPoints = lineIntersect(polygon, line);
    if (intersectPoints.features.length === 0) {
        return retVal;
    }

    lineCoords = getCoords(line);
    if (isPointWithinPolygon(lineCoords[0], polygon) || isPointWithinPolygon(lineCoords.at(-1), polygon)) {
        return retVal;
    }

    offsetLine[0] = lineOffset(line, THICK_LINE_WIDTH, {
        units: THICK_LINE_UNITS
    });
    offsetLine[1] = lineOffset(line, -THICK_LINE_WIDTH, {
        units: THICK_LINE_UNITS
    });

    for (i = 0; i <= 1; i++) {
        forCut = i;
        forSelect = (i + 1) % 2;
        polyCoords = [];
        for (j = 0; j < line.coordinates.length; j++) {
            polyCoords.push(line.coordinates[j]);
        }
        for (j = offsetLine[forCut].geometry.coordinates.length - 1; j >= 0; j--) {
            polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
        }
        polyCoords.push(line.coordinates[0]);

        thickLineString = lineString(polyCoords);
        thickLinePolygon = lineToPolygon(thickLineString);
        polygon = simplify(polygon, { tolerance: 1e-9 }); // simplify due to topological exception
        thickLinePolygon = simplify(thickLinePolygon, { tolerance: 1e-9 }); // simplify due to topological exception
        clipped = difference(polygon, thickLinePolygon);

        cutPolyGeoms = [];
        for (j = 0; j < clipped?.geometry.coordinates.length; j++) {
            polyg = turfPolygon(clipped.geometry.coordinates[j]);
            intersect = lineIntersect(polyg, offsetLine[forSelect]);
            if (intersect.features.length > 0) {
                cutPolyGeoms.push(polyg.geometry.coordinates);
            }
        }

        cutPolyGeoms.forEach((geometry, index) => {
            id = idPrefix + (i + 1) + '.' + (index + 1);
            cutFeatures.push(turfPolygon(geometry));
        });
    }
    if (cutFeatures.length > 0) {
        retVal = featureCollection(cutFeatures);
    }

    return retVal;
};

function extendLineString(lineStringFeature, distanceInFeet) {
    const distanceInMeters = distanceInFeet;

    const lineString = lineStringFeature.getGeometry();
    const lineStringClone = lineStringFeature.clone();
    const coordinates = lineString.getCoordinates();

    // Calculate the angle between the first two coordinates
    const angleStart = Math.atan2(coordinates[1][1] - coordinates[0][1], coordinates[1][0] - coordinates[0][0]);

    // Calculate the angle between the last two coordinates
    const angleEnd = Math.atan2(
        coordinates[coordinates.length - 1][1] - coordinates[coordinates.length - 2][1],
        coordinates[coordinates.length - 1][0] - coordinates[coordinates.length - 2][0]
    );

    // Calculate the new coordinates based on the distance and angle for both ends
    const newCoordinateStart = [
        coordinates[0][0] - distanceInMeters * Math.cos(angleStart),
        coordinates[0][1] - distanceInMeters * Math.sin(angleStart)
    ];

    const newCoordinateEnd = [
        coordinates[coordinates.length - 1][0] + distanceInMeters * Math.cos(angleEnd),
        coordinates[coordinates.length - 1][1] + distanceInMeters * Math.sin(angleEnd)
    ];

    // Update the LineString with the new coordinates

    lineStringClone.getGeometry().setCoordinates([newCoordinateStart, ...coordinates, newCoordinateEnd]);

    return lineStringClone;
}

export const addBufferFeature = (featureParent, layer_type, buffer_layer) => {
    let modifiedFeatures = [];
    const parent_id = featureParent?.getId();
    let { value: bufferValue } = featureParent?.get('buffer');

    // Assuming 'polygonFeature' is your OpenLayers feature with a Polygon geometry

    // Get the Polygon geometry from the feature
    let polygonGeometry = featureParent.getGeometry();

    if (polygonGeometry.getType() !== GEOMETRY_TYPES.POINT) bufferValue = bufferValue / 2;

    if (polygonGeometry?.getType() !== GEOMETRY_TYPES.POLYGON) {
        let newFeature = new Feature(polygonGeometry);
        modifiedFeatures.push(newFeature);
    } else {
        // Get the coordinates of the exterior ring (outer boundary)
        let exteriorRingCoordinates = polygonGeometry.getLinearRing(0).getCoordinates();

        // Create a LineString geometry using the exterior ring coordinates
        let lineStringGeometry = new LineString(exteriorRingCoordinates);

        // Create a new feature with the LineString geometry
        let lineStringFeature = new Feature(lineStringGeometry);
        modifiedFeatures.push(lineStringFeature);
    }

    const geojson = GEO_JSON.writeFeaturesObject(modifiedFeatures);
    geojson.features?.forEach(feature => {
        try {
            const turfbuffer = buffer(feature, bufferValue, { units: 'feet' });
            const finalFeature = GEO_JSON.readFeature(turfbuffer);
            finalFeature?.set('parent_id', layer_type + '-' + parent_id + '-' + '0');

            if (polygonGeometry.getType() === LINESTRING) {
                const lineClone = extendLineString(featureParent, bufferValue);

                const cutPolygon = polygonCut(
                    GEO_JSON.writeFeatureObject(finalFeature).geometry,
                    GEO_JSON.writeFeatureObject(lineClone).geometry
                );

                if (cutPolygon != null) {
                    let features = GEO_JSON.readFeatures(cutPolygon);
                    const bufferIndexArr = featureParent?.get('buffer').bufferIndex;
                    features.forEach((feature, index) => {
                        feature.set('parent_id', layer_type + '-' + parent_id + '-' + index);
                    });
                    features = features.filter((f, index) => bufferIndexArr[index]);
                    features.forEach((feature, index) => {
                        overWriteFromBuffer(feature, featureParent);
                    });

                    buffer_layer.getSource().addFeatures(features);
                }
            } else {
                overWriteFromBuffer(finalFeature, featureParent);
                buffer_layer.getSource().addFeature(finalFeature);
            }

            appStore.setUpdateMapLegend();
        } catch (e) {
            message.error(`Error adding buffer for feature in ${layer_type}`);
            highlightFeature(featureParent);
        }
    });
};

export const getBufferFeaturesOfParent = (layer_type, featureId) => {
    let bufferFeatures = [];
    const bufferLayerSource = mapObj.map?.getLayerById('buffer_' + layer_type)?.getSource();

    bufferLayerSource?.getFeatures()?.forEach(f => {
        const id = f.get('parent_id');

        if (id) {
            const [layer_type, parentFeatureId, bufferIndex] = id?.split('-');

            if (parentFeatureId === featureId) bufferFeatures.push(f);
        }
    });
    return { bufferFeatures, bufferLayerSource };
};

export const removeBufferFeature = id => {
    const [layer_type, featureId, bufferIndex] = id?.split('-');
    mapObj?.map
        ?.getLayerById(layer_type)
        ?.getSource()
        ?.getFeatures()
        ?.forEach(feature => {
            if (feature?.getId() == featureId) {
                let { bufferIndex: bufferIndexArr, value: bufferValue } = feature?.get('buffer');
                bufferIndexArr[bufferIndex] = false;
                const deleteBuffer = bufferIndexArr?.every(item => !item);
                if (deleteBuffer) feature?.set('buffer', null);
                else feature?.set('buffer', { value: bufferValue, bufferIndex: bufferIndexArr });

                console.log(feature?.get('buffer'));
            }
        });
};

export const avoidOverlap = drawnFeature => {
    const layers = appStore.current_layers.filter(
        lId => lId !== LAYER.PARCEL && getLayerType(lId)?.geometry_type === GEOMETRY_TYPES.POLYGON
    );
    let selectedLayer,
        featuresArr = [];
    const newAddedFeatureExtent = drawnFeature.getGeometry().getExtent();
    layers.forEach(lId => {
        const layer = mapObj.map.getLayerById(lId);
        featuresArr.push(...layer.getSource().getFeaturesInExtent(newAddedFeatureExtent));
        selectedLayer = mapObj.map.getLayerById(appStore.selected_layer_id);
    });

    featuresArr = featuresArr.filter(feat => feat != drawnFeature);

    if (!featuresArr.length) {
        return;
    }
    try {
        const geojson = GEO_JSON.writeFeaturesObject(featuresArr);
        const merged = turfMerge(geojson);
        let fc;
        if (!merged.features) {
            fc = featureCollection([merged]);
        } else {
            fc = merged;
        }

        const _combine = combine(fc);
        const turfDrawnFeature = getTurfFeature(drawnFeature.clone());

        _combine.features.forEach(f => {
            const diff = difference(turfDrawnFeature, f);
            if (diff) {
                drawnFeature.setGeometry(GEO_JSON.readFeature(diff).getGeometry().simplify(0.1));
            } else {
                selectedLayer.getSource().removeFeature(drawnFeature);
            }
        });

        if (drawnFeature.getGeometry().getType() === GEOMETRY_TYPES.MULTI_POLYGON) {
            selectedLayer.getSource().convertFeatureGeometry(drawnFeature);
        }
    } catch (e) {
        captureException(e);
    }
};

export const overWriteFromBuffer = (drawnFeature, parentFeature) => {
    const layers = appStore.current_layers.filter(
        lId => lId !== LAYER.PARCEL && getLayerType(lId)?.geometry_type === GEOMETRY_TYPES.POLYGON
    );
    let selectedLayer,
        featuresArr = [];
    const newAddedFeatureExtent = drawnFeature.getGeometry().getExtent();
    layers.forEach(lId => {
        const layer = mapObj.map.getLayerById(lId);
        featuresArr.push(...layer.getSource().getFeaturesInExtent(newAddedFeatureExtent));
        selectedLayer = mapObj.map.getLayerById(appStore.selected_layer_id);
    });

    featuresArr = featuresArr.filter(feat => feat != drawnFeature && !feat?.get('parent_id') && feat != parentFeature);

    if (!featuresArr.length) {
        return;
    }

    try {
        const geojson = GEO_JSON.writeFeaturesObject([drawnFeature]);
        const merged = turfMerge(geojson);
        let fc;
        if (!merged.features) {
            fc = featureCollection([merged]);
        } else {
            fc = merged;
        }
        // fc.features.forEach(turfFeat => {
        //     const olfeat = GEO_JSON.readFeature(turfFeat);
        //     console.log(olfeat.isValid())
        // })
        const _combine = combine(fc);

        featuresArr?.forEach(featureToCut => {
            const turfDrawnFeature = getTurfFeature(featureToCut.clone());

            _combine.features.forEach(f => {
                const diff = difference(turfDrawnFeature, f);
                if (diff) {
                    featureToCut.setGeometry(GEO_JSON.readFeature(diff).getGeometry().simplify(0.1));
                } else {
                    selectedLayer.getSource().removeFeature(featureToCut);
                }
            });

            if (featureToCut.getGeometry().getType() === GEOMETRY_TYPES.MULTI_POLYGON) {
                selectedLayer.getSource().convertFeatureGeometry(featureToCut);
            }
        });
    } catch (e) {
        captureException(e);
    }
};
Feature.prototype.getLayer = function () {
    let layer;
    const layers = mapObj.getEditableLayers();
    for (const l of layers) {
        const features = l
            .getSource()
            .getFeatures()
            .filter(f => f === this);
        if (features.length) {
            layer = l;
            break;
        }
    }
    return layer;
};

Feature.prototype.isValid = function () {
    try {
        const hasRedundantCoords = this.hasRedundantCoords();
        const isSelfIntersect = this.isSelfIntersect();
        const isSliverPolygon = this.isSliverPolygon();
        hasRedundantCoords && appStore.setError('Redundant Coords detected');
        isSelfIntersect && appStore.setError('Self Intersection detected');
        isSliverPolygon && appStore.setError('Sliver polygon detected');
        hasRedundantCoords && isSelfIntersect && appStore.setError('redundant & self-intersection detected');
        hasRedundantCoords && isSliverPolygon && appStore.setError('redundant & sliver detected');
        hasRedundantCoords &&
            isSelfIntersect &&
            isSliverPolygon &&
            appStore.setError('redundant, self-intersect & sliver detected');

        return !(hasRedundantCoords || isSelfIntersect || isSliverPolygon);
    } catch (e) {
        setExtra('featuretovalidate', JSON.stringify(GEO_JSON.writeFeatureObject(this)));
        captureException(e);
    }
};

Feature.prototype.makeValid = function () {
    try {
        if (this.hasRedundantCoords() && this.isSliverPolygon() && this.isSelfIntersect()) {
            this.simplify();
            this.removeSliverPolygon();
        } else if (this.hasRedundantCoords() && this.isSliverPolygon()) {
            this.removeSliverPolygon();
        } else if (this.hasRedundantCoords() && this.isSelfIntersect()) {
            this.simplify();
        } else if (this.hasRedundantCoords()) {
            this.simplify();
        } else if (this.isSelfIntersect()) {
            this.simplify();
            this.removeSelfIntersect();
        } else if (this.isSliverPolygon()) {
            this.removeSliverPolygon();
        }
    } catch (e) {
        setExtra('invalid_feature', JSON.stringify(GEO_JSON.writeFeatureObject(this)));
        // this.getLayer() && this.getLayer().getSource().removeFeature(this);
        message.error('System was not able to fix highlighted features, please resolve them manually');
        highlightFeature(this, 5000);
        captureException(e);
    }
};

Feature.prototype.hasRedundantCoords = function () {
    const poly = getTurfFeature(this);
    const initialCoords = poly.geometry.coordinates[0];
    const cleanPoly = cleanCoords(poly);
    const finalCoords = cleanPoly.geometry.coordinates[0];
    return initialCoords.length !== finalCoords.length;
};

Feature.prototype.simplify = function () {
    const geom = this.getGeometry();
    const simplifiedGeom = geom.simplify(GEOM_SIMPLIFICATION_TOLERANCE);
    this.setGeometry(simplifiedGeom);
};

Feature.prototype.isSelfIntersect = function () {
    const poly = getTurfFeature(this);
    const invalid = kinks(poly);
    return Boolean(invalid.features.length);
};

Feature.prototype.removeSelfIntersect = function () {
    const coords = this.getGeometry().getCoordinates()[0];
    const [, ...linearRings] = this.getGeometry().getLinearRings();
    const polyg = new Polygon([coords]);
    this.setGeometry(polyg);
    const poly = getTurfFeature(this);

    const unkink = unkinkPolygon(poly);
    if (unkink.features.length) {
        const layer = this.getLayer();
        if (layer) {
            const features = GEO_JSON.readFeatures(unkink);
            layer.getSource().addFeatures(features);
            layer.getSource().removeFeature(this);
            if (features.length <= 1) {
                layer.getSource().removeFeatures(features);
            }
            features.map(feature => {
                if (feature.getGeometry().getArea() < SLIVER_POLYGON_AREA_LIMIT) {
                    feature.getLayer().getSource().removeFeature(feature);
                }
                linearRings.map(linearRing => {
                    const singleFeature = getTurfFeature(feature);
                    const pointOfLinearRing = point(
                        transform(linearRing.getFirstCoordinate(), 'EPSG:3857', 'EPSG:4326')
                    );
                    const checkForOverlap = booleanIntersects(pointOfLinearRing, singleFeature);
                    checkForOverlap && feature.getGeometry().appendLinearRing(linearRing);
                });
            });
        }
    }
};

Feature.prototype.isSliverPolygon = function () {
    return Boolean(this.getGeometry().getArea() < SLIVER_POLYGON_AREA_LIMIT);
};

Feature.prototype.removeSliverPolygon = function () {
    const featureExtent = this.getGeometry().getExtent();
    const featuresInExtent = this.getLayer().getSource().getFeaturesInExtent(featureExtent);
    const featuresArr = featuresInExtent.filter(feat => feat != this);
    if (this.getGeometry().getArea() > 0 && featuresArr.length > 0) {
        const largestFeature = featuresArr.reduce(function (currentLargest, feature) {
            return currentLargest.getGeometry().getArea() > feature.getGeometry().getArea() ? currentLargest : feature;
        });
        const geojson = GEO_JSON.writeFeaturesObject([largestFeature, this]);
        const merged = turfMerge(geojson);
        this.setGeometry(GEO_JSON.readFeature(merged).getGeometry().simplify(0.00001));
        this.getLayer().getSource().removeFeature(largestFeature);
    } else {
        this.getLayer().getSource().removeFeature(this);
    }
};

Feature.prototype.removeRedundantCoords = function () {
    const poly = getTurfFeature(this);
    const cleanPoly = cleanCoords(poly);
    const _union = GEO_JSON.readFeature(union(poly, cleanPoly)); // we take union bcz cleanCoords totally remove redundant coords this may change shape of actual geometry
    const layer = this.getLayer();
    if (layer) {
        /**
         * Instead of setGeometry we add _union as a feature bcz union may return multipolygon
         * To handle this we have 2 options either put a check and convert here or
         * just remove this (feature) and add _union as feature in this way we already have conversion onaddfeature
         * */

        layer.getSource().removeFeature(this);
        layer.getSource().addFeature(_union);
    }
};

export const getLayerType = id => {
    return appStore.all_layer_types.get(id);
};

export const getSourceType = id => {
    return appStore?.all_source_types.get(id);
};

export const squareFeetToAcre = squareFeet => {
    return squareFeet / 43560;
};
