import { captureException, setExtra } from '@sentry/react';
import clone from '@turf/clone';
import union from '@turf/union';
import { polygon, featureCollection } from '@turf/helpers';
import { message } from '../UIComponents';
import { Feature } from 'ol';
import { HighlightFeatureStyleFn } from '../Constants/MapConstant';
import { ERROR_INVALID_FEATURE } from '../Constants/Messages';
import { buffer } from '@turf/turf';
import { format } from 'date-fns';
import React from 'react';
import { appStore, mapObj } from '../MainPage/Annotation/OlMap/MapInit';
import { getArea, getLength } from 'ol/sphere';
import { Circle } from 'ol/geom';
import { fromCircle } from 'ol/geom/Polygon';
import { ACTION, TOOL_TYPE } from '../Constants/Tool';

/**
 * @name downloadFileWithUrl
 * @function
 * @description Download a file from URL
 * @param {String} url URL of file
 * @param {String} name Name of file while download (Optional)
 */
export const downloadFileWithUrl = function (url, name) {
    let link = document.createElement('a');
    link.download = name;
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

/**
 * @name objectToArray
 * @function
 * @description Converst a object to array in following format
 * obj = {1: 'test', 2: 'test 2'}
 * return [{id: 1, value: 'test'}, {id: 2, value: 'test 2'}]
 * @param {Object} object Source Object
 */
export const objectToArray = function (obj) {
    let array = [];
    if (Object.keys(obj).length) {
        for (let i in obj) {
            let temp_obj = {
                id: i,
                value: obj[i]
            };
            array.push(temp_obj);
        }
    }
    return array;
};

/**
 * @name getParameterFromUrl
 * @function
 * @description Get parameter from URL
 * @param {String} url URL
 * @param {String} name Parameter name to be get
 */
export const getParameterFromUrl = name => {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&#]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

/**
 * @name createCirclePointCoords
 * @function
 * @description Calculate circle coordinates from center,radius
 * @param {Number} circleCenterX circleCenterX
 * @param {Number} circleCenterX circleCenterY
 * @param {Number} circleRadius circleRadius
 * @param {Number} pointsToFind No of points to found
 */
export const createCirclePointCoords = (circleCenterX, circleCenterY, circleRadius, pointsToFind = 360) => {
    const angleToAdd = 360 / pointsToFind;
    let coords = [];
    let angle = 0;
    for (var i = 0; i < pointsToFind; i++) {
        angle = angle + angleToAdd;
        let coordX = circleCenterX + circleRadius * Math.cos((angle * Math.PI) / 180);
        let coordY = circleCenterY + circleRadius * Math.sin((angle * Math.PI) / 180);
        coords.push([coordX, coordY]);
    }

    return coords;
};
/**
 * @name getDateString
 * @function
 * @description Get date string from timestamp
 * @param {int} timestamp timestamp
 */
export const getDateString = (timestamp, withTime = false) => {
    const date = new Date(timestamp);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    if (hours < 10) {
        hours = `0${hours}`;
    }

    if (minutes < 10) {
        minutes = `0${minutes}`;
    }

    if (seconds < 10) {
        seconds = `0${seconds}`;
    }
    if (withTime) {
        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    }

    return day + ' ' + month + ' ' + year;
};

Array.prototype.difference = function (arr2, key = null) {
    return this.filter(x => {
        if (key) {
            return !arr2.some(y => x[key] == y[key]);
        } else {
            return !arr2.includes(x);
        }
    });
};

export class IterableObject {
    all(exceptItems = []) {
        let items = Object.values(this);
        if (exceptItems.length) {
            items = items.filter(item => !exceptItems.includes(item));
        }
        return items;
    }
}

/**
 * @name highlightFeatureIfInvalid
 * @function
 * @description To hightlight an invalid feature
 * @param {Feature} feature feature
 * @param {Boolean} showErrorMsg showErrorMsg default true
 */

export const highlightFeatureIfInvalid = function (feature, showErrorMsg = true) {
    if (!feature.isValid()) {
        highlightFeature(feature);
        showErrorMsg && message.error(appStore?.error_status);
    }
};

/**
 * @name highlightFeatures
 * @function
 * @description To hightlight a feature by changing its style for specific time
 * @param {Feature} feature feature
 */

export const highlightFeatures = features => {
    for (const f of features) {
        highlightFeature(f);
    }
};

/**
 * @name highlightFeature
 * @function
 * @description To hightlight a feature by changing its style for specific time
 * @param {Feature} feature feature
 */

export const highlightFeature = (feature, time = 10000) => {
    feature.setStyle(HighlightFeatureStyleFn());
    setTimeout(() => {
        feature.setStyle();
    }, time);
};

// TODO: Move to mapHelper after merging
export function turfMerge(fc) {
    let merged = clone(fc.features[0]);
    let poly;
    let features = fc.features;
    // Storing unmerged polygons (due to TopologyException) and adding them separately
    let unmerged = [];
    try {
        for (let i = 1, len = features.length; i < len; i++) {
            poly = features[i];
            if (poly.geometry) {
                try {
                    // poly = buffer(poly, 0.00001); //This is because the vertices between adjacent polygons do not precisely match
                    merged = union(merged, poly);
                } catch (err) {
                    if (err.name == 'TopologyException') {
                        unmerged.push(poly);
                    }
                    setExtra('merged', JSON.stringify(merged));
                    setExtra('poly', JSON.stringify(poly));
                    setExtra('features', features);
                    setExtra('Request ID', localStorage.getItem('job_id'));
                    captureException(err);
                }
            }
        }
        let polys = [];
        let _features;
        // union returns multipolygon when no feature is overlapped
        // convert multipolygon to polygon
        if (merged.geometry.type == 'MultiPolygon') {
            merged.geometry.coordinates.forEach(function (coords) {
                let poly = polygon(coords);
                polys.push(poly);
            });
            // Pushing unmerged polygons into the list
            unmerged.forEach(poly => {
                polys.push(poly);
            });
            _features = featureCollection(polys);
        } else {
            // If there are any unmerged polygons, return a featureCollection containing unmerged polygons
            if (unmerged.length > 0) {
                let polys = [];
                unmerged.forEach(poly => {
                    polys.push(poly);
                });
                polys.push(merged);
                _features = featureCollection(polys);
            } else {
                _features = merged;
            }
        }
        return _features;
    } catch (err) {
        setExtra('merged', JSON.stringify(merged));
        setExtra('poly', JSON.stringify(poly));
        setExtra('features', features);
        setExtra('Request ID', localStorage.getItem('job_id'));
        captureException(err);
    }
}

export const getWorker = filename => {
    if (window.Worker && filename) {
        return new Worker(filename);
    }
};

/**
 * @param {type string} stringA
 * @param {type string} stringB
 * @returns returns 0 or 1, based on reference string comes before, or after as the given string in sort order.
 */
export const sortString = (stringA, stringB) => {
    if (stringA === '') {
        return 1;
    }
    if (stringB === '') {
        return -1;
    }
    if (stringA === '' && stringB === '') {
        return 0;
    }
    return stringA.localeCompare(stringB);
};

export const sortDate = (dateA, dateB) => new Date(dateA) - new Date(dateB);

/**
 * @name sanitizeValue : takes a string input, removes all the spaces present in the string and returns it.
 */
export const sanitizeValue = value => value.trim().replaceAll(' ', '');

export const getColumnTitle = title => <b>{title}</b>;

export const debounce = (cb, timeOut = 300) => {
    let timer;
    return (...args) => {
        let context = this;
        clearTimeout(timer);
        timer = setTimeout(() => {
            cb.apply(context, args);
        }, timeOut);
    };
};

export const validateEmail = email => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const toHoursMinutesSeconds = totalSeconds => {
    function doubleDigit(val) {
        const newVal = val >= 0 && val <= 9 ? `0${val}` : val;
        return newVal;
    }
    const seconds = doubleDigit(Math.floor(totalSeconds % 60));
    const minutes = doubleDigit(Math.floor((totalSeconds % 3600) / 60));
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = doubleDigit(Math.floor((totalSeconds % (3600 * 24)) / 3600) + days * 24);

    return `${hours}:${minutes}:${seconds}`;
};

export const getTimeInHoursAndMins = secs => {
    secs = Number(secs);
    let h = Math.floor(secs / 3600);
    let m = Math.floor((secs % 3600) / 60);
    let s = Math.floor((secs % 3600) % 60);

    let hDisplay = h > 0 ? h + ' H ' : '';
    let mDisplay = m > 0 ? m + ' M ' : '';
    let sDisplay = s > 0 ? s + ' S ' : '';
    return `${h}:${m}:${s}`;
};

export const formatDateTime = dateTimeString => {
    const parsedDateTime = new Date(dateTimeString);
    return format(parsedDateTime, 'MMM dd, yyyy • hh:mm a');
}

export const addOverlay = (map, element, coordinate, color, overlay) => {
    if (!element || !map) return;
    if (!appStore.live_measurement) return;

    map.addOverlay(overlay);
    overlay?.setPosition(coordinate);
    element.style.display = 'flex';
};

export function getAreaOfFeature(geometry) {
    var geometry = geometry;
    var areaInSquareMeters;
    if (geometry instanceof Circle) {
        geometry = fromCircle(geometry, 48);
    }
    areaInSquareMeters = getArea(geometry);

    // Get the area in square meters

    // Convert square meters to square feet with a more precise conversion factor
    var areaInSquareFeet = areaInSquareMeters * 10.7639104;

    return areaInSquareFeet?.toFixed(2);
}

export function getLengthOfFeature(geometry) {
    var geometry = geometry;

    if (geometry instanceof Circle) {
        geometry = fromCircle(geometry, 48);
    }
    length = getLength(geometry);

    // Get the area in square meters

    // Convert square meters to square feet with a more precise conversion factor
    var lengthInFeet = length * 3.28084;

    return lengthInFeet?.toFixed(2);
}

export const showArea = (geometry, overlay) => {
    let center;

    if (geometry instanceof Circle) {
        center = geometry?.getCenter();
    } else {
        center = geometry.getInteriorPoint().getCoordinates();
    }

    let area = getAreaOfFeature(geometry);
    let perimeter = getLengthOfFeature(geometry);

    if (area < 100) return;
    const element = overlay.getElement();
    element.innerHTML = `
    <div>Area : ${area} ft<sup>2</sup></div>
    <div>Perimeter : ${perimeter} ft</div>
    `;
    element.style.display = 'flex';

    addOverlay(mapObj?.map, element, center, '', overlay);
};

export const showLength = (geometry, overlay) => {
    var center = geometry.getLastCoordinate();

    let perimeter = getLengthOfFeature(geometry);

    const element = overlay.getElement();
    element.innerHTML = `
    <div>Length : ${perimeter} ft</div>
    `;
    element.style.display = 'flex';

    addOverlay(mapObj?.map, element, center, '', overlay);
};

export const generateUniqueId = () => {
    const id = Date.now() + '' + Math.round(Math.random() * 10000);
    return id;
};

export const checkForBufferTools = tool => {
    const bufferToolsEnable = [TOOL_TYPE.PAN, TOOL_TYPE.SELECT, ACTION.DELETE, ACTION.UNDO, ACTION.REDO];

    return bufferToolsEnable.includes(tool);

};
