import { Select, DragBox } from 'ol/interaction';
import { selectStyle } from '../MapBase';
import { message } from '../../../../UIComponents';
import { click, shiftKeyOnly, all } from 'ol/events/condition';
import { unByKey } from 'ol/Observable';
import { appStore, undoRedoPush } from '../MapInit';
import { KEY_CODE, LAWN_ATTRIBUTE, PARCEL } from '../../../../Constants/MapConstant';
import { ACTION, TOOL_NAMES } from '../../../../Constants/Tool';
import { executeAction } from '../MapInit';

const LAWN_KEY_MAP = {
    1: 'F',
    2: 'B',
    3: 'L',
    4: 'R'
};

class SelectTool {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.select = null;
        this.drag = null;
        this.layer = null;
        this.key = null;
        this.clipboard = [];
        this.timeTaken = 0;
    }

    init(id) {
        this.off();
        this.layer = this.mapObj.map.getLayerById(id);

        if (this.layer) {
            this.select = new Select({
                layers: [this.layer],
                multi: true,
                condition: click || all(shiftKeyOnly, click),
                style: selectStyle(id)
            });
            this.mapObj.map.addInteraction(this.select);

            this.drag = new DragBox();
            this.mapObj.map.addInteraction(this.drag);
            this.timeTaken = Date.now();

            this.drag.on('boxdrag', () => {
                this.emptyFeatureArray();
            });

            this.drag.on('boxend', () => {
                let extent = this.drag.getGeometry().getExtent();
                this.layer.getSource().forEachFeatureIntersectingExtent(extent, feature => {
                    this.select.getFeatures().push(feature);
                });
                message.info({
                    content: `${this.select.getFeatures().getLength()} features selected.`,
                    style: {
                        marginTop: '4vh'
                    },
                    duration: 0,
                    key: 'selection-tool'
                });
                if (!this.select.getFeatures().getLength()) {
                    message.destroy('selection-tool');
                }
                let currentTime = Date.now();
                this.mapObj.AppStore.setTrackTools({
                    toolName: TOOL_NAMES.select_features,
                    timeTaken: currentTime - this.timeTaken, // in ms
                    successfulOperation: true
                });
            });

            this.key = this.mapObj.map.on('singleclick', e => {
                let layer = this.mapObj.map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
                    return layer;
                });
                if (!layer) {
                    this.emptyFeatureArray();
                } else if (layer.get('id') == PARCEL) {
                    this.emptyFeatureArray();
                }
                message.destroy('selection-tool');
                message.info({
                    content: `${this.select.getFeatures().getLength()} features selected.`,
                    style: {
                        marginTop: '4vh'
                    },
                    duration: 0,
                    key: 'selection-tool'
                });

                if (!this.select.getFeatures().getLength()) {
                    message.destroy('selection-tool');
                }
            });

            window.addEventListener('keydown', this.handleKeydown, false);
        }
    }

    off() {
        this.emptyFeatureArray();
        this.mapObj.map.removeInteraction(this.select);
        this.mapObj.map.removeInteraction(this.drag);
        unByKey(this.key);
        window.removeEventListener('keydown', this.handleKeydown);
        message.destroy('selection-tool');
    }

    handleKeydown = evt => {
        if (appStore?.bufferModal?.value) return;

        if ([1, 2, 3, 4].includes(parseInt(evt.key))) {
            this.changeLawnAttribute(LAWN_KEY_MAP[evt.key]);
        } else if (evt.keyCode === KEY_CODE.DELETE) {
            executeAction(ACTION.DELETE, evt);
            message.destroy('selection-tool');
            message.info({
                content: `${this.select.getFeatures().getLength()} features selected.`,
                style: {
                    marginTop: '4vh'
                },
                duration: 0,
                key: 'selection-tool'
            });
        }
    };

    changeLawnAttribute(lawnType) {
        const features = this.select ? this.select.getFeatures().getArray() : [];
        if (!features.length) {
            message.error('No feature is selected.');
        } else {
            for (let i = 0; i < features.length; i++) {
                let feature = features[i];
                feature.setProperties({ [LAWN_ATTRIBUTE]: lawnType });
            }
            this.emptyFeatureArray();
            undoRedoPush();
        }
    }

    emptyFeatureArray() {
        if (this.select) {
            this.select.getFeatures().clear();
        }
    }

    // cut() {
    //     this.clipboard = [];
    //     this.is_cut = true;
    //     this.prev_layer = this.layer;
    //     if (this.select) {
    //         this.select
    //             .getFeatures()
    //             .getArray()
    //             .forEach(feat => {
    //                 this.clipboard.push(feat);
    //             });
    //     }
    // }
    // copy() {
    //     this.clipboard = [];
    //     this.is_cut = false;
    //     if (this.select) {
    //         this.select
    //             .getFeatures()
    //             .getArray()
    //             .forEach(feat => {
    //                 this.clipboard.push(feat);
    //             });
    //     }
    // }
    // paste(id) {
    //     const layer = this.mapObj.map.getLayerById(id);
    //     if (layer) {
    //         if (this.clipboard.length > 0) {
    //             const layer_geometry_type = LAYER_TYPE_GEOMETRY_MAP[id];
    //             const copied_geometry_type = this.clipboard[0].getGeometry().getType(); // Assuming all are of same type
    //             // Paste only if geometry types match
    //             if (layer_geometry_type == copied_geometry_type) {
    //                 const source = layer.getSource();
    //                 this.clipboard.forEach(feat => {
    //                     let geom = feat.getGeometry().clone();
    //                     // Translate features only if copying
    //                     if (!this.is_cut) {
    //                         geom.translate(5, 5);
    //                     }
    //                     let new_feat = new Feature({ geometry: geom });
    //                     source.addFeature(new_feat);
    //                     if (this.is_cut) {
    //                         this.prev_layer && this.prev_layer.getSource().removeFeature(feat);
    //                         this.clipboard = [];
    //                     }
    //                 });
    //                 this.select.getFeatures().clear();
    //                 undoRedoPush();
    //             } else {
    //                 message.error(`Trying to paste ${copied_geometry_type} features into ${layer_geometry_type} layer`);
    //             }
    //         }
    //     }
    // }
    // deleteFeatures() {
    //     const features = this.select ? this.select.getFeatures().getArray() : [];
    //     if (!features.length) {
    //         message.error('No feature is selected.');
    //     } else {
    //         let source = this.layer.getSource();
    //         for (let i = 0; i < features.length; i++) {
    //             let feature = features[i];
    //             source.removeFeature(feature);
    //         }
    //         this.emptyFeatureArray();
    //         undoRedoPush();
    //     }
    // }

    // mergeFeatures() {
    //     const features = this.select ? this.select.getFeatures().getArray() : [];
    //     if (!features.length) {
    //         console.log('del;ete');

    //         message.error('No feature is selected.');
    //     } else {
    //         const lawnType = features[0].getProperties()[LAWN_ATTRIBUTE];

    //         let geojson = new GeoJSON().writeFeaturesObject(features, {
    //             dataProjection: 'EPSG:4326',
    //             featureProjection: 'EPSG:3857'
    //         });

    //         let source = this.layer.getSource();
    //         let merged = turfMerge(geojson);

    //         for (let i = 0; i < features.length; i++) {
    //             let feature = features[i];
    //             source.removeFeature(feature);
    //         }

    //         let mergedGeojson = new GeoJSON().readFeatures(merged, {
    //             dataProjection: 'EPSG:4326',
    //             featureProjection: 'EPSG:3857'
    //         });

    //         mergedGeojson.forEach(f => {
    //             f.setProperties({ [LAWN_ATTRIBUTE]: lawnType });
    //         });

    //         source.addFeatures(mergedGeojson);

    //         this.emptyFeatureArray();
    //         undoRedoPush();
    //     }
    // }
}

export default SelectTool;
