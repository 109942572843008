import { observable, action } from 'mobx';
import { initTool, mapObj } from '../MainPage/Annotation/OlMap/MapInit';
import { getAPI } from '../Utils/ApiCalls';
import { LAYER_TYPE, SOURCE_TYPE_CONFIG } from '../Constants/Urls';
import { CONTEXT_MENU_FUNCTION } from '../HelperComponents/ContextMenuObject';
import { FEATURE_COLOR } from '../Constants/MapConstant';
import { TOOL_NAMES } from '../Constants/Tool';
import { CORRECTION_COMMENT } from '../Constants/Annotations';

/**
	This is the global state container for FMG-Dashboard
*/
class S_App {
    constructor() {
        this.user = JSON.parse(localStorage.getItem('user')) || {};
        this.is_logged_in = localStorage.getItem('token') || undefined;
    }

    @observable is_logged_in = false;
    @observable user = {};
    @observable current_tool = 'pan';
    @observable selected_layer_id = null;
    @observable snapping_mode = true;
    @observable current_source_type = localStorage.getItem('source_type');
    @observable current_layers = [];
    @observable layers_opacity = 0;
    @observable ortho_image_visible = false;
    @observable avoid_overlap = false;
    @observable live_measurement = true;
    @observable layer_opacity_checkbox = false;
    @observable loader_state = false;
    @observable all_layer_types = new Map();
    @observable all_source_types = new Map();
    @observable tolerance_value = 10;
    @observable submit_status = false;
    @observable error_status = '';
    @observable source_type_loading = false;
    @observable submitBtnModal = false;
    @observable layer_type_loading = false;
    @observable online_status = true;
    @observable update_map_legend = false;
    @observable track_tools = [];
    @observable comments = [];
    @observable overlay = null;
    @observable selectedComment = null;
    @observable current_job = null;
    @observable measurementOverlay = null;
    @observable comment_action_selected = false;
    @observable commentType = CORRECTION_COMMENT;
    @observable ratingData = null;

    @action setMeasureMentOverLay = overlay => {
        this.measurementOverlay = overlay;
    };
    @action setCommentType = val => {
        this.commentType = val;
    };
    @action setLiveMeasureMent = value => {
        this.live_measurement = value;
    };
    @observable bufferModal = {
        value: false,
        bufferFn: () => {}
    };

    @observable current_job_data = null;
    @observable delete_modal = {
        value: false,
        count: 0,
        layerId: '',
        deleteFn: () => {}
    };

    @action setDeleteModal(obj, fn = () => {}) {
        this.delete_modal = { ...obj };
        this.delete_modal.deleteFn = fn;
    }
    @action setBufferModal(args) {
        this.bufferModal = {
            ...args
        };
    }

    @action loginUser(user) {
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', user.token);
        localStorage.setItem('source_type', user.source_type || '');
        this.user = user;
        this.is_logged_in = true;
        this.current_source_type = user.source_type;
    }

    @action setcurrentJob(data) {
        this.current_job_data = { ...data };
    }

    @action logoutUser() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('source_type');
        this.is_logged_in = false;
        window.location.reload();
    }

    @action setCurrentSourceType(val) {
        this.current_source_type = val;
        localStorage.setItem('source_type', val);
    }
    @action setSelectedLayerId(val) {
        this.selected_layer_id = val;
        if (val) {
            localStorage.setItem('selected_layer_id', val);
            initTool(this.current_tool, this.selected_layer_id);
        }
    }

    @action setCurrentTool(val, options = {}) {
        this.current_tool = val;
        if (val) {
            localStorage.setItem('current_tool', val);
            initTool(this.current_tool, this.selected_layer_id, options.mode);
        }
    }
    @action setCurrentLayers(val) {
        this.current_layers = val;
    }

    @action setLayersOpacity(val) {
        this.layers_opacity = val;
        mapObj.setLayersOpacity(val);
    }

    @action setSnappingMode(val) {
        this.snapping_mode = val;
    }

    @action setOrthoImageVisible(val) {
        this.ortho_image_visible = val;
    }

    @action setAvoidOverlap(val) {
        this.avoid_overlap = val;
    }

    @action setLayerOpacityCheckbox(val) {
        this.layer_opacity_checkbox = val;
    }

    @action setLoaderState(val) {
        this.loader_state = val;
    }

    @action setToleranceValue(val) {
        this.tolerance_value = val;
    }

    @action setSubmit(val) {
        this.submit_status = val;
    }

    @action setError(val) {
        this.error_status = val;
    }

    @action setOnlineStatus(val) {
        this.online_status = val;
    }

    @action setComments(val) {
        this.comments = [...val];
    }

    @action setOverlay(val) {
        this.overlay = val;
    }

    @action setSelectedComment(val, cb) {
        this.selectedComment = { ...val };
        if (cb) cb();
    }

    @action setCurrentJob(val) {
        this.current_job = val;
    }

    /**make a central API for both layer ans source types */

    @action setAllLayerTypes() {
        this.layer_type_loading = true;
        getAPI(LAYER_TYPE).then(layer_type => {
            const map = new Map();
            layer_type.forEach(item => {
                map.set(item.name, {
                    color: item.meta_data?.color,
                    geometry_type: item.geometry_type,
                    z_index: item?.z_index
                });
            });

            map.set('hard_edge_layer', {
                color: FEATURE_COLOR['H'],
                zIndex: 100,
                geometry_type: 'LineString'
            });
            map.set('soft_edge_layer', {
                color: FEATURE_COLOR['S'],
                zIndex: 100,
                geometry_type: 'LineString'
            });
            map.set('line_trim_layer', {
                color: FEATURE_COLOR['L'],
                zIndex: 100,
                geometry_type: 'LineString'
            });
            this.all_layer_types = map;
            this.layer_type_loading = false;
        });
    }

    @action updateAllLayerTypes(layer) {
        this.all_layer_types.set(layer.layer_type, {
            color: layer.color,
            zIndex: 100,
            geometry_type: layer.geometry_type
        });
    }

    @action setAllSourceTypes() {
        this.source_type_loading = true;
        getAPI(SOURCE_TYPE_CONFIG).then(source_type => {
            const map = new Map();
            source_type.forEach(item => {
                map.set(item.source_type, {
                    property_type: item?.property_type,
                    job_save_time: item?.job_save_time,
                    tools: [...item?.tools, 'comment'],
                    rating_handler: item?.rating_handler,
                    lifeline: item?.lifeline,
                    context_menu: item?.context_menu,
                    extension_timer: item?.extension_timer,
                    style: item?.style
                });
            });
            this.source_type_loading = false;
            this.all_source_types = map;
        });
    }

    @action setSubmitBtnModal(val) {
        this.submitBtnModal = val;
    }

    @action setUpdateMapLegend() {
        this.update_map_legend = !this.update_map_legend;
    }

    @action setRatingData(val) {
        this.ratingData = val;
    }
    @action setTrackTools(props) {
        const {
            toolName,
            timeTaken = 0,
            successfulOperation = false,
            hasError = false,
            error = '',
            operation = '', // here operation indicates "the way the tool was triggered (keyboard) or (mouse),
            ...otherProps
        } = props; // these are the configurable props to for tool tracking. we can send in any data of our choice in tools files & it will be tracked and stored here.
        let toolsData = [...this.track_tools];
        if (toolName === TOOL_NAMES.comment) {
            return;
        }
        // finds an existing tool from the state
        let existingTool = toolsData.find(list => list.name === toolName);

        if (existingTool) {
            // if existing tool means, there is already one keyboard or mouse clicked on this tool
            if (operation) {
                existingTool.usage_information.last_tool_used = operation;
            }
            // this is a metric which calculates the time taken for the tool to perform an action.
            // implemented in a simple fashion, which records the initialization time (when the tool started to be used) and the completion time.
            // time is currently being recorded via Date.now() in ms
            if (timeTaken) {
                existingTool.usage_information.time_for_operation.push(timeTaken);
            }
            // on successful operation, we need to add the operation count.
            if (successfulOperation) {
                existingTool.usage_information.total_no_of_successful_operations += 1; // successful event, click registered & operation done.
                if (existingTool.usage_information.last_tool_used === 'keyboard') {
                    existingTool.usage_information.no_of_times_clicked_by_keyboard += 1;
                }
                if (existingTool.usage_information.last_tool_used === 'mouse') {
                    existingTool.usage_information.no_of_times_clicked_by_mouse += 1;
                }
            }
            // if there occurs any error while performing the calculation by the tool, we store the error obj here.
            if (hasError) {
                existingTool.usage_information.error_info.push(JSON.stringify(error));
                if (existingTool.usage_information.last_tool_used === 'keyboard') {
                    existingTool.usage_information.no_of_times_clicked_by_keyboard += 1;
                }
                if (existingTool.usage_information.last_tool_used === 'mouse') {
                    existingTool.usage_information.no_of_times_clicked_by_mouse += 1;
                }
            }

            existingTool.usage_information = { ...existingTool.usage_information, ...otherProps };
        } else {
            // Will be triggered when mouse click happens or keyboard click happens. That's why default data
            if (toolName) {
                let newData = {
                    name: toolName,
                    usage_information: {
                        no_of_times_clicked_by_mouse: toolName === 'Fix Validity' ? 1 : 0, // on successful operation done, currently no successful operation done, only click registered
                        no_of_times_clicked_by_keyboard: 0, // on successful operation done
                        total_no_of_successful_operations: toolName === 'Fix Validity' ? 1 : 0, // fix validity is not a tool, and for rest everything we calculate it from 0.
                        time_for_operation: [],
                        error_info: [],
                        last_tool_used: operation // helps us track the last used tool, which helps us further in updating the keyboard count or mouse count in future (the above if code)
                    }
                };
                toolsData.push(newData);
            }
        }

        this.track_tools = [...toolsData];
    }
    @action setCommentActionSelected(val) {
        this.comment_action_selected = val;
    }

    @action resetTrackTools = () => {
        let tools = [...this.track_tools];
        tools.forEach(tool => {
            tool.usage_information = {
                no_of_times_clicked_by_mouse: tool.toolName === 'Fix Validity' ? 1 : 0, // on successful operation done, currently no successful operation done, only click registered
                no_of_times_clicked_by_keyboard: 0, // on successful operation done
                total_no_of_successful_operations: tool.toolName === 'Fix Validity' ? 1 : 0, // fix validity is not a tool, and for rest everything we calculate it from 0.
                time_for_operation: [],
                error_info: [],
                last_tool_used: tool.usage_information.last_tool_used
            };
        });
        this.track_tools = [...tools];
    };

    @action resetAppStore() {
        this.current_tool = 'pan';
        this.selected_layer_id = null;
        this.snapping_mode = false;
        this.current_source_type = localStorage.getItem('source_type');
        this.current_layers = [];
        this.layers_opacity = 0;
        this.ortho_image_visible = false;
        this.avoid_overlap = false;
        this.layer_opacity_checkbox = false;
        this.loader_state = false;
        this.tolerance_value = 10;
        this.submit_status = false;
        this.error_status = '';
        this.source_type_loading = false;
        this.submitBtnModal = false;
        this.layer_type_loading = false;
        this.comments = [];
        this.overlay = null;
        this.selectedComment = null;
    }

    ContextMenuFn(_this, e) {
        const value = this.all_source_types.get(this.current_source_type)?.context_menu;
        return CONTEXT_MENU_FUNCTION[value](_this, e);
    }
}

export default S_App;
