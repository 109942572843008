import { buffer } from '@turf/turf';
import { EMPTY_GEOJSON, GEOMETRY_TYPES, GEO_JSON } from '../../../../Constants/MapConstant';
import { appStore, selectTool, undoRedoPush } from '../MapInit';
import { message } from 'antd';
import { Feature } from 'ol';
import { LineString } from 'ol/geom';
import {
    addBufferFeature,
    avoidOverlap,
    getBufferFeaturesOfParent,
    overWriteFromBuffer,
    removeBufferFeature
} from '../../../../Utils/MapHelper';
import { generateUniqueId } from '../../../../Utils/HelperFunctions';
import { TOOL_NAMES } from '../../../../Constants/Tool';

class BufferTool {
    constructor(mapObj) {
        this.timeTaken = 0;
        this.mapObj = mapObj;
        this.selectedFeatures = [];
    }

    execute() {
        this.select = selectTool.select;
        this.layer = selectTool.layer;
        this.selectedFeatures = this.select ? [...this.select.getFeatures().getArray()] : [];
        if (!this.selectedFeatures?.length) return message.info('No features selected to add buffer');

        appStore.setBufferModal({ value: true, bufferFn: this.bufferFeatures });
    }

    bufferFeatures = (bufferValue = 10, applyToAll) => {
        this.timeTaken = Date.now();
        let features = this.selectedFeatures;

        if (applyToAll) features = this.layer.getSource().getFeatures();

        features?.forEach(feature => {
            const layer_type = this.layer?.get('id');
            let bufferLayer = this.mapObj.map.getLayerById('buffer_' + layer_type);
            if (!bufferLayer) {
                this.mapObj.addVectorLayer(EMPTY_GEOJSON, 'buffer_' + layer_type);
                undoRedoPush();
            }
            bufferLayer = this.mapObj.map.getLayerById('buffer_' + layer_type);

            if (feature?.get('buffer')) {
                const { bufferFeatures, bufferLayerSource } = getBufferFeaturesOfParent(layer_type, feature?.getId());

                bufferLayerSource.removeFeatures(bufferFeatures);
            }

            if (feature?.getGeometry()?.getType() === GEOMETRY_TYPES.LINESTRING)
                feature?.set('buffer', { value: bufferValue, bufferIndex: [true, true] });
            else feature?.set('buffer', { value: bufferValue, bufferIndex: [true] });

            if (!feature?.getId()) feature?.setId(generateUniqueId());

            addBufferFeature(feature, layer_type, bufferLayer);
        });
        undoRedoPush();
        let currentTime = Date.now();
        this.mapObj.AppStore.setTrackTools({
            toolName: TOOL_NAMES.buffer_tool,
            timeTaken: currentTime - this.timeTaken, // in ms
            successfulOperation: true
        });
    };

    off() {}
}

export default BufferTool;
