import { Draw } from 'ol/interaction';
import { drawStyle } from '../MapBase';
import { appStore, undoRedoPush } from '../MapInit';
import Polygon from 'ol/geom/Polygon';
import Point from 'ol/geom/Point';
import * as turf from '@turf/turf';
import { avoidOverlap } from '../../../../Utils/MapHelper';
import { TOOL_NAMES } from '../../../../Constants/Tool';
import { showArea } from '../../../../Utils/HelperFunctions';
import { unByKey } from 'ol/Observable';

class DrawRectangle {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.draw = null;
        this.snap = null;
        this.timeTaken = 0;
        this.listner = null;
    }

    init(id) {
        this.off();

        const layer = this.mapObj.map.getLayerById(id);
        this.layer = layer;
        if (layer) {
            const source = layer.getSource();

            this.draw = new Draw({
                source: source,
                type: 'Polygon',
                style: (feat, res) => {
                    if (feat.getGeometry().getType() == 'LineString') {
                        return null;
                    }
                    return drawStyle(id);
                },
                dragVertexDelay: 0,
                snapTolerance: 1,
                maxPoints: 3,
                condition: e => {
                    const mouseClick = e.originalEvent.which;
                    if (mouseClick == 3 || mouseClick == 2) {
                        return false;
                    }
                    return true;
                },
                geometryFunction: this.geometryFunction
            });
            this.mapObj.map.addInteraction(this.draw);
            this.snap = this.mapObj.getSnap();
            this.snap.forEach(snap => {
                this.mapObj.map.addInteraction(snap);
            });
            this.draw.on('drawend', this.pushTimeout);
            this.draw.on('drawstart', this.drawStart);
            window.addEventListener('keydown', this.keyDownHandler);
            // this.layer.getSource().on('addfeature', undoRedoPush);
        }
    }

    drawStart(evt) {
        this.timeTaken = Date.now();
        let sketch = evt.feature;
        let overlay = appStore?.measurementOverlay;
        this.listner = sketch.getGeometry().on('change', event => {
            const geom = event.target;
            if (geom instanceof Polygon) {
                showArea(geom, overlay);
            }
        });
    }
    geometryFunction = (coordinates, geometry) => {
        if (!geometry) {
            geometry = new Polygon(coordinates);
        } else {
            geometry.setCoordinates(coordinates);
        }
        const points = coordinates[0];
        if (points && points.length > 1) {
            const first_point = turf.point(new Point(points[0]).transform('EPSG:3857', 'EPSG:4326').getCoordinates());
            const second_point = turf.point(new Point(points[1]).transform('EPSG:3857', 'EPSG:4326').getCoordinates());
            // TODO - Improve readability
            if (points.length > 2) {
                const third_point = turf.point(
                    new Point(points[2]).transform('EPSG:3857', 'EPSG:4326').getCoordinates()
                );
                const bearing = turf.rhumbBearing(second_point, first_point);
                const bearing2 = turf.rhumbBearing(third_point, second_point);
                let angle_bw = bearing2 - bearing;
                if (angle_bw < 0) {
                    angle_bw += 360;
                }
                const dir = angle_bw > 180 ? 1 : -1;
                const perpendicular_bearing = bearing + dir * 90;
                const dist = turf.distance(second_point, third_point);
                const perpendicular_line_point = turf.rhumbDestination(second_point, dist, perpendicular_bearing);
                const perpendicular_line_coords = [
                    second_point.geometry.coordinates,
                    perpendicular_line_point.geometry.coordinates
                ];
                const perpendicular_line = turf.lineString(perpendicular_line_coords);
                const nearest_point_perpendicular = turf.nearestPointOnLine(perpendicular_line, third_point);

                const side_1_length = turf.distance(second_point, nearest_point_perpendicular);
                const perpendicular_point_2 = turf.rhumbDestination(first_point, side_1_length, perpendicular_bearing);

                let final_coordinates = [
                    first_point,
                    second_point,
                    nearest_point_perpendicular,
                    perpendicular_point_2,
                    first_point
                ];
                final_coordinates = final_coordinates.map(pt => {
                    return new Point(pt.geometry.coordinates).transform('EPSG:4326', 'EPSG:3857').getCoordinates();
                });
                geometry.setCoordinates([final_coordinates]);
            }
            return geometry;
        }
    };
    keyDownHandler = e => {
        if (e.code == 'Backspace') {
            this.draw.removeLastPoint();
        }
    };

    pushTimeout = e => {
        if (this.mapObj.AppStore?.snapping_mode) {
            this.mapObj.snapFeature(e.feature);
        }

        setTimeout(() => {
            if (this.mapObj.AppStore.avoid_overlap) {
                avoidOverlap(e.feature);
            }
            undoRedoPush();
        }, 0);

        let currentTime = Date.now();
        this.mapObj.AppStore.setTrackTools({
            toolName: TOOL_NAMES.add_rectangle,
            timeTaken: currentTime - this.timeTaken, // in ms
            successfulOperation: true
        });

        this.mapObj.AppStore.setUpdateMapLegend();
        unByKey(this.listner);
        let overlay = appStore?.measurementOverlay;
        overlay.setPosition(undefined);
    };

    off() {
        this.mapObj.map.removeInteraction(this.draw);
        this.mapObj.map.removeInteraction(this.snap);
        this.draw && this.draw.un('drawend', this.pushTimeout);
        window.removeEventListener('keydown', this.keyDownHandler);
        // this.layer && this.layer.getSource().un('addfeature', undoRedoPush);
    }
}

export default DrawRectangle;
