import Draw from 'ol/interaction/Draw';
import Select from 'ol/interaction/Select';
import lineSegment from '@turf/line-segment';
import pointToLineDistance from '@turf/point-to-line-distance';
import { point as turfPoint, convertLength } from '@turf/helpers';
import { appStore, undoRedoPush } from '../MapInit';
import Polygon, { fromCircle } from 'ol/geom/Polygon';
import { Circle, LinearRing, MultiPolygon, Point } from 'ol/geom';

import { selectStyle } from '../MapBase';
import { Feature } from 'ol';
import { GEOMETRY_TYPES, GEO_JSON } from '../../../../Constants/MapConstant';
import { getPointResolution } from 'ol/proj';
import { TOOL_NAMES } from '../../../../Constants/Tool';
import { showArea } from '../../../../Utils/HelperFunctions';
import { unByKey } from 'ol/Observable';

const DEFAULT_RADIUS = 0.762; // it is in meters which is equal to 2.5 feet because 1ft=0.3048m
const TO_METERS = 1150;

class CircularHole {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.draw = null;
        this.defaultLimitFlag = false;
        this.select = null;
        this.layer = null;
        this.defaultGeometry = null;
        this.defaultRadius = null;
        this.features = [];
        this.timeTaken = 0;
    }

    init(id) {
        this.off();

        this.select = new Select({
            layers: this.getLayers(),
            style: selectStyle(id)
            // multi: true
        });

        this.select.setActive(false);

        const options = {
            type: 'Circle',
            dragVertexDelay: 0,
            clickTolerance: 12,
            features: this.select.getFeatures(),
            style: selectStyle(id),
            geometryFunction: this.geometryFunction,
            condition: e => {
                const mouseClick = e.originalEvent.which;
                const feature = this.getFeatureAtPixel(e.pixel);
                if (mouseClick == 2) {
                    return false;
                } else if (mouseClick == 3) {
                    this.defaultLimitFlag = false;
                    this.draw.abortDrawing();
                    return false;
                } else if (!feature) {
                    return false;
                } else if (feature && feature.getGeometry().getType() != GEOMETRY_TYPES.POLYGON) {
                    return false;
                }
                return true;
            }
        };

        this.draw = new Draw(options);
        this.mapObj.map.addInteraction(this.select);
        this.mapObj.map.addInteraction(this.draw);
        this.draw.on('drawend', this.drawEnd);
        this.draw.on('drawstart', this.drawStart);
        this.mapObj.map.on('pointermove', this.handlePointerMove);
        window.addEventListener('keydown', this.keyDownHandler);
    }

    off() {
        this.mapObj.map.removeInteraction(this.draw);
        this.mapObj.map.removeInteraction(this.select);
        this.mapObj.map.un('pointermove', this.handlePointerMove);
        this.draw && this.draw.un('drawstart', this.drawStart);
        this.draw && this.draw.un('drawend', this.drawEnd);
        window.removeEventListener('keydown', this.keyDownHandler);
    }

    keyDownHandler = e => {
        if (e.code == 'Backspace') {
            this.draw.removeLastPoint();
        } else if (e.code == 'Space') {
            this.draw.finishDrawing();
        }
    };

    handleDblClick = e => {
        // const layer = this.getLayerAtPixel(e.pixel);
        // && layer && layer.get('id') != PARCEL
        const feature = this.getFeatureAtPixel(e.pixel);
        if (feature && feature.getGeometry().getType() == GEOMETRY_TYPES.POLYGON) {
            if (this.defaultGeometry) {
                // this.defaultGeometry.setRadius(DEFAULT_RADIUS);
                this.draw.dispatchEvent({ type: 'drawend', feature: new Feature(this.defaultGeometry) });
            }
        }
    };

    handlePointerMove = e => {
        const feature = this.getFeatureAtPixel(e.pixel);
        if (feature && feature.getGeometry().getType() == GEOMETRY_TYPES.POLYGON) {
            const layers = this.getLayers();
            const features = this.mapObj.map.getFeaturesAtPixel(e.pixel, {
                layerFilter: function (l) {
                    return layers.indexOf(l) >= 0;
                }
            });
            this.features = features;
            this.select.setActive(true);
            this.draw.setActive(true);
        } else {
            this.features = [];
            this.select.setActive(false);
            this.draw.setActive(false);
        }
    };

    drawStart = e => {
        this.timeTaken = Date.now();
        const circularFeature = e.feature;
        const center = circularFeature.getGeometry().getCenter();
        this.maxRadius = this.getMaxRadius(center);

        let overlay = appStore?.measurementOverlay;

        let sketch = e.feature;
        this.listner = sketch.getGeometry().on('change', event => {
            const geom = event.target;

            if (geom instanceof Circle) {
                showArea(geom, overlay);
            }
        });
    };

    drawEnd = e => {
        const circularFeature = e.feature;
        const circularGeom = circularFeature.getGeometry();
        if (!circularGeom.getRadius()) {
            return;
        }

        const features = this.getSelectedFeatures();
        let intersectFeature = false;
        for (const feature of features) {
            if (feature) {
                const poly = feature.getGeometry();
                const innerRing = fromCircle(circularGeom, 360);
                const coords = innerRing.getCoordinates()[0];
                for (const coord of coords) {
                    if (!poly.intersectsCoordinate(coord)) {
                        intersectFeature = true;
                        break;
                    }
                }

                if (intersectFeature) {
                    continue;
                }

                const linearRing = new LinearRing(coords);
                if (poly.getType() == 'Polygon') {
                    poly.appendLinearRing(linearRing);
                } else if (poly.getType() == 'MultiPolygon') {
                    const newGeom = new MultiPolygon([]);
                    for (let i = 0, pi; (pi = poly.getPolygon(i)); i++) {
                        pi.appendLinearRing(new LinearRing(linearRing));
                        newGeom.appendPolygon(pi);
                    }
                    feature.setGeometry(newGeom);
                }
            }
        }
        undoRedoPush();
        let currentTime = Date.now();
        this.mapObj.AppStore.setTrackTools({
            toolName: TOOL_NAMES.add_circular_hole,
            timeTaken: currentTime - this.timeTaken, // in ms
            successfulOperation: true
        });
        this.select.getFeatures().clear();
        this.features = [];
        this.defaultLimitFlag = false;
        unByKey(this.listner);
        let overlay = appStore?.measurementOverlay;
        overlay.setPosition(undefined);
    };

    getLayers = () => {
        // return [this.layer];
        return this.mapObj.map
            .getLayers()
            .getArray()
            .filter(l => !this.mapObj.isIgnorableLayer(l) && l.getVisible());
    };

    getLayerAtPixel = px => {
        return this.mapObj.map.forEachFeatureAtPixel(px, (f, l) => l);
    };

    getFeatureAtPixel = px => {
        return this.mapObj.map.forEachFeatureAtPixel(px, (f, l) => {
            if (l && !this.mapObj.isIgnorableLayer(l)) {
                return f;
            }
            return null;
        });
    };

    getSelectedFeature = () => {
        return this.select.getFeatures().item(0);
    };

    getSelectedFeatures = () => {
        return this.features;
        // return this.select.getFeatures().getArray();
    };

    getResolutionFactor = () => {
        const map = this.mapObj.map;
        const view = map.getView();
        const projection = view.getProjection();
        const resolutionAtEquator = view.getResolution();
        const center = map.getView().getCenter();
        const pointResolution = getPointResolution(projection, resolutionAtEquator, center);
        return resolutionAtEquator / pointResolution;
    };

    convertToFeet = radius => {
        const resolutionFactor = this.getResolutionFactor();
        return (radius / METERS_PER_UNIT['ft']) * resolutionFactor;
    };

    setDefaultRadius = () => {
        this.defaultRadius = parseInt(this.convertToFeet(DEFAULT_RADIUS));
    };

    getMaxRadius = center => {
        const features = this.getSelectedFeatures();
        const fc = GEO_JSON.writeFeaturesObject(features);
        const point = turfPoint(new Point(center).transform('EPSG:3857', 'EPSG:4326').getCoordinates());
        let distances = [];
        for (const poly of fc.features) {
            if (poly) {
                const segments = lineSegment(poly);
                segments.features.forEach(lineSeg => {
                    let distance = pointToLineDistance(point, lineSeg);
                    distances.push(distance);
                });
            }
        }
        if (distances.length) {
            const maxRadius = Math.min(...distances);
            return maxRadius * TO_METERS;
        }

        return null;
    };

    calculateRadius(coordinates) {
        const center = coordinates[0];
        const last = coordinates[coordinates.length - 1];
        const dx = center[0] - last[0];
        const dy = center[1] - last[1];
        return Math.sqrt(dx * dx + dy * dy);
    }

    geometryFunction = (coordinates, geometry) => {
        let radius = DEFAULT_RADIUS;
        if (!geometry) {
            geometry = new Circle(coordinates[0], radius);
            this.defaultGeometry = geometry;
        } else {
            radius = this.calculateRadius(coordinates);
            if (radius > DEFAULT_RADIUS) {
                this.defaultLimitFlag = true;
            }

            if (this.defaultLimitFlag) {
                if (this.maxRadius && radius >= this.maxRadius) {
                    radius = this.maxRadius;
                }

                geometry.setRadius(radius);
            }
        }
        return geometry;
    };
}

export default CircularHole;
