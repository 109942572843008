import React from 'react';
import { Collapse as UICollapse } from 'antd';

const Collapse = props => {
    const { children } = props;
    return <UICollapse {...props}>{children}</UICollapse>;
};

export default Object.assign(Collapse, {
    Panel: UICollapse.Panel
});
