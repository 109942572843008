import React from 'react';
import Icon from '@ant-design/icons';
import { appStore, mapObj, selectTool } from './MapInit';
import { TOOL_MODES, TOOL_TYPE } from '../../../Constants/Tool';

function Tool(title, type, geom, sk, icon, toolClass, ordering, multiMode = false) {
    this.title = title;
    this.type = type;
    this.allowed_geometries = geom;
    this.shortcut_key = sk;
    this.icon = props => {
        return <Icon style={{ fontSize: 23 }} component={icon} />;
    };
    this.ordering = ordering;
    this.multi_mode = multiMode;
    this.modes = multiMode ? TOOL_MODES[type] : [];
    this.action = e => {
        // e.preventDefault();
        appStore.setCurrentTool(type);
    };

    this.getObject = () => {
        if (!this.toolObj && toolClass && type !== TOOL_TYPE.PAN) {
            this.toolObj = type === TOOL_TYPE.SELECT ? selectTool : new toolClass(mapObj);
        }
        return this.toolObj;
    };
}

export default Tool;

export function ToolMode(id, title, icon) {
    this.id = id;
    this.title = title;
    this.icon = props => {
        return <Icon style={{ fontSize: 23 }} component={icon} />;
    };
}
