import React from 'react';
import { Modal as AntdModal } from 'antd';

const Modal = props => {
    const { children } = props;
    return <AntdModal {...props}>{children}</AntdModal>;
};

export default Object.assign(Modal, {
    useModal: AntdModal.useModal,
    warning: AntdModal.warning
});
