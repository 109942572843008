import { DragBox, Select, Translate } from 'ol/interaction';
import { undoRedoPush } from '../MapInit';
import { avoidOverlap, getTurfFeature } from '../../../../Utils/MapHelper';
import { EVENT_TYPE, KEY_CODE } from '../../../../Constants/MapConstant';
import { TOOL_NAMES } from '../../../../Constants/Tool';
import { all, click, shiftKeyOnly } from 'ol/events/condition';
import { selectStyle } from '../MapBase';
import { message } from 'antd';

class CopyAndMoveTool {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.translate = null;
        this.hitTolerance = 10;
        this.isTranslating = false;
        this.isoverlap = false;
        this.finalFeature = null;
        this.outsideMapevent = null;
        this.timeTaken = 0;
        this.select = null;
        this.drag = null;
    }

    emptyFeatureArray() {
        if (this.select) {
            this.select.getFeatures().clear();
        }
    }

    init(id) {
        this.off();
        this.layer = this.mapObj.map.getLayerById(id);

        this.translate = new Translate({ layers: [this.layer], hitTolerance: this.hitTolerance });
        this.drag = new DragBox();
        this.select = new Select({
            layers: [this.layer],
            multi: true,
            // condition: all(shiftKeyOnly, click),
            style: selectStyle(id)
        });

        this.drag.on('boxdrag', () => {
            this.emptyFeatureArray();
        });

        this.drag.on('boxend', () => {
            let extent = this.drag.getGeometry().getExtent();
            this.layer.getSource().forEachFeatureIntersectingExtent(extent, feature => {
                this.select.getFeatures().push(feature);
            });
            message.info({
                content: `${this.select.getFeatures().getLength()} features selected.`,
                style: {
                    marginTop: '4vh'
                },
                duration: 0,
                key: 'selection-tool'
            });
            if (!this.select.getFeatures().getLength()) {
                message.destroy('selection-tool');
            }
        });

        this.translate = new Translate({
            layers: [this.layer],
            hitTolerance: this.hitTolerance,
            features: this.select.getFeatures()
        });

        this.translate.handleEvent = e => {
            this.outsideMapevent = e;
            if (e.originalEvent.which === KEY_CODE.LEFT_CLICK && e.type === EVENT_TYPE.CLICK && !this.isTranslating) {
                this.translate.handleDownEvent(e);
            } else if (
                e.originalEvent.which === KEY_CODE.LEFT_CLICK &&
                e.type === EVENT_TYPE.CLICK &&
                this.isTranslating
            ) {
                this.onLeftClick();
            } else if (e.type === EVENT_TYPE.POINTER_MOVE) {
                this.translate.handleMoveEvent(e);
                this.translate.handleDragEvent(e);
            }
            // else if (e.originalEvent.which === KEY_CODE.RIGHT_CLICK) {
            //     this.translate.handleUpEvent(e);

            //     this.isTranslating = false;
            //     this.isoverlap = false;
            // }

            return true;
        };
        this.mapObj.map.addInteraction(this.translate);
        this.mapObj.map.addInteraction(this.select);
        this.mapObj.map.addInteraction(this.drag);
        this.mapObj.map.on('pointermove', this.pointerMoveHandler);
        this.translate.on('translateend', this.onTranslateEnd);
        this.translate.on('translatestart', this.onTranslateStart);
    }

    onTranslateStart = e => {
        this.mapObj.map.removeInteraction(this.select);
        this.mapObj.map.removeInteraction(this.drag);
        this.timeTaken = Date.now();
        this.isTranslating = true;
        this.finalFeature = e.features.getArray();
        this.selected_feature = e.features.getArray().map(f => f.clone());
        this.layer.getSource().addFeatures(this.selected_feature);
    };

    onLeftClick = () => {
        this.selected_feature = this.finalFeature.map(f => f.clone());

        this.layer.getSource().addFeatures(this.selected_feature);
        this.layer.getSource().removeFeatures(this.finalFeature);

        if (this.mapObj.AppStore.avoid_overlap && this.isTranslating) {
            this.selected_feature?.forEach(f => {
                avoidOverlap(f);
            });
        }
        this.layer.getSource().addFeatures(this.finalFeature);

        undoRedoPush();
    };

    onTranslateEnd = e => {
        const finalFeature = e.features.getArray();
        this.isoverlap = true;
        if (this.isoverlap) {
            this.layer.getSource().removeFeatures(finalFeature);
        } else {
            undoRedoPush();
        }

        let currentTime = Date.now();
        this.mapObj.AppStore.setTrackTools({
            toolName: TOOL_NAMES.copy_and_move,
            timeTaken: currentTime - this.timeTaken, // in ms
            successfulOperation: true
        });
        this.mapObj.map.addInteraction(this.select);
        this.mapObj.map.addInteraction(this.drag);
        this.mapObj.AppStore.setUpdateMapLegend();
    };
    pointerMoveHandler = e => {
        let canvas = document.getElementsByTagName('canvas')[0];
        if (canvas) {
            canvas.style.cursor = '';

            this.mapObj.map.forEachFeatureAtPixel(
                e.pixel,
                (feature, layer) => {
                    if (feature) {
                        canvas.style.cursor = 'move';
                    }
                },
                {
                    layerFilter: layer_candidate => {
                        return this.layer == layer_candidate;
                    },
                    hitTolerance: this.hitTolerance
                }
            );
        }
    };
    off() {
        //edge case handling
        let canvas = document.getElementsByTagName('canvas')[0];
        this.mapObj.map.removeInteraction(this.select);
        this.mapObj.map.removeInteraction(this.drag);
        if (canvas) canvas.style.cursor = '';
        this.translate?.handleUpEvent(this.outsideMapevent);
        this.isTranslating = false;
        this.isoverlap = false;

        this.mapObj.map.un('pointermove', this.pointerMoveHandler);
        this.translate && this.mapObj.map.removeInteraction(this.translate);
        this.translate && this.translate.un('translateend', undoRedoPush);
    }
}

export default CopyAndMoveTool;
