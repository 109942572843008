import { Draw } from 'ol/interaction';
import { drawStyle } from '../MapBase';
import { appStore, undoRedoPush } from '../MapInit';
import { TOOL_NAMES } from '../../../../Constants/Tool';
import { showArea, showLength } from '../../../../Utils/HelperFunctions';
import { unByKey } from 'ol/Observable';
import { LineString } from 'ol/geom';

export const DRAW_MODES = {
    ONE_CLICK: 'one-click-draw',
    FREEHAND: 'freehand-draw'
};

const DEFAULT_MODE = DRAW_MODES.ONE_CLICK;

class DrawPolyline {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.draw = null;
        this.snap = null;
        this.mode = DEFAULT_MODE;
        this.timeTaken = 0;
    }

    init(id, mode = DEFAULT_MODE) {
        this.off();
        this.mode = mode;
        const layer = this.mapObj.map.getLayerById(id);
        this.layer = layer;
        if (layer) {
            const source = layer.getSource();

            this.draw = new Draw({
                freehand: this.mode === DRAW_MODES.FREEHAND,
                source: source,
                type: 'LineString',
                style: drawStyle(id),
                dragVertexDelay: 0,
                snapTolerance: 1,
                condition: e => {
                    const mouseClick = e.originalEvent.which;
                    if (mouseClick == 3 || mouseClick == 2) {
                        return false;
                    }
                    return true;
                }
            });
            this.mapObj.map.addInteraction(this.draw);
            this.snap = this.mapObj.getSnap();
            this.snap.forEach(snap => {
                this.mapObj.map.addInteraction(snap);
            });
            this.draw.on('drawstart', this.onDrawStart);
            this.draw.on('drawend', this.onDrawEnd);
            window.addEventListener('keydown', this.keyDownHandler);
        }
    }

    onDrawStart = e => {
        this.timeTaken = Date.now();
        this.mode === DRAW_MODES.FREEHAND &&
            this.snap.forEach(snap => {
                this.mapObj.map.removeInteraction(snap);
            });

        let overlay = appStore?.measurementOverlay;

        let sketch = e.feature;
        this.listner = sketch.getGeometry().on('change', event => {
            const geom = event.target;

            if (geom instanceof LineString) {
                showLength(geom, overlay);
            }
        });
    };

    keyDownHandler = e => {
        if (e.code == 'Backspace') {
            this.draw.removeLastPoint();
        } else if (e.code == 'Space') {
            this.draw.finishDrawing();
        }
    };

    onDrawEnd = e => {
        this.mode === DRAW_MODES.FREEHAND &&
            this.snap.forEach(snap => {
                this.mapObj.map.addInteraction(snap);
            });
        setTimeout(() => {
            undoRedoPush();
        }, 0);

        let currentTime = Date.now();
        this.mapObj.AppStore.setTrackTools({
            toolName: TOOL_NAMES.draw_polyline,
            timeTaken: currentTime - this.timeTaken, // in ms
            successfulOperation: true
        });

        this.mapObj.AppStore.setUpdateMapLegend();

        unByKey(this.listner);
        let overlay = appStore?.measurementOverlay;
        overlay.setPosition(undefined);
    };

    off() {
        this.mapObj.map.removeInteraction(this.draw);
        this.mapObj.map.removeInteraction(this.snap);
        this.draw && this.draw.un('drawend', this.onDrawEnd);
        window.removeEventListener('keydown', this.keyDownHandler);
        // this.layer && this.layer.getSource().un('addfeature', undoRedoPush);
    }
}

export default DrawPolyline;
