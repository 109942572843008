import React from 'react';
import { Dropdown as UIDropwdown } from 'antd';

const Dropdown = props => {
    return <UIDropwdown {...props}>{props.children}</UIDropwdown>;
};

export default Object.assign(Dropdown, {
    Button: UIDropwdown.Button
});
