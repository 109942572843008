// export const message = () => {
//     return message
// }

// returns an object, where all the og messages are defined.

import { message as UIMessage } from 'antd';

// should be a function, and also need to be object.

const message = data => {
    return () => UIMessage(data);
};

export default Object.assign(message, {
    success: UIMessage.success,
    warning: UIMessage.warning,
    error: UIMessage.error,
    info: UIMessage.info,
    warn: UIMessage.warn,
    loading: UIMessage.loading,
    destroy: UIMessage.destroy
});
