import {
    DUMMY_PARCEL,
    SOURCE_TYPE,
    CARTO_ROLES_ENUM,
    LAYER,
    LAYER_DUMMY_JOB,
    EXTENSION_TIMER_COUNT
} from './MapConstant';

const {
    LAWN,
    CANOPY,
    BEDS,
    LAWN_EDGE,
    ROADWAYS,
    MULCH_BED,
    GRAVEL_BED,
    DRIVEWAY,
    PARCEL: EDITABLE_PARCEL,
    TREE,
    BUILDING_FOOTPRINT,
    MOWER_60,
    MOWER_48,
    MOWER_36,
    MOWER_21,
    PONDS,
    PALM_TREE,
    TOTAL_TURF,
    SECONDARY_MOW,
    PUBLIC_SIDEWALK,
    PRIVATE_SIDEWALK,
    LOT_AREA,
    WATERBODY,
    NATIVE_AREA,
    SPORTS_FIELD,
    PRIMARY_MOW,
    LOADING_AREA,
    LINE_TRIM,
    TREE_WITH_RING,
    TREE_WITHOUT_RING,
    RETENTION_POND,
    GREEN,
    FRINGE,
    ROUGH,
    FAIRWAY,
    TEE_BOX,
    CART_PATH,
    BUNKER,
    TENNIS_COURT,
    POTHOLE
} = LAYER;

export const SOURCE_TYPE_DATA = {
    [SOURCE_TYPE.RESIDENTIAL]: {
        dummy_layers: [LAWN, BEDS, LOADING_AREA],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.RESIDENTIAL_QA]: {
        dummy_layers: [LINE_TRIM, TREE_WITH_RING, TREE_WITHOUT_RING, RETENTION_POND, PRIMARY_MOW, SECONDARY_MOW],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.RESIDENTIAL_RAPID]: {
        dummy_layers: [LAWN, BEDS, EDITABLE_PARCEL, CANOPY, BUILDING_FOOTPRINT],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.RESIDENTIAL_RAPID_TRAIN]: {
        dummy_layers: [LAWN, BEDS, EDITABLE_PARCEL, CANOPY, BUILDING_FOOTPRINT],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.FALCON]: {
        dummy_layers: [TREE, LAWN_EDGE, MOWER_60, MOWER_48, MOWER_36, MOWER_21],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.FALCON_TRAIN]: {
        dummy_layers: [GREEN, FRINGE, ROUGH, FAIRWAY, TEE_BOX, CART_PATH, BUNKER, TENNIS_COURT, POTHOLE],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.FALCON_QA]: {
        dummy_layers: [MULCH_BED, TREE, ROADWAYS],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.FMS_QC]: {
        dummy_layers: [MULCH_BED, TREE, ROADWAYS, LAWN, DRIVEWAY, GRAVEL_BED],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.FMS_QR_BACKUP]: {
        dummy_layers: [MULCH_BED, TREE, ROADWAYS, LAWN, DRIVEWAY, GRAVEL_BED, WATERBODY, NATIVE_AREA, SPORTS_FIELD],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.FMS_RECYCLE_DATA]: {
        dummy_layers: [MULCH_BED, TREE, ROADWAYS, LAWN, DRIVEWAY, GRAVEL_BED, WATERBODY, NATIVE_AREA, SPORTS_FIELD],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.FMS_QR]: {
        dummy_layers: [PONDS, PALM_TREE, TOTAL_TURF, PRIVATE_SIDEWALK, PUBLIC_SIDEWALK, LOT_AREA],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.FMS_TRAIN]: {
        dummy_layers: [PONDS, PALM_TREE, TOTAL_TURF, PRIVATE_SIDEWALK, PUBLIC_SIDEWALK, LOT_AREA],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.FMS_DUMMY_IMAGES]: {
        dummy_layers: [PONDS, PALM_TREE, TOTAL_TURF, PRIVATE_SIDEWALK, PUBLIC_SIDEWALK, LOT_AREA],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.FMS]: {
        dummy_layers: [PONDS, PALM_TREE, TOTAL_TURF, PRIVATE_SIDEWALK, PUBLIC_SIDEWALK],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.FMS_QA]: {
        dummy_layers: [PONDS, PALM_TREE, TOTAL_TURF, PRIVATE_SIDEWALK, PUBLIC_SIDEWALK],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.FMS_DUMMY_IMAGE]: {
        dummy_layers: [PONDS, PALM_TREE, TOTAL_TURF, PRIVATE_SIDEWALK, PUBLIC_SIDEWALK],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    },
    [SOURCE_TYPE.FMS_DUMMY_IMAGE_QA]: {
        dummy_layers: [PONDS, PALM_TREE, TOTAL_TURF, PRIVATE_SIDEWALK, PUBLIC_SIDEWALK, LOT_AREA],
        getJobs: function () {
            return this.dummy_layers.map(l => LAYER_DUMMY_JOB[l]);
        }
    }
};

export const PING_JOB_TIME = 2000;
export const PING_INTERVAL_KEY = 'pingInterval';
export const SAVE_INTERVAL_KEY = 'saveInterval';

export const IMAGE_BOUNDS_BASE_LAYER_TYPE = 2;
export const IMAGE_TILE_LAYER_TYPE = 1;
export const SHIFT_COMMENT = 1;
export const CORRECTION_COMMENT = 2;
export const OBLIQUE_IMAGE_ORIENATION_MAP = {
    1: 'NORTH',
    2: 'SOUTH',
    3: 'EAST',
    4: 'WEST'
};

export const EXTENSION_TIME = 1800;

// Only these carto roles will be allowed to call active_work_items api irrespective of them having a job.
export const ALLOWED_CARTO_ROLES = [CARTO_ROLES_ENUM.QA, CARTO_ROLES_ENUM.QC, CARTO_ROLES_ENUM.TRAINEE];

export const MEAL_BREAK_TIME = 1800;
export const SHORT_BREAK_TIME = 600;
export const IDLE_TIME = 900;
export const MODAL_IDLE_TIME = 300000;
export const MEAL_BREAK = 1;
export const SHORT_BREAK = 2;
export const HOLD_BREAK = 3;

export const FIVE_STAR_RATING = 5;
