import { appStore, offAllTools } from '../MainPage/Annotation/OlMap/MapInit';
import { SNIPPING_MODES } from '../MainPage/Annotation/OlMap/Tools/LineSnippingTool';
import { IterableObject } from '../Utils/HelperFunctions';
import Tool, { ToolMode } from '../MainPage/Annotation/OlMap/Tool';
import Action from '../MainPage/Annotation/OlMap/Action';
import { GEOMETRY_TYPES } from './MapConstant';

import DrawPoint from '../MainPage/Annotation/OlMap/Tools/DrawPoint';
import DrawPolyline, { DRAW_MODES } from '../MainPage/Annotation/OlMap/Tools/DrawPolyline';
import DrawPolygon from '../MainPage/Annotation/OlMap/Tools/DrawPolygon';
import DrawRectangle from '../MainPage/Annotation/OlMap/Tools/DrawRectangle';
import CircularHole from '../MainPage/Annotation/OlMap/Tools/CircularHole';
import CircularFill from '../MainPage/Annotation/OlMap/Tools/CircularFill';
import SplitLine from '../MainPage/Annotation/OlMap/Tools/SplitLine';
import DrawHoles from '../MainPage/Annotation/OlMap/Tools/DrawHoles';
import FillRing from '../MainPage/Annotation/OlMap/Tools/FillRing';
import MoveTool from '../MainPage/Annotation/OlMap/Tools/MoveTool';
import SelectTool from '../MainPage/Annotation/OlMap/Tools/SelectTool';
import ModifyEdge from '../MainPage/Annotation/OlMap/Tools/ModifyEdge';
import RemoveHole from '../MainPage/Annotation/OlMap/Tools/RemoveHole';
import CopyTool from '../MainPage/Annotation/OlMap/Tools/CopyTool';
import LineSnippingTool from '../MainPage/Annotation/OlMap/Tools/LineSnippingTool';
import MeasureTool from '../MainPage/Annotation/OlMap/Tools/MeasureTool';
import ReshapePolygon from '../MainPage/Annotation/OlMap/Tools/ReshapePolygon';
import SplitPoly from '../MainPage/Annotation/OlMap/Tools/CutPolygon';
import ModifySegment from '../MainPage/Annotation/OlMap/Tools/ModifySegment';
import CopyAndMoveTool from '../MainPage/Annotation/OlMap/Tools/CopyAndMoveTool';
import CommentTool from '../MainPage/Annotation/OlMap/Tools/CommentTool';

import Clip from '../MainPage/Annotation/OlMap/Tools/Actions/Clip';
import CutCopyPaste from '../MainPage/Annotation/OlMap/Tools/Actions/CutCopyPaste';
import Merge from '../MainPage/Annotation/OlMap/Tools/Actions/Merge';
import RightClick from '../MainPage/Annotation/OlMap/Tools/Actions/RightClick';
import LawnBedMerge from '../MainPage/Annotation/OlMap/Tools/Actions/LawnBedMerge';
import Delete from '../MainPage/Annotation/OlMap/Tools/Actions/Delete';
import UndoRedo from '../MainPage/Annotation/OlMap/Tools/Actions/UndoRedo';

import { ReactComponent as DrawIcon } from '../MainPage/Annotation/OlMap/Tools/icons/draw.svg';
import { ReactComponent as DrawPolylineIcon } from '../MainPage/Annotation/OlMap/Tools/icons/draw_polyline.svg';
import { ReactComponent as DrawPointIcon } from '../MainPage/Annotation/OlMap/Tools/icons/draw_point.svg';
import { ReactComponent as DrawReactangleIcon } from '../MainPage/Annotation/OlMap/Tools/icons/three_point_rectangle.svg';
import { ReactComponent as CircularHoleIcon } from '../MainPage/Annotation/OlMap/Tools/icons/circular_hole.svg';
import { ReactComponent as CircularFillIcon } from '../MainPage/Annotation/OlMap/Tools/icons/circular_fill.svg';
import { ReactComponent as SelectIcon } from '../MainPage/Annotation/OlMap/Tools/icons/select.svg';
import { ReactComponent as SplitLineIcon } from '../MainPage/Annotation/OlMap/Tools/icons/split_line.svg';
import { ReactComponent as LineSnippingIcon } from '../MainPage/Annotation/OlMap/Tools/icons/line_snipping.svg';
import { ReactComponent as OneClickLineSnippingIcon } from '../MainPage/Annotation/OlMap/Tools/icons/one_click_line_snipping.svg';
import { ReactComponent as TwoClickLineSnippingIcon } from '../MainPage/Annotation/OlMap/Tools/icons/two_click_line_snipping.svg';
import { ReactComponent as HoleIcon } from '../MainPage/Annotation/OlMap/Tools/icons/hole.svg';
import { ReactComponent as FillRingIcon } from '../MainPage/Annotation/OlMap/Tools/icons/fill_ring.svg';
import { ReactComponent as DeleteIcon } from '../MainPage/Annotation/OlMap/Tools/icons/delete.svg';
import { ReactComponent as CutFeaturesIcon } from '../MainPage/Annotation/OlMap/Tools/icons/cut_features.svg';
import { ReactComponent as CopyIcon } from '../MainPage/Annotation/OlMap/Tools/icons/copy.svg';
import { ReactComponent as MeasureIcon } from '../MainPage/Annotation/OlMap/Tools/icons/measure.svg';
import { ReactComponent as PasteIcon } from '../MainPage/Annotation/OlMap/Tools/icons/paste.svg';
import { ReactComponent as SplitPolyIcon } from '../MainPage/Annotation/OlMap/Tools/icons/split.svg';
import { ReactComponent as ResizeIcon } from '../MainPage/Annotation/OlMap/Tools/icons/reshape.svg';
import { ReactComponent as MoveIcon } from '../MainPage/Annotation/OlMap/Tools/icons/move.svg';
import { ReactComponent as PanIcon } from '../MainPage/Annotation/OlMap/Tools/icons/pan.svg';
import { ReactComponent as EdgeIcon } from '../MainPage/Annotation/OlMap/Tools/icons/edge.svg';
import { ReactComponent as MergeIcon } from '../MainPage/Annotation/OlMap/Tools/icons/merge.svg';
import { ReactComponent as RemoveHoleIcon } from '../MainPage/Annotation/OlMap/Tools/icons/delete_ring.svg';
import { ReactComponent as RedoIcon } from '../MainPage/Annotation/OlMap/Tools/icons/redo.svg';
import { ReactComponent as UndoIcon } from '../MainPage/Annotation/OlMap/Tools/icons/undo.svg';
import { ReactComponent as CopyToolIcon } from '../MainPage/Annotation/OlMap/Tools/icons/copyicon.svg';
import { ReactComponent as ClippingToolIcon } from '../MainPage/Annotation/OlMap/Tools/icons/clipping_icon.svg';
import { ReactComponent as ModifySegmentIcon } from '../MainPage/Annotation/OlMap/Tools/icons/modify_segment.svg';
import { ReactComponent as LawnBedIcon } from '../MainPage/Annotation/OlMap/Tools/icons/fuse.svg';
import { ReactComponent as CopyAndMoveIcon } from '../MainPage/Annotation/OlMap/Tools/icons/copy_and_move.svg';
import { ReactComponent as CommentToolIcon } from '../MainPage/Annotation/OlMap/Tools/icons/comment_icon.svg';
import { ReactComponent as BufferIcon } from '../MainPage/Annotation/OlMap/Tools/icons/buffer.svg';
import BufferTool from '../MainPage/Annotation/OlMap/Tools/BufferTool';
const { POLYGON, POINT, LINESTRING } = GEOMETRY_TYPES;
const GEOMETRY_TYPES_ALL = [POLYGON, POINT, LINESTRING];
export const TOOL_TYPE = {
    DRAW: 'draw',
    DRAW_POINT: 'draw_point',
    DRAW_POLYLINE: 'draw_polyline',
    DRAW_RECTANGLE: 'draw_rectangle',
    CIRCULAR_HOLE: 'circular_hole',
    CIRCULAR_FILL: 'circular_fill',
    SPLIT_POLY: 'split_poly',
    SPLIT_LINE: 'split_line',
    HOLE: 'hole',
    FILL_RING: 'fill_ring',
    SELECT: 'select',
    RESIZE: 'resize',
    PAN: 'pan',
    EDGE: 'edge',
    REMOVE_HOLE: 'remove_hole',
    COPY_COORDS: 'copy_coords',
    LINE_SNIPPING: 'line_snipping_tool',
    MOVE: 'move',
    MEASURE: 'measure_tool',
    MODIFY_SEGMENT: 'modify_segment_tool',
    COPY_AND_MOVE: 'copy_and_move',
    DRAW_BUFFER: 'buffer_tool'
};

export const ACTION = {
    MERGE: 'merge',
    DELETE: 'delete',
    CUT: 'cut',
    COPY: 'copy',
    PASTE: 'paste',
    RIGHT_CLICK: 'right_click',
    CLIPPING: 'clipping',
    UNDO: 'undo',
    REDO: 'redo',
    LAWN_BED_MERGE: 'lawn_bed_merge',
    BUFFER: 'buffer_tool'
};

Object.setPrototypeOf(TOOL_TYPE, IterableObject.prototype);
Object.setPrototypeOf(ACTION, IterableObject.prototype);

export const TOOL_MODES = {
    [TOOL_TYPE.LINE_SNIPPING]: [
        new ToolMode(SNIPPING_MODES.ONE_CLICK, 'One Click Mode', OneClickLineSnippingIcon),
        new ToolMode(SNIPPING_MODES.TWO_CLICK, 'Two Click Mode', TwoClickLineSnippingIcon)
    ],
    [TOOL_TYPE.DRAW_POLYLINE]: [
        new ToolMode(DRAW_MODES.ONE_CLICK, 'One Click Mode', DrawPolylineIcon),
        new ToolMode(DRAW_MODES.FREEHAND, 'Freehand Mode', PanIcon)
    ]
};

export const TOOL_NAMES = {
    reshape_feature: 'Reshape Feature',
    split_feature: 'Split Features',
    select_features: 'Select Features',
    add_polygon: 'Add Polygon',
    add_ring: 'Add Ring',
    add_circular_hole: 'Add Circular Hole',
    pan_map: 'Pan Map',
    add_point: 'Add Point',
    add_rectangle: 'Add Rectangle',
    fill_ring: 'Fill Ring',
    circular_fill: 'Circular Fill',
    split_line: 'Split Line',
    draw_polyline: 'Add Polyline',
    line_snipping: 'Line Snipping',
    measure_tool: 'Measure Tool',
    modify_vertex: 'Modify Vertex',
    delete_ring: 'Delete Ring',
    move_feature: 'Move Feature',
    copy_coordinates: 'Copy Coordinates',
    modify_segment: 'Modify Segment',
    copy_and_move: 'Copy and Move Feature',
    merge_selected: 'Merge Selected',
    delete_selected: 'Delete Selected',
    clip_features: 'Clip Features',
    merge_beds_to_lawn: 'Merge Beds to Lawn',
    right_click: 'Right Click',
    undo: 'Undo',
    redo: 'Redo',
    buffer_tool: 'Buffer Tool',
    comment: 'Comment'
};

const TOOL_MAP = {
    [TOOL_TYPE.RESIZE]: new Tool(
        TOOL_NAMES.reshape_feature,
        TOOL_TYPE.RESIZE,
        [POLYGON],
        'r',
        ResizeIcon,
        ReshapePolygon,
        1
    ),
    [TOOL_TYPE.SPLIT_POLY]: new Tool(
        TOOL_NAMES.split_feature,
        TOOL_TYPE.SPLIT_POLY,
        [POLYGON],
        'x',
        SplitPolyIcon,
        SplitPoly,
        2
    ),
    [TOOL_TYPE.SELECT]: new Tool(
        TOOL_NAMES.select_features,
        TOOL_TYPE.SELECT,
        GEOMETRY_TYPES_ALL,
        's',
        SelectIcon,
        SelectTool,
        3
    ),
    [TOOL_TYPE.DRAW]: new Tool(TOOL_NAMES.add_polygon, TOOL_TYPE.DRAW, [POLYGON], 'a', DrawIcon, DrawPolygon, 6),
    [TOOL_TYPE.HOLE]: new Tool(TOOL_NAMES.add_ring, TOOL_TYPE.HOLE, [POLYGON], 'alt+r', HoleIcon, DrawHoles, 7),
    [TOOL_TYPE.CIRCULAR_HOLE]: new Tool(
        TOOL_NAMES.add_circular_hole,
        TOOL_TYPE.CIRCULAR_HOLE,
        [POLYGON],
        null,
        CircularHoleIcon,
        CircularHole,
        8
    ),
    [TOOL_TYPE.PAN]: new Tool(TOOL_NAMES.pan_map, TOOL_TYPE.PAN, GEOMETRY_TYPES_ALL, 'p', PanIcon, null, 9),
    [TOOL_TYPE.DRAW_POINT]: new Tool(
        TOOL_NAMES.add_point,
        TOOL_TYPE.DRAW_POINT,
        [POINT],
        null,
        DrawPointIcon,
        DrawPoint,
        11
    ),
    [TOOL_TYPE.DRAW_RECTANGLE]: new Tool(
        TOOL_NAMES.add_rectangle,
        TOOL_TYPE.DRAW_RECTANGLE,
        [POLYGON],
        null,
        DrawReactangleIcon,
        DrawRectangle,
        12
    ),
    [TOOL_TYPE.FILL_RING]: new Tool(
        TOOL_NAMES.fill_ring,
        TOOL_TYPE.FILL_RING,
        [POLYGON],
        'f',
        FillRingIcon,
        FillRing,
        13
    ),
    [TOOL_TYPE.CIRCULAR_FILL]: new Tool(
        TOOL_NAMES.circular_fill,
        TOOL_TYPE.CIRCULAR_FILL,
        [POLYGON],
        null,
        CircularFillIcon,
        CircularFill,
        14
    ),
    [TOOL_TYPE.SPLIT_LINE]: new Tool(
        TOOL_NAMES.split_line,
        TOOL_TYPE.SPLIT_LINE,
        [LINESTRING],
        null,
        SplitLineIcon,
        SplitLine,
        15
    ),
    // Multimode tools
    [TOOL_TYPE.DRAW_POLYLINE]: new Tool(
        TOOL_NAMES.draw_polyline,
        TOOL_TYPE.DRAW_POLYLINE,
        [LINESTRING, POLYGON],
        null,
        DrawPolylineIcon,
        DrawPolyline,
        10,
        true
    ),
    [TOOL_TYPE.LINE_SNIPPING]: new Tool(
        TOOL_NAMES.line_snipping,
        TOOL_TYPE.LINE_SNIPPING,
        [LINESTRING],
        null,
        LineSnippingIcon,
        LineSnippingTool,
        16,
        true
    ),
    [TOOL_TYPE.MEASURE]: new Tool(
        TOOL_NAMES.measure_tool,
        TOOL_TYPE.MEASURE,
        GEOMETRY_TYPES_ALL,
        null,
        MeasureIcon,
        MeasureTool,
        17
    ),
    [TOOL_TYPE.EDGE]: new Tool(
        TOOL_NAMES.modify_vertex,
        TOOL_TYPE.EDGE,
        GEOMETRY_TYPES_ALL,
        'v',
        EdgeIcon,
        ModifyEdge,
        21
    ),
    [TOOL_TYPE.REMOVE_HOLE]: new Tool(
        TOOL_NAMES.delete_ring,
        TOOL_TYPE.REMOVE_HOLE,
        [POLYGON],
        'shift+r',
        RemoveHoleIcon,
        RemoveHole,
        22
    ),
    [TOOL_TYPE.MOVE]: new Tool(
        TOOL_NAMES.move_feature,
        TOOL_TYPE.MOVE,
        GEOMETRY_TYPES_ALL,
        'm',
        MoveIcon,
        MoveTool,
        20
    ),
    [TOOL_TYPE.COPY_COORDS]: new Tool(
        TOOL_NAMES.copy_coordinates,
        TOOL_TYPE.COPY_COORDS,
        GEOMETRY_TYPES_ALL,
        null,
        CopyToolIcon,
        CopyTool,
        23
    ),
    [TOOL_TYPE.MODIFY_SEGMENT]: new Tool(
        TOOL_NAMES.modify_segment,
        TOOL_TYPE.MODIFY_SEGMENT,
        [POLYGON],
        'e',
        ModifySegmentIcon,
        ModifySegment,
        24
    ),

    [TOOL_TYPE.COPY_AND_MOVE]: new Tool(
        TOOL_NAMES.copy_and_move,
        TOOL_TYPE.COPY_AND_MOVE,
        GEOMETRY_TYPES_ALL,
        'ctrl+m',
        CopyAndMoveIcon,
        CopyAndMoveTool,
        20
    ),
    [ACTION.BUFFER]: new Action(
        TOOL_NAMES.buffer_tool,
        ACTION.BUFFER,
        GEOMETRY_TYPES_ALL,
        'ctrl+b',
        BufferIcon,
        BufferTool,
        4
    ),
    [ACTION.MERGE]: new Action(TOOL_NAMES.merge_selected, ACTION.MERGE, [POLYGON], 'c', MergeIcon, Merge, 4),
    [ACTION.DELETE]: new Action(
        TOOL_NAMES.delete_selected,
        ACTION.DELETE,
        GEOMETRY_TYPES_ALL,
        'd',
        DeleteIcon,
        Delete,
        5
    ),
    // [ACTION.CUT]: new Action(
    //     'Cut Selected',
    //     ACTION.CUT,
    //     GEOMETRY_TYPES_ALL,
    //     'ctrl+x',
    //     CutFeaturesIcon,
    //     CutCopyPaste,
    //     18
    // ),
    // [ACTION.COPY]: new Action('Copy Selected', ACTION.COPY, GEOMETRY_TYPES_ALL, 'ctrl+c', CopyIcon, CutCopyPaste),
    // [ACTION.PASTE]: new Action('Paste', ACTION.PASTE, GEOMETRY_TYPES_ALL, 'ctrl+v', PasteIcon, CutCopyPaste, 19),
    [ACTION.CLIPPING]: new Action(
        TOOL_NAMES.clip_features,
        ACTION.CLIPPING,
        GEOMETRY_TYPES_ALL,
        'ctrl+q',
        ClippingToolIcon,
        Clip,
        25
    ),
    [ACTION.LAWN_BED_MERGE]: new Action(
        TOOL_NAMES.merge_beds_to_lawn,
        ACTION.LAWN_BED_MERGE,
        [POLYGON],
        null,
        LawnBedIcon,
        LawnBedMerge,
        26
    ),
    [ACTION.RIGHT_CLICK]: new Action(TOOL_NAMES.right_click, ACTION.RIGHT_CLICK, [POLYGON], '', null, RightClick),
    [ACTION.UNDO]: new Action(TOOL_NAMES.undo, ACTION.UNDO, GEOMETRY_TYPES_ALL, 'ctrl+z', UndoIcon, UndoRedo, 100),
    [ACTION.REDO]: new Action(TOOL_NAMES.redo, ACTION.REDO, GEOMETRY_TYPES_ALL, 'ctrl+y', RedoIcon, UndoRedo, 101)
};

export const SHORTCUT_TOOL_MAPPING = {
    r: 'Reshape Feature',
    x: 'Split Features',
    s: 'Select Features',
    a: 'Add Polygon',
    'alt+r': 'Add Ring',
    p: 'Pan Map',
    f: 'Fill Ring',
    v: 'Modify Vertex',
    'shift+r': 'Delete Ring',
    m: 'Move Feature',
    e: 'Modify Segment',
    'ctrl+m': 'Copy and Move Feature',
    //TODO: Should we also add action?
    c: 'Merge Selected',
    d: 'Delete Selected',
    'ctrl+q': 'Clip Features',
    'ctrl+z': 'Undo',
    'ctrl+y': 'Redo'
};

export default TOOL_MAP;

export const GENERAL_KEYS = ['esc'];
export const SHORTCUT_ACTION_MAP = {
    esc: () => {
        offAllTools();
        appStore.setCurrentTool(TOOL_TYPE.PAN);
        if (document.getElementById('select-attr')) {
            document.getElementById('select-attr').style.display = 'none';
        }
    }
};
