import React from 'react';
import { Input as AntdInput } from 'antd';

const Input = props => {
    return <AntdInput {...props} />;
};

export default Object.assign(Input, {
    TextArea: AntdInput.TextArea,
    Search: AntdInput.Search,
    Group: AntdInput.Group,
    Password: AntdInput.Password
});
