import React from 'react';
import { Radio as AntdRadio } from 'antd';

const Radio = props => {
    const { children } = props;
    return <AntdRadio {...props}>{children}</AntdRadio>;
};

export default Object.assign(Radio, {
    Group: AntdRadio.Group
});
