import { CONTEXT_MENU } from '../Constants/MapConstant';
import { ACTION } from '../Constants/Tool';
import { executeAction } from '../MainPage/Annotation/OlMap/MapInit';
import { ContextMenuFn } from './ContextMenuFunction';

export const contextMenuFn_1 = (mapBase, e) => {
    return ContextMenuFn(mapBase, e);
};

export const contextMenuFn_2 = (mapBase, e) => {
    return executeAction(ACTION.RIGHT_CLICK, e);
};

export const CONTEXT_MENU_FUNCTION = {
    [CONTEXT_MENU.FUNCTION_1]: contextMenuFn_1,
    [CONTEXT_MENU.FUNCTION_2]: contextMenuFn_2
};
