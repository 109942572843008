import { Draw } from 'ol/interaction';
import { drawStyle } from '../MapBase';
import { appStore, undoRedoObj, undoRedoPush } from '../MapInit';
import { TOOL_NAMES } from '../../../../Constants/Tool';
import { clearCommentLayer } from '../../components/CommentsList/CommentUtils';

class DrawPoint {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.draw = null;
        this.snap = null;
        this.timeTaken = 0;
    }

    init(id) {
        this.off();
        const layer = this.mapObj.map.getLayerById(id);
        this.layer = layer;
        if (layer) {
            const source = layer.getSource();
            this.draw = new Draw({
                source: source,
                type: 'Point',
                style: drawStyle(id),
                dragVertexDelay: 0,
                snapTolerance: 1,
                condition: e => {
                    const mouseClick = e.originalEvent.which;
                    if (mouseClick == 3 || mouseClick == 2) {
                        return false;
                    }
                    return true;
                }
            });
            this.mapObj.map.addInteraction(this.draw);
            this.snap = this.mapObj.getSnap();
            this.draw.on('drawend', e => {
                const coordinate = e.feature.getGeometry().getCoordinates();
                this.pushTimeout();
                if (appStore.comment_action_selected) {
                    // Remove all features from comment layer
                    clearCommentLayer();

                    this.popup = appStore.overlay;
                    this.popup.setPosition([coordinate[0], coordinate[1]]);
                    const view = this.mapObj?.map?.getView();
                    view.animate({
                        center: coordinate,
                        duration: 500
                    });
                }
                this.mapObj.AppStore.setUpdateMapLegend();
            });
            window.addEventListener('keydown', this.keyDownHandler);
            // this.layer.getSource().on('addfeature', undoRedoPush);
        }
    }
    keyDownHandler = e => {
        if (e.key == 'Backspace') {
            this.draw.removeLastPoint();
        }
    };

    pushTimeout() {
        this.timeTaken = Date.now();
        setTimeout(() => {
            undoRedoPush();
            let currentTime = Date.now();
            this.mapObj.AppStore.setTrackTools({
                toolName: TOOL_NAMES.add_point,
                timeTaken: currentTime - this.timeTaken, // in ms
                successfulOperation: true
            });
        }, 0);
    }

    off() {
        this.mapObj.map.removeInteraction(this.draw);
        this.draw && this.draw.un('drawend', this.pushTimeout);
        this.draw &&
            this.draw.un('drawstart', () => {
                this.timeTaken = Date.now();
            });
        window.removeEventListener('keydown', this.keyDownHandler);

        // this.layer && this.layer.getSource().un('addfeature', undoRedoPush);
    }
}

export default DrawPoint;
