import { toLonLat } from 'ol/proj';
import { message } from '../../../../UIComponents';
import {
    COPY_TO_CLIPBOARD_FAILED,
    COPY_TO_CLIPBOARD_SUCCESS,
    ERROR_COPY_NOT_SUPPORTED
} from '../../../../Constants/Messages';

class CopyTool {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.timeTaken = 0;
    }

    init(id) {
        this.off();
        this.mapObj.map.on('click', this.addLeftClick);
        this.mapObj.map.getViewport().addEventListener('contextmenu', this.addRightClick);
        this.mapObj.map.on('pointermove', this.setPointer);
    }

    setPointer = _ => {
        this.mapObj.map.getTargetElement().style.cursor = 'crosshair';
    };

    removePointer = _ => {
        this.mapObj.map.getTargetElement().style.cursor = '';
    };

    addLeftClick = e => {
        const coords = toLonLat(e.coordinate);
        const coordString = coords[1] + ',' + coords[0];
        try {
            let isCopied = this.copyToClipboard(coordString);
            if (isCopied) {
                message.success('Coordinates: ' + coordString + ' ' + COPY_TO_CLIPBOARD_SUCCESS);
            } else {
                message.error(COPY_TO_CLIPBOARD_FAILED);
            }
        } catch (err) {
            message.error(ERROR_COPY_NOT_SUPPORTED);
        }

        // this.off();
    };

    addRightClick = e => {
        let demoUrl = 'https://maps.google.com?q=';
        const coords = toLonLat(this.mapObj.map.getEventCoordinate(e));
        const coordString = coords[1] + ',' + coords[0];
        // this.off();
        window.open(demoUrl + coordString, '_blank');
    };

    copyToClipboard(text) {
        var dummy = document.createElement('input');
        dummy.value = text;
        document.body.appendChild(dummy);
        dummy.select();
        let success = document.execCommand('copy');
        document.body.removeChild(dummy);
        return success;
    }

    off() {
        this.mapObj.map.un('click', this.addLeftClick);
        this.mapObj.map.un('pointermove', this.setPointer);
        this.mapObj.map.getViewport().removeEventListener('contextmenu', this.addRightClick);
        // this.mapObj.AppStore.current_tool = 'pan';
        this.removePointer();
    }
}

export default CopyTool;
