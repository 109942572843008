import React from 'react';
import { Table as UITable } from 'antd';

const Table = props => {
    return <UITable {...props}>{props.children}</UITable>;
};

export default Object.assign(Table, {
    Column: UITable.Column,
    ColumnGroup: UITable.ColumnGroup
});
