import { message } from 'antd';
import { highlightFeatures } from '../../../../../Utils/HelperFunctions';
import { mergeFeatures } from '../../../../../Utils/MapHelper';
import { selectTool, undoRedoPush } from '../../MapInit';
import { TOOL_NAMES } from '../../../../../Constants/Tool';

class Merge {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.timeTaken = 0;
    }

    execute() {
        this.timeTaken = Date.now();
        this.select = selectTool.select;
        this.layer = selectTool.layer;
        this.mapObj.AppStore.setLoaderState(true);
        setTimeout(() => {
            this.mergeFeatures();
            this.mapObj.AppStore.setLoaderState(false);
            let currentTime = Date.now();
            this.mapObj.AppStore.setTrackTools({
                toolName: TOOL_NAMES.merge_selected,
                timeTaken: currentTime - this.timeTaken, // in ms
                successfulOperation: true
            });
        }, 10);
    }

    mergeFeatures() {
        const features = this.select ? this.select.getFeatures().getArray() : [];
        if (!features.length) {
            message.error('No feature is selected');
        } else {
            const invalidFeatures = features.filter(f => !f.isValid());
            if (invalidFeatures.length) {
                message.error(
                    'Invalid geometries exists in the provided features. Please first fix or delete them to perform operation. '
                );
                highlightFeatures(invalidFeatures);
                return;
            }
            mergeFeatures(features, this.layer);
            selectTool.emptyFeatureArray();
            undoRedoPush();
            message.destroy('selection-tool');
            this.mapObj.AppStore.setUpdateMapLegend();
        }
    }
}

export default Merge;
