import React from 'react';
import { Row as UIRow } from 'antd';

const Row = props => {
    const { children } = props;

    return <UIRow {...props}>{children}</UIRow>;
};

export default Row;
