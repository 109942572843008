import DrawHole from 'ol-ext/interaction/DrawHole';
import { selectStyle } from '../MapBase';
import { appStore, undoRedoPush } from '../MapInit';
import { TOOL_NAMES } from '../../../../Constants/Tool';
import { showArea } from '../../../../Utils/HelperFunctions';
import { unByKey } from 'ol/Observable';
import { Polygon } from 'ol/geom';

class DrawHoles {
    constructor(mapObj) {
        this.mapObj = mapObj;
        this.hole = null;
        this.timeTaken = 0;
        this.listner = null;
    }

    init(id) {
        this.off();
        const layer = this.mapObj.map.getLayerById(id);
        this.hole = new DrawHole({
            layers: [layer],
            style: selectStyle(),
            dragVertexDelay: 0,
            snapTolerance: 1,
            condition: e => {
                const mouseClick = e.originalEvent.which;
                if (mouseClick == 3 || mouseClick == 2) {
                    return false;
                }
                return true;
            }
        });
        this.mapObj.map.addInteraction(this.hole);
        this.hole.on('drawstart', evt => {
            this.timeTaken = Date.now();
            let overlay = appStore?.measurementOverlay;

            let sketch = evt.feature;
            this.listner = sketch.getGeometry().on('change', event => {
                const geom = event.target;

                if (geom instanceof Polygon) {
                    showArea(geom, overlay);
                }
            });
        });
        this.hole.on('drawend', () => {
            let currentTime = Date.now();
            this.mapObj.AppStore.setTrackTools({
                toolName: TOOL_NAMES.add_ring,
                timeTaken: currentTime - this.timeTaken,
                successfulOperation: true
            });
            undoRedoPush();
            unByKey(this.listner);
            let overlay = appStore?.measurementOverlay;
            overlay.setPosition(undefined);
        });
        window.addEventListener('keydown', this.keyDownHandler);
    }

    keyDownHandler = e => {
        if (e.code == 'Backspace') {
            this.hole.removeLastPoint();
        } else if (e.code == 'Space') {
            this.hole.finishDrawing();
        }
    };

    onDrawEnd() {
        this.mapObj.AppStore.setUpdateMapLegend();
        undoRedoPush();
    }

    off() {
        this.hole && this.mapObj.map.removeInteraction(this.hole);
        this.hole && this.hole.un('drawend', this.onDrawEnd);
        window.removeEventListener('keydown', this.keyDownHandler);
    }
}

export default DrawHoles;
