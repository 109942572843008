import React from 'react';
import { Tag as AntdTag } from 'antd';

const Tag = props => {
    const { children } = props;

    return <AntdTag {...props}>{children}</AntdTag>;
};

export default Tag;
