import { LAYER } from '../../../../../Constants/MapConstant';
import { undoRedoPush } from '../../MapInit';

class Clip {
    constructor(mapObj) {
        this.mapObj = mapObj;
    }

    execute() {
        const layers = this.getLayers();
        const parcelLayer = this.mapObj.map.getLayerById(LAYER.PARCEL);

        if (!parcelLayer || !layers.length) {
            return;
        }
        const boundFeature = parcelLayer.getSource().getFeatures()[0];
        for (const layer of layers) {
            const features = layer.getSource().getFeatures();
            const clippedFeatures = [];
            features.forEach(f => {
                const feat = this.mapObj.clipFeature(f, boundFeature);
                feat && clippedFeatures.push(feat);
            });
            if (clippedFeatures.length) {
                layer.getSource().clear();
                layer.getSource().addFeatures(clippedFeatures);
            }
        }
        undoRedoPush();
    }

    getLayers() {
        return this.mapObj.getEditableLayers().filter(el => el.getVisible());
    }
}

export default Clip;
