import { asString } from 'ol/color';
import GeoJSON from 'ol/format/GeoJSON'; // <= 1e-160 raise error after that

import { Stroke, Style, Fill, Circle } from 'ol/style';
import { appStore } from '../MainPage/Annotation/OlMap/MapInit';
import { BEDS_DATA, CANOPY_DATA, LAWN_DATA, LAWN_EDGE_DATA, MOWER_21_DATA, PARCEL_LAYER_DATA } from './DummyData';

export const LAYER_NAME = 'lawnx_layer';
export const LAWN_ATTRIBUTE = 'LawnType';
export const DATA_PROJECTION = 'EPSG:4326';
export const FEATURE_PROJECTION = 'EPSG:3857';
export const PARCEL = 'parcel'; //boundary
export const GEOM_SIMPLIFICATION_TOLERANCE = 1e-16;

export const META_DATA = {};
export const RATING_META_DATA = { ratings: {} };
export const AUTO_SUBMIT_TIMER = 60;
export const EXTENSION_TIMER_COUNT = 10;

export const GEO_JSON_OBJECT_PROJECTION = {
    dataProjection: DATA_PROJECTION,
    featureProjection: FEATURE_PROJECTION
};

export const GEO_JSON = {
    writeFeaturesObject: (features, projection = true) => {
        if (!projection) {
            return new GeoJSON().writeFeaturesObject(features);
        }
        return new GeoJSON().writeFeaturesObject(features, GEO_JSON_OBJECT_PROJECTION);
    },
    writeFeatureObject: (feature, projection = true) => {
        if (!projection) {
            return new GeoJSON().writeFeatureObject(feature);
        }
        return new GeoJSON().writeFeatureObject(feature, GEO_JSON_OBJECT_PROJECTION);
    },
    readFeature: (feature, projection = true) => {
        if (!projection) {
            return new GeoJSON().readFeature(feature);
        }
        return new GeoJSON().readFeature(feature, GEO_JSON_OBJECT_PROJECTION);
    },
    readFeatures: (features, projection = true) => {
        if (!projection) {
            return new GeoJSON().readFeatures(features);
        }
        return new GeoJSON().readFeatures(features, GEO_JSON_OBJECT_PROJECTION);
    },
    writeFeatures: (features, projection = true) => {
        if (!projection) {
            return new GeoJSON().writeFeatures(features);
        }
        return new GeoJSON().writeFeatures(features, GEO_JSON_OBJECT_PROJECTION);
    }
};

export const CONTEXT_MENU = {
    FUNCTION_1: 'context_1',
    FUNCTION_2: 'context_2'
};

export const KEY_CODE = {
    DELETE: 46,
    RIGHT_CLICK: 3,
    SCROLL_CLICK: 2,
    LEFT_CLICK: 1
};

export const EVENT_TYPE = {
    CLICK: 'click',
    POINTER_MOVE: 'pointermove'
};

export const DEFAULT_ZOOM_VALUE = 22;
export const MIN_ZOOM_VALUE = 2;
export const SUPER_ZOOM_VALUE = 28;

export const UNDO_REDO_STACK_LIMIT = 25;

export const SLIVER_POLYGON_AREA_LIMIT = 0.3; // in square metres

export const PROPERTY_TYPES = [
    'HoA',
    'Office',
    'Mall/ Plaza',
    'Hospital',
    'Petrol Pump',
    'Single Store',
    'Residential',
    'Warehouse/ Distribution Centre',
    'Industrial unit',
    'Hotel & Restaurant',
    'Educational Institute',
    'Sports Stadium/Fields'
];

export function StyleGen(color) {
    return new Style({
        stroke: new Stroke({
            color: color || 'black',
            width: 3
        }),
        fill: new Fill({
            color: 'rgba(0,0,0,0.08)'
        }),
        // Add visualisation for point features
        image: new Circle({
            radius: 5,
            fill: new Fill({
                color: color || 'black'
            })
        })
    });
}

export const GEOMETRY_TYPES = {
    POINT: 'Point',
    LINESTRING: 'LineString',
    POLYGON: 'Polygon',
    MULTI_POLYGON: 'MultiPolygon',
    MULTI_LINESTRING: 'MultiLineString',
    MULTI_POINT: 'MultiPoint'
};
export const DATA_STATUS = {
    PARTIAL: 1,
    COMPLETE: 2
};
export const GEOMETRY_TYPES_ALL = [GEOMETRY_TYPES.POINT, GEOMETRY_TYPES.LINESTRING, GEOMETRY_TYPES.POLYGON];

/**The defination of the LAYER, LAYER_COLOR, LAYER_TYPE_GEOMETRY_MAP and LAYER_DUMMY_JOB are 
 required for the demo pages we can not remove it for now */

export const LAYER = {
    PARCEL: 'parcel_layer', // editable layer
    CANOPY: 'canopy_layer',
    LAWN: 'lawn_layer',
    BEDS: 'beds_layer',
    HEDGE: 'hedge_layer',
    SIDEWALK: 'sidewalk_layer',
    DRIVEWAY: 'driveway_layer',
    PAVEMENT: 'pavement_layer',
    PARKING_SPACE: 'parking_space_layer',
    DRIVELANE: 'drivelane_layer',
    DEBUG: 'debug_layer',
    TREE: 'tree_layer',
    CURB: 'curb_layer',
    PARKING_LOT: 'parking_lot_layer',
    LAWN_EDGE: 'lawn_edge_layer',
    DRIVABLE_AREA: 'drivable_area_layer',
    MULCH_BED: 'mulch_bed_layer',
    GRAVEL_BED: 'gravel_bed_layer',
    ROADWAYS: 'roadways_layer',
    BUILDING_FOOTPRINT: 'building_footprint_layer',
    MOWER_60: 'mower_60_layer',
    MOWER_48: 'mower_48_layer',
    MOWER_36: 'mower_36_layer',
    MOWER_21: 'mower_21_layer',
    TOTAL_TURF: 'total_turf_layer',
    PONDS: 'ponds_layer',
    ROUGH_MOW: 'rough_mow_layer',
    SECONDARY_MOW: 'secondary_mow_layer',
    DECIDUOUS_TREE: 'deciduous_tree_layer',
    PALM_TREE: 'palm_tree_layer',
    TREE_RINGS: 'tree_rings_layer',
    HEDGE_COUNT: 'hedge_point_layer',
    PUBLIC_SIDEWALK: 'public_sidewalk_layer',
    PRIVATE_SIDEWALK: 'private_sidewalk_layer',
    LOT_AREA: 'lot_area_layer',
    PLAYGROUND: 'playground_layer',
    WATERBODY: 'waterbody_layer',
    NATIVE_AREA: 'native_area_layer',
    SPORTS_FIELD: 'sports_field_layer',
    PRIMARY_MOW: 'primary_mow_layer',
    SHRUB_COUNT: 'shrubs_count_layer',
    LOADING_AREA: 'loading_area_layer',
    ASPHALT_CRACKS: 'asphalt_cracks_layer',
    CONCRETE_CRACKS: 'concrete_cracks_layer',
    GRAVEL_SURFACE: 'gravel_surface_layer',
    CURB_STRIPS: 'curb_strips_layer',
    FLOWER_BEDS: 'flower_beds_layer',
    ASHPALT_HARD_EDGES: 'asphalt_hard_edges_layer',
    CONCRETE_HARD_EDGES: 'concrete_hard_edges_layer',
    CHEMICAL_APPLICATION_BUFFER: 'chemical_application_buffer_layer',
    PARKING_GARAGE: 'parking_garage_layer',
    LINE_TRIM: 'line_trim_layer',
    TREE_WITH_RING: 'tree_with_ring_layer',
    TREE_WITHOUT_RING: 'tree_without_ring_layer',
    RETENTION_POND: 'retention_pond_layer',
    PARKING_STALLS_STRIPES: 'parking_stalls_stripes_layer',
    CROSS_HATCHING_STRIPES: 'cross_hatching_stripes_layer',
    STOP_BAR_STRIPES: 'stop_bar_stripes_layer',
    OTHER_STRIPES: 'other_stripes_layer',
    HANDICAP_PARKING_STALL_COUNT: 'handicap_parking_stall_count_layer',
    REGULAR_PARKING_STALL_COUNT: 'regular_parking_stall_count_layer',
    ASPHALT: 'asphalt_layer',
    CONCRETE: 'concrete_layer',
    CRACKS: 'cracks_layer',
    DIRECTIONAL_ARROWS_COUNT: 'directional_arrows_count_layer',
    GREEN: 'green_layer',
    FRINGE: 'fringe_layer',
    ROUGH: 'rough_layer',
    FAIRWAY: 'fairway_layer',
    TEE_BOX: 'tee_box_layer',
    CART_PATH: 'cart_path_layer',
    BUNKER: 'bunker_layer',
    TENNIS_COURT: 'tennis_court_layer',
    POTHOLE: 'pothole_layer',
    COMMENT_LAYER: 'comment_layer'
};

export const LAYER_COLOR = {
    [LAYER_NAME]: 'red',
    [PARCEL]: 'greenyellow', // boundary layer
    [LAYER.PARCEL]: '#1473E6', // editable layer
    [LAYER.CANOPY]: 'brown',
    [LAYER.LAWN]: '#FF00A7',
    [LAYER.BEDS]: '#00863E',
    [LAYER.HEDGE]: '#795235',
    [LAYER.SIDEWALK]: '#0AAFFF',
    [LAYER.DRIVEWAY]: '#7551E9',
    [LAYER.PAVEMENT]: '#FF7D51',
    [LAYER.PARKING_SPACE]: '#12009D',
    [LAYER.DRIVELANE]: '#F5E03C',
    [LAYER.DEBUG]: 'blue',
    [LAYER.TREE]: '#CBFF3C',
    [LAYER.CURB]: '#FFF507',
    [LAYER.PARKING_LOT]: '#B551F6',
    [LAYER.LAWN_EDGE]: 'red',
    [LAYER.DRIVABLE_AREA]: '#F5E03C',
    [LAYER.MULCH_BED]: '#006400', // dark green
    [LAYER.GRAVEL_BED]: '#808080', // grey
    [LAYER.ROADWAYS]: '#800080', // purple
    [LAYER.BUILDING_FOOTPRINT]: '#ff0000', // purple
    [LAYER.MOWER_60]: '#40ff81',
    [LAYER.MOWER_48]: '#ea2b1e',
    [LAYER.MOWER_36]: '#f0f647',
    [LAYER.MOWER_21]: '#d00eb9',
    [LAYER.TOTAL_TURF]: '#fb2323',
    [LAYER.HEDGE_COUNT]: '#03edca',
    [LAYER.PONDS]: '#8b39e8',
    [LAYER.ROUGH_MOW]: '#73ab03',
    [LAYER.SECONDARY_MOW]: '#0cff04',
    [LAYER.DECIDUOUS_TREE]: '#c0ff11',
    [LAYER.PALM_TREE]: '#f60002',
    [LAYER.TREE_RINGS]: '#43c183',
    [LAYER.PUBLIC_SIDEWALK]: '#600c25',
    [LAYER.PRIVATE_SIDEWALK]: '#0a0802',
    [LAYER.LOT_AREA]: '#000051',
    [LAYER.PLAYGROUND]: '#d40094',
    [LAYER.WATERBODY]: '#B6E7F5',
    [LAYER.NATIVE_AREA]: '#870B00',
    [LAYER.SPORTS_FIELD]: '#CAD100',
    [LAYER.PRIMARY_MOW]: '#0cff7a',
    [LAYER.SHRUB_COUNT]: '#8012fa',
    [LAYER.LOADING_AREA]: '#ffabcd',
    [LAYER.ASPHALT_CRACKS]: '#e41af9',
    [LAYER.CONCRETE_CRACKS]: '#faff39',
    [LAYER.GRAVEL_SURFACE]: '#e3ec70',
    [LAYER.CURB_STRIPS]: '#f71201',
    [LAYER.FLOWER_BEDS]: '#f7004e',
    [LAYER.ASHPALT_HARD_EDGES]: '#6c206c',
    [LAYER.CONCRETE_HARD_EDGES]: '#fbfbfb',
    [LAYER.CHEMICAL_APPLICATION_BUFFER]: '#7add89',
    [LAYER.PARKING_GARAGE]: '#e41a1c',
    [LAYER.LINE_TRIM]: '#fffb06',
    [LAYER.TREE_WITH_RING]: '#47e479',
    [LAYER.TREE_WITHOUT_RING]: '#47e4d4',
    [LAYER.RETENTION_POND]: '#80fffa',
    [LAYER.PARKING_STALLS_STRIPES]: '#008cdd',
    [LAYER.CROSS_HATCHING_STRIPES]: '#f7b500',
    [LAYER.STOP_BAR_STRIPES]: '#cf142b',
    [LAYER.OTHER_STRIPES]: '#c715ff',
    [LAYER.HANDICAP_PARKING_STALL_COUNT]: '#172155',
    [LAYER.REGULAR_PARKING_STALL_COUNT]: '#227293',
    [LAYER.ASPHALT]: '#ffff00',
    [LAYER.CONCRETE]: '#7F8076',
    [LAYER.CRACKS]: '#16E60B',
    [LAYER.DIRECTIONAL_ARROWS_COUNT]: '#54e300',
    [LAYER.GREEN]: '#32e300',
    [LAYER.FRINGE]: '#086537',
    [LAYER.ROUGH]: '#549d60',
    [LAYER.FAIRWAY]: '#aead25',
    [LAYER.TEE_BOX]: '#00FFA2',
    [LAYER.CART_PATH]: '#09d2ec',
    [LAYER.BUNKER]: '#c39231',
    [LAYER.TENNIS_COURT]: '#4434db',
    [LAYER.POTHOLE]: '#a9a5d0'
};
// TODO: Create constant enum for layer_type keys
// Mapping layer type to geometry type
export const LAYER_TYPE_GEOMETRY_MAP = {
    [LAYER_NAME]: GEOMETRY_TYPES.POLYGON,
    [PARCEL]: GEOMETRY_TYPES.POLYGON, // boundary layer
    [LAYER.PARCEL]: GEOMETRY_TYPES.POLYGON, // editable layer
    [LAYER.CANOPY]: null,
    [LAYER.LAWN]: GEOMETRY_TYPES.POLYGON,
    [LAYER.BEDS]: GEOMETRY_TYPES.POLYGON,
    [LAYER.HEDGE]: GEOMETRY_TYPES.POLYGON,
    [LAYER.SIDEWALK]: GEOMETRY_TYPES.POLYGON,
    [LAYER.DRIVEWAY]: GEOMETRY_TYPES.POLYGON,
    [LAYER.PAVEMENT]: GEOMETRY_TYPES.POLYGON,
    [LAYER.PARKING_SPACE]: GEOMETRY_TYPES.POLYGON,
    [LAYER.DRIVELANE]: GEOMETRY_TYPES.POLYGON,
    [LAYER.DEBUG]: GEOMETRY_TYPES.POLYGON,
    [LAYER.TREE]: GEOMETRY_TYPES.POINT,
    [LAYER.CURB]: GEOMETRY_TYPES.LINESTRING,
    [LAYER.PARKING_LOT]: GEOMETRY_TYPES.POLYGON,
    [LAYER.LAWN_EDGE]: GEOMETRY_TYPES.LINESTRING,
    [LAYER.DRIVABLE_AREA]: GEOMETRY_TYPES.POLYGON,
    [LAYER.MULCH_BED]: GEOMETRY_TYPES.POLYGON,
    [LAYER.GRAVEL_BED]: GEOMETRY_TYPES.POLYGON,
    [LAYER.ROADWAYS]: GEOMETRY_TYPES.POLYGON,
    [LAYER.BUILDING_FOOTPRINT]: GEOMETRY_TYPES.POLYGON,
    [LAYER.MOWER_60]: GEOMETRY_TYPES.POLYGON,
    [LAYER.MOWER_48]: GEOMETRY_TYPES.POLYGON,
    [LAYER.MOWER_36]: GEOMETRY_TYPES.POLYGON,
    [LAYER.MOWER_21]: GEOMETRY_TYPES.POLYGON,
    [LAYER.TOTAL_TURF]: GEOMETRY_TYPES.POLYGON,
    [LAYER.PONDS]: GEOMETRY_TYPES.POLYGON,
    [LAYER.ROUGH_MOW]: GEOMETRY_TYPES.POLYGON,
    [LAYER.SECONDARY_MOW]: GEOMETRY_TYPES.POLYGON,
    [LAYER.HEDGE_COUNT]: GEOMETRY_TYPES.POINT,
    [LAYER.DECIDUOUS_TREE]: GEOMETRY_TYPES.POINT,
    [LAYER.PALM_TREE]: GEOMETRY_TYPES.POINT,
    [LAYER.TREE_RINGS]: GEOMETRY_TYPES.POINT,
    [LAYER.PUBLIC_SIDEWALK]: GEOMETRY_TYPES.POLYGON,
    [LAYER.PRIVATE_SIDEWALK]: GEOMETRY_TYPES.POLYGON,
    [LAYER.LOT_AREA]: GEOMETRY_TYPES.POLYGON,
    [LAYER.PLAYGROUND]: GEOMETRY_TYPES.POLYGON,
    [LAYER.WATERBODY]: GEOMETRY_TYPES.POLYGON,
    [LAYER.NATIVE_AREA]: GEOMETRY_TYPES.POLYGON,
    [LAYER.SPORTS_FIELD]: GEOMETRY_TYPES.POLYGON,
    [LAYER.PRIMARY_MOW]: GEOMETRY_TYPES.POLYGON,
    [LAYER.SHRUB_COUNT]: GEOMETRY_TYPES.POINT,
    [LAYER.LOADING_AREA]: GEOMETRY_TYPES.POLYGON,
    [LAYER.ASPHALT_CRACKS]: GEOMETRY_TYPES.LINESTRING,
    [LAYER.CONCRETE_CRACKS]: GEOMETRY_TYPES.LINESTRING,
    [LAYER.GRAVEL_SURFACE]: GEOMETRY_TYPES.POLYGON,
    [LAYER.CURB_STRIPS]: GEOMETRY_TYPES.POLYGON,
    [LAYER.FLOWER_BEDS]: GEOMETRY_TYPES.POLYGON,
    [LAYER.ASHPALT_HARD_EDGES]: GEOMETRY_TYPES.LINESTRING,
    [LAYER.CONCRETE_HARD_EDGES]: GEOMETRY_TYPES.LINESTRING,
    [LAYER.CHEMICAL_APPLICATION_BUFFER]: GEOMETRY_TYPES.POLYGON,
    [LAYER.PARKING_GARAGE]: GEOMETRY_TYPES.POINT,
    [LAYER.LINE_TRIM]: GEOMETRY_TYPES.LINESTRING,
    [LAYER.TREE_WITH_RING]: GEOMETRY_TYPES.POINT,
    [LAYER.TREE_WITHOUT_RING]: GEOMETRY_TYPES.POINT,
    [LAYER.RETENTION_POND]: GEOMETRY_TYPES.POLYGON,
    [LAYER.PARKING_STALLS_STRIPES]: GEOMETRY_TYPES.LINESTRING,
    [LAYER.CROSS_HATCHING_STRIPES]: GEOMETRY_TYPES.LINESTRING,
    [LAYER.STOP_BAR_STRIPES]: GEOMETRY_TYPES.LINESTRING,
    [LAYER.OTHER_STRIPES]: GEOMETRY_TYPES.LINESTRING,
    [LAYER.HANDICAP_PARKING_STALL_COUNT]: GEOMETRY_TYPES.POINT,
    [LAYER.REGULAR_PARKING_STALL_COUNT]: GEOMETRY_TYPES.POINT,
    [LAYER.ASPHALT]: GEOMETRY_TYPES.POLYGON,
    [LAYER.CONCRETE]: GEOMETRY_TYPES.POLYGON,
    [LAYER.CRACKS]: GEOMETRY_TYPES.LINESTRING,
    [LAYER.DIRECTIONAL_ARROWS_COUNT]: GEOMETRY_TYPES.POINT,
    [LAYER.GREEN]: GEOMETRY_TYPES.POLYGON,
    [LAYER.FRINGE]: GEOMETRY_TYPES.POLYGON,
    [LAYER.ROUGH]: GEOMETRY_TYPES.POLYGON,
    [LAYER.FAIRWAY]: GEOMETRY_TYPES.POLYGON,
    [LAYER.TEE_BOX]: GEOMETRY_TYPES.POLYGON,
    [LAYER.CART_PATH]: GEOMETRY_TYPES.POLYGON,
    [LAYER.BUNKER]: GEOMETRY_TYPES.POLYGON,
    [LAYER.TENNIS_COURT]: GEOMETRY_TYPES.POLYGON,
    [LAYER.POTHOLE]: GEOMETRY_TYPES.POLYGON
};

export const LAYER_ZINDEX = {
    [LAYER.BEDS]: 300,
    [LAYER.HEDGE]: 200,
    [LAYER.LAWN]: 150
};

export const SOURCE_TYPE = {
    FALCON: 'falcon',
    FALCON_QA: 'falcon-qa',
    FALCON_TRAIN: 'falcon-train',
    RESIDENTIAL: 'residential',
    RESIDENTIAL_QA: 'residential-qa',
    RESIDENTIAL_RAPID: 'residential-rapid',
    RESIDENTIAL_RAPID_TRAIN: 'residential-rapid-train',
    FMS_QC: 'fms-qc',
    FMS_QR: 'fms-qr',
    FMS_DUMMY_IMAGES: 'fms_dummy_images',
    FMS_QR_BACKUP: 'fms-qr-backup',
    FMS_RECYCLE_DATA: 'fms-recycle-data',
    FMS_TRAIN: 'fms-train',
    FMS: 'fms',
    FMS_QA: 'fms-qa',
    FMS_DUMMY_IMAGE: 'fms-dummy-image',
    FMS_DUMMY_IMAGE_QA: 'fms-dummy-image-qa'
};

export const PROPERTY_TYPE = {
    COMMERCIAL: 'commercial',
    RESIDENTIAL: 'residential'
};

export const SOURCE_TYPE_LAYERS_MAP = {
    [SOURCE_TYPE.RESIDENTIAL]: [LAYER.LAWN, LAYER.BEDS, LAYER.BUILDING_FOOTPRINT],
    [SOURCE_TYPE.RESIDENTIAL_QA]: [LAYER.LAWN, LAYER.BEDS, LAYER.BUILDING_FOOTPRINT],
    [SOURCE_TYPE.RESIDENTIAL_RAPID]: [LAYER.LAWN, LAYER.BEDS, LAYER.BUILDING_FOOTPRINT],
    [SOURCE_TYPE.RESIDENTIAL_RAPID_TRAIN]: [LAYER.LAWN, LAYER.BEDS, LAYER.BUILDING_FOOTPRINT]
};

export const LIFELINES = [
    { time: 60, title: 'Lifeline1- 60s' },
    { time: 120, title: 'Lifeline2- 120s' }
];

export const LAYER_TYPE = {
    LAWN_EDGE: 'lawn_edge_layer'
};

export const FEATURE_COLOR = {
    S: '#D80090',
    H: '#1921FF',
    L: '#fffb06'
};
const LAWN_EDGE_ATTRIBUTE = 'edge_type';

export function LawnEdgeVectorStyleFn(feature, resolution) {
    const edge_type = feature.getProperties()[LAWN_EDGE_ATTRIBUTE];
    return new Style({
        stroke: new Stroke({
            color: FEATURE_COLOR[edge_type] || 'red',
            width: 3
        }),
        fill: new Fill({
            color: 'rgba(0,0,0,0.08)'
        })
    });
}

export function ModifiedStyleGenFn(id) {
    if (id === LAYER.LAWN_EDGE) {
        return LawnEdgeVectorStyleFn;
    }
    if (id === LAYER.COMMENT_LAYER) {
        //dotted style
        return new Style({
            stroke: new Stroke({
                color: 'black',
                width: 3,
                lineDash: [4]
            }),
            fill: new Fill({
                color: 'rgba(0,0,0,0.08)'
            })
        });
    }
    const color = appStore.all_layer_types.get(id)?.color || 'black';

    return new Style({
        stroke: new Stroke({
            color: color,
            width: 3
        }),
        fill: new Fill({
            color: 'rgba(0,0,0,0.08)'
        }),
        // Add visualisation for point features
        image: new Circle({
            radius: 5,
            fill: new Fill({
                color: color
            })
        })
    });
}

export function HighlightFeatureStyleFn() {
    const color = asString([255, 0, 0, 0.6]);
    return new Style({
        stroke: new Stroke({
            color: color,
            width: 3
        }),
        fill: new Fill({
            color: color
        })
        // Add visualisation for point features
        // image: new Circle({
        //     radius: 5,
        //     fill: new Fill({
        //         color: color
        //     })
        // })
    });
}

export const DUMMY_PARCEL = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            geometry: {
                type: 'MultiPolygon',
                coordinates: [
                    [
                        [
                            [-74.274562, 40.788671],
                            [-74.275044, 40.78834],
                            [-74.275465, 40.78819],
                            [-74.275597, 40.787828],
                            [-74.275927, 40.787616],
                            [-74.27643, 40.78751],
                            [-74.27657, 40.787097],
                            [-74.275218, 40.786485],
                            [-74.274616, 40.78656],
                            [-74.275432, 40.786186],
                            [-74.275263, 40.786115],
                            [-74.273989, 40.786654],
                            [-74.27291, 40.78853],
                            [-74.273557, 40.788622],
                            [-74.274562, 40.788671]
                        ]
                    ]
                ]
            },
            properties: null
        }
    ]
};

export const DUMMY_BUILDING = {
    crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
    name: 'building1596105856',
    type: 'FeatureCollection',
    features: [
        {
            id: 1,
            type: 'Feature',
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-84.82984538458234, 33.380825973829445],
                        [-84.82984360927152, 33.38083117249698],
                        [-84.8298302152318, 33.38083453437877],
                        [-84.82982217880794, 33.38084013751508],
                        [-84.82981682119205, 33.38085358504222],
                        [-84.8298087847682, 33.380861429433054],
                        [-84.82978333609272, 33.380865911942095],
                        [-84.82976860264901, 33.38087823884198],
                        [-84.8297632450331, 33.380904013268996],
                        [-84.82975520860927, 33.38092642581423],
                        [-84.82974985099338, 33.38093314957781],
                        [-84.82974449337748, 33.38093090832328],
                        [-84.82974181456953, 33.38091297828709],
                        [-84.82974583278146, 33.38089953075995],
                        [-84.82974449337748, 33.3808804800965],
                        [-84.82974717218544, 33.38087823884198],
                        [-84.82974583278146, 33.380859188178526],
                        [-84.82974315397351, 33.380861429433054],
                        [-84.82974315397351, 33.38089616887817],
                        [-84.82973779635762, 33.38091634016888],
                        [-84.82973511754967, 33.38096452714113],
                        [-84.82973511754967, 33.38099702533172],
                        [-84.82974047516556, 33.381023920386006],
                        [-84.82974181456953, 33.381021679131486],
                        [-84.82975119039735, 33.381022799758746],
                        [-84.82975788741722, 33.38101495536791],
                        [-84.82976592384105, 33.3810183172497],
                        [-84.82978735430463, 33.38101607599518],
                        [-84.82982083940398, 33.38100711097708],
                        [-84.82985566390728, 33.380991422195414],
                        [-84.82986771854304, 33.380991422195414],
                        [-84.82987441556291, 33.38098806031363],
                        [-84.82988245198675, 33.380992542822675],
                        [-84.82989986423841, 33.380983577804585],
                        [-84.82989986423841, 33.3809275464415],
                        [-84.82990388245032, 33.3809185814234],
                        [-84.8299052218543, 33.38087935946924],
                        [-84.82990790066225, 33.38087823884198],
                        [-84.82990656125828, 33.38085358504222],
                        [-84.82990254304636, 33.38084349939686],
                        [-84.82990254304636, 33.380827446985734],
                        [-84.829924, 33.380828],
                        [-84.829904, 33.381152],
                        [-84.829715, 33.381141],
                        [-84.82973, 33.380823],
                        [-84.82984538458234, 33.380825973829445]
                    ]
                ]
            },
            properties: { LawnType: 'F' }
        }
    ]
};

export const DUMMY_SHAPE = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: { LawnType: 'F' },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-84.82990130782127, 33.38104982486028],
                        [-84.82989460229874, 33.38099943104024],
                        [-84.82976585626602, 33.38101846870902],
                        [-84.82977524399757, 33.3810666227938],
                        [-84.8298342525959, 33.38111589671342],
                        [-84.82990130782127, 33.38104982486028]
                    ]
                ]
            }
        }
    ]
};

export const DUMMY_SHAPE_2 = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: { LawnType: 'F' },
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-84.83001664280891, 33.381103578236136],
                        [-84.83009710907936, 33.381077821414344],
                        [-84.830122590065, 33.381026307747895],
                        [-84.83010247349739, 33.38098263309374],
                        [-84.83003675937653, 33.38097479405094],
                        [-84.82995495200157, 33.38103190706096],
                        [-84.82995495200157, 33.381096859065956],
                        [-84.83001664280891, 33.381103578236136]
                    ]
                ]
            }
        }
    ]
};

export const DUMMY_SHAPE_3 = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-84.8298516869545, 33.380971434460925],
                        [-84.8298704624176, 33.380947917327354],
                        [-84.82988119125366, 33.38091208168301],
                        [-84.82986778020859, 33.3808751261593],
                        [-84.82983022928238, 33.38083817061988],
                        [-84.82979267835617, 33.380828091833685],
                        [-84.82973501086235, 33.38084153021503],
                        [-84.82973769307137, 33.380884085075586],
                        [-84.82975378632545, 33.380936718690094],
                        [-84.8297806084156, 33.380984872820136],
                        [-84.8298516869545, 33.380971434460925]
                    ]
                ]
            }
        }
    ]
};

export const DUMMY_SHAPE_4 = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-84.82978194952011, 33.38108566044788],
                        [-84.82965186238289, 33.38108566044788],
                        [-84.82965186238289, 33.381128215188944],
                        [-84.82978194952011, 33.381128215188944],
                        [-84.82978194952011, 33.38108566044788]
                    ]
                ]
            }
        }
    ]
};

export const DUMMY_SHAPE_5 = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-84.83009308576584, 33.38107110224218],
                        [-84.8301413655281, 33.381001110834724],
                        [-84.83008034527302, 33.38092831971118],
                        [-84.82998311519623, 33.38095799609968],
                        [-84.82992611825466, 33.38097255432428],
                        [-84.82989862561224, 33.38100615021795],
                        [-84.82989527285099, 33.38103022726708],
                        [-84.8299227654934, 33.381045345410776],
                        [-84.82999451458454, 33.381043665617185],
                        [-84.83009308576584, 33.38107110224218]
                    ]
                ]
            }
        }
    ]
};

export const DUMMY_SHAPE_6 = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        [-84.8297980427742, 33.38109965872026],
                        [-84.82980575412512, 33.38108230086217],
                        [-84.82980206608772, 33.38104982486028],
                        [-84.82975546270609, 33.38108062106926],
                        [-84.82976250350475, 33.38111309705965],
                        [-84.8297980427742, 33.38109965872026]
                    ]
                ]
            }
        }
    ]
};

export const DUMMY_SHAPE_LINESTRING = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {
                edge_type: 'H'
            },
            geometry: {
                type: 'LineString',
                coordinates: [
                    [-84.82982132447529, 33.380960965282],
                    [-84.829792365074013, 33.380991645043736],
                    [-84.829731005550542, 33.380969853811102],
                    [-84.829695738160879, 33.381014296456613],
                    [-84.82972355065516, 33.38104927711953]
                ]
            }
        }
    ]
};
export const DUMMY_SHAPE_POINT = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {},
            geometry: { type: 'Point', coordinates: [-84.829749356062237, 33.38100168047982] }
        },
        {
            type: 'Feature',
            properties: {},
            geometry: { type: 'Point', coordinates: [-84.829761972039023, 33.381083971055709] }
        },
        {
            type: 'Feature',
            properties: {},
            geometry: { type: 'Point', coordinates: [-84.829712368312101, 33.381079956881273] }
        }
    ]
};

export const DUMMY_POINT = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Point',
                coordinates: [-84.82975780963898, 33.381069982380126]
            }
        },
        {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Point',
                coordinates: [-84.82977289706469, 33.381045905341985]
            }
        }
    ]
};
export const EMPTY_GEOJSON = {
    type: 'FeatureCollection',
    features: []
};

/**
 * Jobs
 */

export const JOB_STATUS_ENUM = {
    PENDING: 0,
    IN_PROGRESS: 1,
    COMPLETE: 2,
    FAILED: 3
};
export const JOB_STATUS = {
    [JOB_STATUS_ENUM.PENDING]: 'Pending',
    [JOB_STATUS_ENUM.IN_PROGRESS]: 'In Progress',
    [JOB_STATUS_ENUM.COMPLETE]: 'Complete',
    [JOB_STATUS_ENUM.FAILED]: 'Failed'
};
export const JOB_STATUS_COLOR = {
    [JOB_STATUS_ENUM.PENDING]: 'blue',
    [JOB_STATUS_ENUM.IN_PROGRESS]: 'blue',
    [JOB_STATUS_ENUM.COMPLETE]: 'green',
    [JOB_STATUS_ENUM.FAILED]: 'red'
};

export const ROLES_ENUM = {
    SUPER_ADMIN: 1,
    ADMIN: 2,
    CARTOGRAPHER: 3
};
export const ROLES = {
    [ROLES_ENUM.SUPER_ADMIN]: 'Super Admin',
    [ROLES_ENUM.ADMIN]: 'Admin',
    [ROLES_ENUM.CARTOGRAPHER]: 'Carto'
};

export const CARTO_ROLES_ENUM = {
    QC: 1,
    QA: 2,
    TRAINEE: 3
};
export const CARTO_ROLES = {
    [CARTO_ROLES_ENUM.QC]: 'QC',
    [CARTO_ROLES_ENUM.QA]: 'QA',
    [CARTO_ROLES_ENUM.TRAINEE]: 'TRAINEE'
};

export const REQUEST_STATUS_ENUM = {
    DRAFT: 1,
    IN_PROGRESS: 2,
    COMPLETED: 3,
    FAILED: 4,
    QUEUED: 5,
    INVESTIGATING: 6,
    RESUBMITTED: 7,
    CANCELLED: 8
};

export const REQUEST_STATUS = {
    [REQUEST_STATUS_ENUM.DRAFT]: 'Draft',
    [REQUEST_STATUS_ENUM.IN_PROGRESS]: 'In Progress',
    [REQUEST_STATUS_ENUM.FAILED]: 'Failed',
    [REQUEST_STATUS_ENUM.COMPLETED]: 'Completed',
    [REQUEST_STATUS_ENUM.QUEUED]: 'Queued',
    [REQUEST_STATUS_ENUM.INVESTIGATING]: 'Investigating',
    [REQUEST_STATUS_ENUM.RESUBMITTED]: 'Resubmitted',
    [REQUEST_STATUS_ENUM.CANCELLED]: 'Cancelled'
};

export const REQUEST_STATUS_COLOR = {
    [REQUEST_STATUS_ENUM.DRAFT]: '#FFA700',
    [REQUEST_STATUS_ENUM.IN_PROGRESS]: '#1473E6',
    [REQUEST_STATUS_ENUM.FAILED]: '#FF7474',
    [REQUEST_STATUS_ENUM.COMPLETED]: '#2DCA73',
    [REQUEST_STATUS_ENUM.QUEUED]: '#FF7D51',
    [REQUEST_STATUS_ENUM.INVESTIGATING]: '#989971',
    [REQUEST_STATUS_ENUM.RESUBMITTED]: '#5550b5',
    [REQUEST_STATUS_ENUM.CANCELLED]: '#FF7474'
};

export const LAYER_DUMMY_JOB = {
    [LAYER.SIDEWALK]: { id: 'test-id1', layer_type: LAYER.SIDEWALK, original_json: DUMMY_SHAPE },
    [LAYER.LAWN]: { id: 'test-id2', layer_type: LAYER.LAWN, original_json: LAWN_DATA },
    [LAYER.CANOPY]: { id: 'test-id3', layer_type: LAYER.CANOPY, original_json: DUMMY_SHAPE_3 },
    [LAYER.BEDS]: { id: 'test-id4', layer_type: LAYER.BEDS, original_json: DUMMY_SHAPE_4 },
    [LAYER.TREE]: { id: 'test-id5', layer_type: LAYER.TREE, original_json: DUMMY_SHAPE_POINT },
    [LAYER.LAWN_EDGE]: { id: 'test-id6', layer_type: LAYER.LAWN_EDGE, original_json: LAWN_EDGE_DATA },
    [LAYER.PARCEL]: { id: 'test-id7', layer_type: LAYER.PARCEL, original_json: DUMMY_PARCEL },
    [LAYER.MULCH_BED]: { id: 'test-id8', layer_type: LAYER.MULCH_BED, original_json: DUMMY_SHAPE_2 },
    [LAYER.GRAVEL_BED]: { id: 'test-id9', layer_type: LAYER.GRAVEL_BED, original_json: DUMMY_SHAPE_3 },
    [LAYER.DRIVEWAY]: { id: 'test-id10', layer_type: LAYER.DRIVEWAY, original_json: DUMMY_SHAPE },
    [LAYER.ROADWAYS]: { id: 'test-id11', layer_type: LAYER.ROADWAYS, original_json: DUMMY_SHAPE_4 },
    [LAYER.BUILDING_FOOTPRINT]: {
        id: 'test-id12',
        layer_type: LAYER.BUILDING_FOOTPRINT,
        original_json: DUMMY_BUILDING
    },
    [LAYER.MOWER_60]: { id: 'test-id13', layer_type: LAYER.MOWER_60, original_json: DUMMY_SHAPE_5 },
    [LAYER.MOWER_48]: { id: 'test-id14', layer_type: LAYER.MOWER_48, original_json: DUMMY_SHAPE_3 },
    [LAYER.MOWER_36]: { id: 'test-id15', layer_type: LAYER.MOWER_36, original_json: DUMMY_SHAPE_4 },
    [LAYER.MOWER_21]: { id: 'test-id16', layer_type: LAYER.MOWER_21, original_json: DUMMY_SHAPE },
    [LAYER.PONDS]: { id: 'test-id17', layer_type: LAYER.PONDS, original_json: DUMMY_SHAPE_3 },
    [LAYER.TOTAL_TURF]: { id: 'test-id18', layer_type: LAYER.TOTAL_TURF, original_json: DUMMY_SHAPE_4 },
    [LAYER.ROUGH_MOW]: { id: 'test-id19', layer_type: LAYER.ROUGH_MOW, original_json: DUMMY_SHAPE_6 },
    [LAYER.SECONDARY_MOW]: { id: 'test-id20', layer_type: LAYER.SECONDARY_MOW, original_json: DUMMY_SHAPE_5 },
    [LAYER.DECIDUOUS_TREE]: { id: 'test-id21', layer_type: LAYER.DECIDUOUS_TREE, original_json: DUMMY_SHAPE_POINT },
    [LAYER.HEDGE_COUNT]: { id: 'test-id22', layer_type: LAYER.HEDGE_COUNT, original_json: DUMMY_POINT },
    [LAYER.PALM_TREE]: { id: 'test-id23', layer_type: LAYER.PALM_TREE, original_json: DUMMY_POINT },
    [LAYER.TREE_RINGS]: { id: 'test-id24', layer_type: LAYER.TREE_RINGS, original_json: DUMMY_SHAPE_POINT },
    [LAYER.PRIVATE_SIDEWALK]: { id: 'test-id25', layer_type: LAYER.PRIVATE_SIDEWALK, original_json: DUMMY_SHAPE_2 },
    [LAYER.PUBLIC_SIDEWALK]: { id: 'test-id26', layer_type: LAYER.PUBLIC_SIDEWALK, original_json: DUMMY_SHAPE_4 },
    [LAYER.LOT_AREA]: { id: 'test-id27', layer_type: LAYER.LOT_AREA, original_json: DUMMY_SHAPE },
    [LAYER.PLAYGROUND]: { id: 'test-id28', layer_type: LAYER.PLAYGROUND, original_json: DUMMY_SHAPE },
    [LAYER.WATERBODY]: { id: 'test-id29', layer_type: LAYER.WATERBODY, original_json: DUMMY_SHAPE },
    [LAYER.NATIVE_AREA]: { id: 'test-id30', layer_type: LAYER.NATIVE_AREA, original_json: DUMMY_SHAPE },
    [LAYER.SPORTS_FIELD]: { id: 'test-id31', layer_type: LAYER.SPORTS_FIELD, original_json: DUMMY_SHAPE },
    [LAYER.PRIMARY_MOW]: { id: 'test-id32', layer_type: LAYER.PRIMARY_MOW, original_json: DUMMY_SHAPE },
    [LAYER.SHRUB_COUNT]: { id: 'test-id33', layer_type: LAYER.SHRUB_COUNT, original_json: DUMMY_POINT },
    [LAYER.LOADING_AREA]: { id: 'test-id34', layer_type: LAYER.LOADING_AREA, original_json: DUMMY_SHAPE_4 },
    [LAYER.ASPHALT_CRACKS]: { id: 'test-id35', layer_type: LAYER.ASPHALT_CRACKS, original_json: LAWN_EDGE_DATA },
    [LAYER.CONCRETE_CRACKS]: { id: 'test-id36', layer_type: LAYER.CONCRETE_CRACKS, original_json: LAWN_EDGE_DATA },
    [LAYER.GRAVEL_SURFACE]: { id: 'test-id37', layer_type: LAYER.GRAVEL_SURFACE, original_json: DUMMY_SHAPE_2 },
    [LAYER.CURB_STRIPS]: { id: 'test-id38', layer_type: LAYER.CURB_STRIPS, original_json: DUMMY_SHAPE_4 },
    [LAYER.FLOWER_BEDS]: { id: 'test-id39', layer_type: LAYER.FLOWER_BEDS, original_json: DUMMY_SHAPE_6 },
    [LAYER.ASHPALT_HARD_EDGES]: {
        id: 'test-id40',
        layer_type: LAYER.ASHPALT_HARD_EDGES,
        original_json: LAWN_EDGE_DATA
    },
    [LAYER.CONCRETE_HARD_EDGES]: {
        id: 'test-id41',
        layer_type: LAYER.CONCRETE_HARD_EDGES,
        original_json: LAWN_EDGE_DATA
    },
    [LAYER.CHEMICAL_APPLICATION_BUFFER]: {
        id: 'test-id42',
        layer_type: LAYER.CHEMICAL_APPLICATION_BUFFER,
        original_json: DUMMY_SHAPE_4
    },
    [LAYER.PARKING_GARAGE]: { id: 'test-id43', layer_type: LAYER.PARKING_GARAGE, original_json: DUMMY_POINT },
    [LAYER.LINE_TRIM]: { id: 'test-id44', layer_type: LAYER.LINE_TRIM, original_json: DUMMY_SHAPE_LINESTRING },
    [LAYER.TREE_WITH_RING]: { id: 'test-id45', layer_type: LAYER.TREE_WITH_RING, original_json: DUMMY_POINT },
    [LAYER.TREE_WITHOUT_RING]: { id: 'test-id46', layer_type: LAYER.TREE_WITHOUT_RING, original_json: DUMMY_POINT },
    [LAYER.RETENTION_POND]: { id: 'test-id47', layer_type: LAYER.RETENTION_POND, original_json: DUMMY_SHAPE_6 },
    [LAYER.PARKING_STALLS_STRIPES]: {
        id: 'test-id48',
        layer_type: LAYER.PARKING_STALLS_STRIPES,
        original_json: DUMMY_SHAPE_LINESTRING
    },
    [LAYER.CROSS_HATCHING_STRIPES]: {
        id: 'test-id49',
        layer_type: LAYER.CROSS_HATCHING_STRIPES,
        original_json: DUMMY_SHAPE_LINESTRING
    },
    [LAYER.STOP_BAR_STRIPES]: {
        id: 'test-id50',
        layer_type: LAYER.STOP_BAR_STRIPES,
        original_json: DUMMY_SHAPE_LINESTRING
    },
    [LAYER.OTHER_STRIPES]: { id: 'test-id51', layer_type: LAYER.OTHER_STRIPES, original_json: DUMMY_SHAPE_LINESTRING },
    [LAYER.HANDICAP_PARKING_STALL_COUNT]: {
        id: 'test-id52',
        layer_type: LAYER.HANDICAP_PARKING_STALL_COUNT,
        original_json: DUMMY_POINT
    },
    [LAYER.REGULAR_PARKING_STALL_COUNT]: {
        id: 'test-id53',
        layer_type: LAYER.REGULAR_PARKING_STALL_COUNT,
        original_json: DUMMY_POINT
    },
    [LAYER.ASPHALT]: { id: 'test-id54', layer_type: LAYER.ASPHALT, original_json: DUMMY_SHAPE },
    [LAYER.CONCRETE]: { id: 'test-id55', layer_type: LAYER.CONCRETE, original_json: DUMMY_SHAPE },
    [LAYER.CRACKS]: { id: 'test-id56', layer_type: LAYER.CRACKS, original_json: DUMMY_SHAPE_LINESTRING },
    [LAYER.DIRECTIONAL_ARROWS_COUNT]: {
        id: 'test-id57',
        layer_type: LAYER.DIRECTIONAL_ARROWS_COUNT,
        original_json: DUMMY_POINT
    },
    [LAYER.GREEN]: {
        id: 'test-id58',
        layer_type: LAYER.GREEN,
        original_json: DUMMY_SHAPE
    },
    [LAYER.FRINGE]: {
        id: 'test-id59',
        layer_type: LAYER.FRINGE,
        original_json: DUMMY_SHAPE_2
    },
    [LAYER.ROUGH]: {
        id: 'test-id60',
        layer_type: LAYER.ROUGH,
        original_json: DUMMY_SHAPE_3
    },
    [LAYER.FAIRWAY]: {
        id: 'test-id61',
        layer_type: LAYER.FAIRWAY,
        original_json: DUMMY_SHAPE_4
    },
    [LAYER.TEE_BOX]: {
        id: 'test-id62',
        layer_type: LAYER.TEE_BOX,
        original_json: DUMMY_SHAPE_5
    },
    [LAYER.CART_PATH]: {
        id: 'test-id63',
        layer_type: LAYER.CART_PATH,
        original_json: DUMMY_SHAPE_6
    },
    [LAYER.BUNKER]: {
        id: 'test-id64',
        layer_type: LAYER.BUNKER,
        original_json: DUMMY_SHAPE
    },
    [LAYER.TENNIS_COURT]: {
        id: 'test-id65',
        layer_type: LAYER.TENNIS_COURT,
        original_json: DUMMY_SHAPE_2
    },
    [LAYER.POTHOLE]: {
        id: 'test-id66',
        layer_type: LAYER.POTHOLE,
        original_json: DUMMY_SHAPE_3
    }
};
