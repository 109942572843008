import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import 'antd/dist/antd.less';
import './Styles/index.less';
import { Provider } from 'mobx-react';
import S_App from './Stores/AppStore';
import * as Sentry from '@sentry/react';
import { Integrations, BrowserTracing } from '@sentry/tracing';
import { CaptureConsole, ExtraErrorData, Debug as DebugIntegration } from '@sentry/integrations';
import { CARTO_ROLES } from './Constants/MapConstant';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'cropperjs/dist/cropper.css';

let AppStore = new S_App();
const stores = {
    AppStore
};
// process.env.REACT_APP_ENV === 'prod'
if (process.env.REACT_APP_ENV === 'close') {
    Sentry.init({
        dsn: 'https://a584833b7dfa46228a05f71308691785@o439343.ingest.sentry.io/5406044',
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            new Integrations.BrowserTracing(),
            new CaptureConsole({
                levels: ['error']
            }),
            new Sentry.Replay({
                blockAllMedia: false,
                maskAllInputs: false,
                maskAllText: false
            }),
            new BrowserTracing(),
            new ExtraErrorData(),
            new DebugIntegration({
                // trigger DevTools debugger instead of using console.log
                debugger: false,

                // stringify event before passing it to console.log
                stringify: true
            })
        ],
        tracesSampleRate: 100,
        environment: process.env.REACT_APP_ENV,
        attachStacktrace: true,
        beforeSend: (event, hint) => {
            return event;
        }
    });
    if (AppStore.user.email) {
        Sentry.configureScope(function (scope) {
            scope.setUser({
                email: AppStore.user.email,
                username: AppStore.user.name,
                id: AppStore.user.id,
                carto_role: CARTO_ROLES[AppStore.user.carto_role]
            });
        });
    }
}

ReactDOM.render(
    <Router>
        <Provider {...stores}>
            <App />
        </Provider>
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
